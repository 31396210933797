import config from 'components/config';
import request from 'components/lib/request';
import { getNewApiBaseUrl } from 'components/common/helpers'
const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/fintechs`;

const clients = {
  list: (filter, page = 0, pageSize = 100) =>
    request({ uri: `${baseUrl}${filter}${filter ? '&' : '?'}page=${page}&size=${pageSize}` }),
  create: (body) => request({ uri: baseUrl, method: 'POST', body }),
  update: (id, body) =>
    request({ uri: `${baseUrl}/${id}`, method: 'PUT', body }),
  delete: (id) => request({ uri: `${baseUrl}/${id}`, method: 'DELETE' }),
}

export default clients;