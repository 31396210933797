import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/providers`;

const apps = {
  listIntegratedAccounts: (fintechId, start, size) => request({ uri: `${baseUrl}/integrated/accounts?fintechId=${fintechId}&start=${start}&size=${size}` }),
  getIntegratedAccount: (fintechId, aspspId, accountId) => request({ uri: `${baseUrl}/integrated/account?fintechId=${fintechId}&aspspId=${aspspId}&accountId=${accountId}` }),
  listIntegratedAccountsTransactions: (fintechId, aspspId, accountId, from, to, start, size) => request(
    {
      uri: `${baseUrl}/integrated/accounts/transactions?fintechId=${fintechId}&aspspId=${aspspId}&accountId=${accountId}&start=${start}&size=${size}&from=${from}&to=${to}`
    }),
  listIntegratedAccountsBalances: (fintechId, aspspId, accountId, start, size) => request(
    {
      uri: `${baseUrl}/integrated/accounts/balances?fintechId=${fintechId}&aspspId=${aspspId}&accountId=${accountId}&start=${start}&size=${size}`
    }),
  listIntegratedConsents: (fintechId, aspspId) => request(
    {
      uri: `${baseUrl}/integrated/consents?fintechId=${fintechId}&aspspId=${aspspId}`
    }),
  listDcrJobs: (fintechId, page, max, userId) => request({ uri: `${baseUrl}/dcr/jobs?fintechId=${fintechId}&page=${page}&max=${max}`, method: 'GET',headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }  }),
  listPaymentsDcrJobs: (fintechId, page, max, userId) => request({ uri: `${baseUrl}/payments/dcr/jobs?fintechId=${fintechId}&page=${page}&max=${max}`, method: 'GET',headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }  }),
  getDcrJob: (fintechId, jobId) => request({ uri: `${baseUrl}/dcr/job?fintechId=${fintechId}&jobId=${jobId}` }),
  getPaymentsDcrJob: (fintechId, jobId) => request({ uri: `${baseUrl}/payments/dcr/job?fintechId=${fintechId}&jobId=${jobId}` })
}

export default apps;