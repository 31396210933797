import common from './common';
import components from './components';
import pages from './pages';
import nav from './nav';
import staticPage from './static';
import termsAndConditions from './termsAndConditions';
import privacyPolicy from './privacyPolicy';
import ebanxTermsAndConditions from './ebanxTermsAndConditions';
import ebanxPrivacyPolicy from './ebaxPrivacyPolicy';

export default {
  common,
  components,
  pages,
  nav,
  staticPage,
  termsAndConditions,
  privacyPolicy,
  termsConditions: ebanxTermsAndConditions,
  ebanxPrivacyPolicy
}