import jwt from 'jsonwebtoken';
import safeParse from 'components/lib/safe-parse';
import storage from 'components/lib/storage';

export const setSession = (data) => {
  storage.setItem('session', JSON.stringify(data));
}

export const getSession = () =>
  safeParse(storage.getItem('session'));

export const removeSession = () => {
  storage.removeItem('session');
  storage.removeItem('selectedFintech')
}

const getTokenPayload = () => {
  const session = getSession();

  if (
    !session ||
    !session.accessToken
  ) {
    return;
  }

  const tokenData = jwt.decode(
    session.accessToken
  );

  if (
    !tokenData ||
    typeof tokenData !== 'object'
  ) {
    return;
  }

  return tokenData;
}

export const getUser = () => {
  const tokenData = getTokenPayload();

  if (!tokenData) {
    return;
  }

  if (
    tokenData.sub &&
    typeof tokenData.sub === 'string'
  ) {
    return tokenData.sub;
  }
  if (
    tokenData.username &&
    typeof tokenData.username === 'string'
  ) {
    return tokenData.username;
  }
}

export const getUserEntity = () => {
  const { scope } = getTokenPayload() || {};

  if (!scope || !Array.isArray(scope)) {
    return;
  }

  const entity = scope.find((item) =>
    item.match(/^ent:/)
  );

  if (!entity || typeof entity !== 'string') {
    return;
  }

  return entity.split(/ent:/)[1];
};

export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';

export const removeCoockie = (name) => {
  if (typeof window !== 'undefined') {
    return sessionStorage.removeItem(name)
  }
}

export const setCoockie = (name, value) => {
  if (typeof window !== 'undefined') {
      return sessionStorage.setItem(name, value);
  }
  return null;
};

export const getCoockie = (name) => {
  if (typeof window !== 'undefined') {
      return sessionStorage.getItem(name);
  }
  return null;
};
