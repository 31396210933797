import config from 'components/config'
import request from 'components/lib/request'
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/metrics`

const metrics = {
  list: () => request({ uri: baseUrl }),
  get: (id, from, to) => request({ uri: `${baseUrl}?fintechId=${id}&from=${from}&to=${to}` }),
  getSelectedBank: (aspspId, from, to, selectedFintech) => request({ uri: `${getNewApiBaseUrl(apiBase)}/resources/metrics/${aspspId}?fintechId=${selectedFintech}&from=${from}&to=${to}` })
}

export default metrics;
