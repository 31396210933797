import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/apps`;

const apps = {
  list: (page, limit) => request({ uri: `${baseUrl}?page=${page}&limit=${limit}` }),
  get: (id) => request({ uri: `${baseUrl}/${id}` }),
  create: (body) => request({ uri: `${baseUrl}`, method: 'POST', body }),
  update: (id, body) => request({ uri: `${baseUrl}/${id}`, method: 'PUT', body }),
  delete: (id, fintechId) => request({ uri: `${baseUrl}/${id}/${fintechId}`, method: 'DELETE' })
}

export default apps;