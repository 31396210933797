export default {
  'tabTitle': 'Privacy Policy',
  'mainHeading': 'Privacy Policy',
  'subTitle': 'Your privacy is important to us. We process all information about you in a gentle and responsible manner.',
  'lastModified': 'Last modified: June 19, 2023',
  'description': 'This privacy policy applies between you, the User of this Website and Banfico Ltd, the owner and provider of this Website. Banfico Ltd takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website.',
  'warningMessage': 'Please read this privacy policy carefully.',
  'section1': {
    'title': 'Definitions and interpretation',
    'orderedList1': 'In this privacy policy, the following definitions are used:',
    'orderedList2': 'In this privacy policy, the following definitions are used:',
    'unorderedList1': 'the singular includes the plural and vice versa;',
    'unorderedList2': 'references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses,schedules or appendices of this privacy policy;',
    'unorderedList3': 'a reference to a person includes firms, companies, government entities, trusts and partnerships;',
    'unorderedList4': '"including" is understood to mean "including without limitation";',
    'unorderedList5': 'reference to any statutory provision includes any modification or amendment of it;',
    'unorderedList6': 'the headings and sub-headings do not form part of this privacy policy.',
    'definitionRow': {
      'title1': 'Date',
      'description1': 'collectively all information that you submit to Banfico Ltd via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws;',
      'title2': 'Cookies',
      'description2': 'a small text file placed on your computer by this Website when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below (Cookies);',
      'title3': 'Data Protection Laws',
      'description3': 'any applicable law relating to the processing of personal Data, including but not limited to the Directive 96/46/EC (Data Protection Directive) or the GDPR, and any national implementing laws, regulations and secondary legislation, for as long as the GDPR is effective in the UK;',
      'title4': 'GDPR',
      'description4': 'the General Data Protection Regulation (EU) 2016/679;',
      'title5': 'UK and EU Cookie Law',
      'description5': 'Banfico Ltd, a company incorporated in England and Wales with registered number 11036752 whose registered office is at 1 Canada Square, Level39, Canary Wharf, London, E14 5AB;',
      'title6': 'User or you',
      'description6': 'any third party that accesses the Website and is not either (i) employed by Banfico Ltd and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Banfico Ltd and accessing the Website in connection with the provision of such services; and',
      'title7': 'Website',
      'description7': 'the website that you are currently using, www.banfico.com, and any sub-domains of this site unless expressly excluded by their own terms and conditions.'
    }
  },
  'section2': {
    'title': 'Scope of this privacy policy',
    'orderedList1': 'This privacy policy applies only to the actions of Banfico Ltd and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites.',
    'orderedList2': 'For purposes of the applicable Data Protection Laws, Banfico Ltd is the "data controller". This means that Banfico Ltd determines the purposes for which, and the manner in which, your Data is processed.'
  },
  'section3': {
    'title': 'Data collected',
    'orderedList1': 'We may collect the following Data, which includes personal Data, from you:',
    'unorderedList1': 'name',
    'unorderedList2': 'contact Information such as email addresses and telephone numbers;',
    'unorderedList3': 'demographic information such as postcode, preferences and interests;',
    'unorderedList4': 'IP address (automatically collected);',
    'unorderedList5': 'web browser type and version (automatically collected);',
    'unorderedList6': 'operating system (automatically collected);',
    'unorderedList7': 'a list of URLs starting with a referring site, your activity on this Website, and the site you exit to (automatically collected);',
    'unorderedList8': 'in each case, in accordance with this privacy policy.'
  },
  'section4': {
    'title': 'How we collect data',
    'orderedList1': 'We collect Data in the following ways:',
    'unorderedList1': 'data is given to us by you; and',
    'unorderedList2': 'data is collected automatically.'
  },
  'section5': {
    'title': 'Data that is given to us by you',
    'orderedList1': 'Banfico Ltd will collect your Data in a number of ways, for example:',
    'unorderedList1': 'when you contact us through the Website, by telephone, post, e-mail or through any other means;',
    'unorderedList2': 'when you register with us and set up an account to receive our products/services;',
    'unorderedList3': 'when you use our services;',
    'description': 'in each case, in accordance with this privacy policy.'
  },
  'section6': {
    'title': 'Data that is collected automatically',
    'orderedList1': 'To the extent that you access the Website, we will collect your Data automatically, for example:',
    'unorderedList1': 'we automatically collect some information about your visit to the Website. This information helps us to make improvements to Website content and navigation, and includes your IP address, the date, times and frequency with which you access the Website and the way you use and interact with its content.',
    'unorderedList2': 'we will collect your Data automatically via cookies, in line with the cookie settings on your browser. For more information about cookies, and how we use them on the Website, see the section below, headed "Cookies".'
  },
  'section7': {
    'title': 'Cookies',
    'description1': 'Below is a list of the cookies that we use. We have tried to ensure this is complete and up to date, but if you think that we have missed a cookie or there is any discrepancy, please let us know.',
    'description2': 'We use the following strictly necessary cookies:',
    'definitionRow': {
      'title1': 'Description of Cookie',
      'description1': 'Purpose',
      'title2': 'Identity & Session',
      'description2': 'Secure login'
    }
  }
}
