export default {
  add: 'Adicionar Novo',
  application: 'Aplicação',
  addedSuccessfully: 'Adicionado com sucesso',
  back: 'Voltar',
  bank: 'Banco',
  cancel: 'Cancelar',
  close: 'Fechar',
  confirm: 'Confirmar',
  chooseBank: 'Selecione o Banco',
  chooseAccount: 'Selecione a Conta',
  connectedBanks: 'Bancos Conectados',
  create: 'Criar',
  created: 'Criado',
  clear: 'Limpar',
  search: 'Procurar',
  copyToClipboard: 'Copiar para área de transferência',
  date: 'data',
  dateRange: 'Período',
  download: 'Baixar',
  delete: 'Excluir',
  developer: 'Desenvolvedor',
  edit: 'Editar',
  error: 'Erro',
  email: 'Email',
  export: 'Exportar',
  fintech: 'Fintech',
  getStarted: 'Iniciar',
  home: 'Início',
  login: 'Acessar',
  loading: 'Carregando',
  main: 'principal',
  message: 'Mensagem',
  noDataFound: 'Dado não encontrado',
  name: 'Nome',
  next: 'Próximo',
  pleaseConfirm: 'Por favor confirme',
  product: 'Produto',
  save: 'Salvar',
  send: 'Enviar',
  subscribe: 'Inscrever-se',
  saveChanges: 'Salvar Alterações',
  status: 'Status',
  settings: 'Configurações',
  statement: 'Extrato',
  register: 'Registro',
  registration: 'Cadastro',
  resume: 'Continuar',
  revoke: 'Revogar',
  signUp: 'Inscrever-se',
  signIn: 'Entrar',
  remove: 'Remover',
  test: 'Testar',
  today: 'hoje',
  user: 'Usuário',
  update: 'Atualizar',
  updatedSuccessfully: 'Atualizado com sucesso',
  viewDetails: 'Exibir Detalhes',
  viewDocs: 'Exibir Documentos',
  website: 'Site',
  tableStatus: {
    completed: 'Concluído',
    failure: 'Erro',
    active: 'Ativo',
    inActive: 'Inativo'
  },
  errorMessage: {
    internalError: 'desculpe, mas ocorreu um erro interno.',
    missingPassword: 'Falta a senha',
    missingOrganization: 'Falta o nome da organização',
    solveCaptcha: 'Por favor, resolva o captcha',
    isRequired: 'é obrigatório',
    notFound: 'não encontrado',
    tooManyRequests: 'muitas requisições',
    provideOldPassword: 'Por favor, informe a senha antiga',
    passwordDontMatch: 'Senha não confere',
    newPasswordSame:
      'A nova senha deve ser diferente da senha anterior',
    noAccessCode: 'nenhum código de acesso fornecido',
    authenticationError: 'Por favor faça login novamente!'
  },
  confirmationMessage: {
    delete: 'Tem certeza de que deseja excluir'
  },
  testing: {
    mainTitle: 'OBAF',
    availableBrazil: 'Disponível no Brasil'
  },
  passwordRules: {
    lengthRule: 'A senha deve conter pelo menos 8 caracteres.',
    digitRule: 'A senha deve conter pelo 1 letra',
    upperCaseRule: 'A senha deve conter pelo menos 1 letra maiúscula',
    numberRule: 'A senha deve conter pelo menos 1 letra minúscula',
    charRule: 'A senha deve conter pelo menos 1 caracter especial (!@#$~)'
  }
}
