import config from 'components/config'
import request from 'components/lib/request'
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/aspsps`

const aspsps = {
  listAvailables: (fintechId, userId) => request({ uri: `${baseUrl}/available/${fintechId}`, method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0  && { fintechId: userId } }),
  listAvailablePayments: (fintechId, userId) => request({ uri: `${baseUrl}/payments/available/${fintechId}`, method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId } }),
  listIntegrateds: (fintechId) => request({ uri: `${baseUrl}/integrated/${fintechId}` }),
  get: (id) => request({ uri: `${baseUrl}/available/${id}` })
}

export default aspsps
