/* eslint-disable max-lines */
export default {
  adminAnalytics: {
    title: 'Métricas de Finanças Abertas',
    subtitle: 'Mostrando análises dos últimos 7 dias',
    bestUptime: 'Melhor Tempo de Atividade',
    lastDowntime: 'Último Tempo de Inatividade',
    fastestAvgResponseTime: 'Média de Tempo de Resposta mais Rápida',
    banks: 'Bancos',
    avgUptime: 'Média de Tempo de Atividade',
    lastFailure: 'Última Falha',
    noDowntime: 'sem tempo de inatividade',
    avgResponseTime: 'Média do Tempo de Resposta'
  },
  adminAnalyticsDetail: {
    nav: {
      responseTime: 'Tempo de Resposta',
      uptime: 'Tempo de Atividade',
      apiRequests: 'Requisições da API',
      uniqueApiUsers: 'Usuários Únicos da API',
      regulatoryReports: 'Relatórios Regulatórios'
    },
    format: 'Formato',
    type: 'Tipo',
    downloadReports: 'Baixar Relatórios',
    pleaseSelectReportTypeAndDateRange:
      'Selecione o tipo de relatório e o intervalo de datas.',
    reportFormat: 'Formato do Relatório',
    reportType: 'Tipo do Relatório',
    dateRange: 'Intervalo de Datas',
    requests: 'Requisições',
    aispNoOfRequests: 'AISP - No. de Requisições',
    noAispDataFound: 'Nenhum Dado do AISP Encontrado!',
    pispNoOfRequests: 'PISP - No. de Requisições',
    noDataFound: 'Desculpe! Nenhum dado disponível no período de datas selecionado.',
    noRejectedPaymentsFound: 'Nenhum pagamento rejeitado/cancelado encontrado!',
    average: 'Média',
    minimum: 'Mínimo',
    maximum: 'Máximo',
    apiRequests: 'Requisições da API',
    aispAvgResponseTime: 'AISP - Média do Tempo de Resposta',
    pispAvgResponseTime: 'PISP - Média do Tempo de Resposta',
    apiResponseTime: 'Tempo de Resposta da API',
    today: 'Hoje',
    daysAgo: 'dias atrás',
    uptime: 'Tempo de Atividade',
    users: 'Usuários',
    aispUniqueApiUsers: 'AISP - Usuários Únicos da API',
    pispUniqueApiUsers: 'PISP - Usuários Únicos da API',
    uniqueApiUsers: 'Usuários Únicos da API',
    days: 'dias'
  },
  adminClientManagement: {
    title: 'Clientes ASPSP',
    subtitle: 'Aqui você pode gerenciar seu cliente ASPSP.',
    dcrManagement: 'Gerenciamento de DCR',
    aspspClientManagement: 'Gerenciamento de Clientes ASPSP',
    dcrRegistrationError: 'Alguns registros do cliente DCR falharam!',
    aspsp: 'ASPSP',
    noAspspSelected: 'Nenhum ASPSP selecionado...',
    selectedAspsp: 'ASPSPS(s) selecionado',
    dcrJobs: 'Rotinas DCR',
    dcrInfo: 'Informações do DCR',
    webhookInfo: 'Informação do Webhook',
    runDcr: 'Executar DCR',
    registrationStatus: 'Status de Registro',
    clientDetails: 'Detalhes do Cliente',
    noDataMessageClient: 'Você ainda não tem nenhum cliente.',
    jobId: 'ID da Rotina',
    manualFintechNoData: 'Para clientes de fintech manuais, não há dados disponíveis.',
    aspspId: 'ID do ASPSP',
    registrationType: 'Tipo de Registro',
    mtlsPrivateKey: 'Chave Privada MTLS',
    mtlsPublicCertificate: 'Certificado Público MTLS',
    signingKeyId: 'ID da Chave de Assinatura',
    signingPrivateKey: 'Chave Privada de Assinatura',
    softwareAssertion: 'Declaração de Software de Diretório',
    listOfAspsp: 'Lista de ASPSP(s)',
    goToDcr: 'Abrir o Gerenciamento de DCR',
    noAspspAdded: 'Você ainda não tem nenhum cliente ASPSP. Por favor, adicione um ASPSP para visualizá-lo na lista.',
    clientOverview: 'Visão Geral do Cliente',
    dcrDetails: 'Detalhes do DCR',
    error: 'Erro',
    activate: 'Ativar',
    deactivate: 'Desativar',
    product: 'Produto'
  },
  apiKeys: {
    title: 'Chaves da API',
    createApiKey: 'Criar Chaves da API',
    keyTitle: 'Título',
    environment: 'Ambiente',
    noApiKeyFound: 'Nenhuma Chave da API encontrada',
    revokeConfirmationMessage: 'Tem certeza que deseja revogar a chave?',
    keyCopiedToClipboard: 'Chave copiada para a área de transferência'
  },
  customerDetails: {
    accountTemp: 'conta temporária',
    customers: 'Clientes',
    transactions: 'Transações',
    balances: 'Saldos',
    consents: 'Consentimentos',
    profile: 'Perfil',
    noAccountSelected: 'Selecione o banco e a conta para ver os detalhes.',
    accountName: 'Nome da Conta',
    accountNumber: 'Número da Conta',
    searchText: 'Pesquisar nome/número de conta, referências...',
    customerSearchText: 'Pesquisar CPF, nome/número da conta...',
    noTransactionText: 'Você ainda não tem transações.',
    noConsentsText: 'Você ainda não tem consentimentos.',
    noBalanceText: 'Você ainda não tem saldos.',
    noCustomerText: 'Você ainda não tem clientes.',
    allBanks: 'Todos os Bancos'
  },
  paymentHistory: {
    title: 'Histórico de Pagamento',
    paymentId: 'Id do Pagamento',
    reference: 'Referência',
    amount: 'Valor',
    paymentNoDataMessage: 'Desculpe! Nenhum pagamento foi feito no período de data selecionado',
    createdAt: 'Criado em',
    updatedOn: 'Atualizado em',
    consentId: 'ID do Consentimento',
    paymentDashboard: 'Painel de pagamento',
    details: 'Detalhes',
    consentTitle: 'Detalhe do Consentimento',
    consentsNoDataMessage: 'Nenhum detalhe de consentimento encontrado para o pagamento!',
    clientId: 'ID do Cliente',
    userId: 'ID do Usuário',
    searchBy: 'Pesquisar por número da conta, informação de envio, identificação da transação ou endToendId',
    searchByPlaceholder: 'Pesquisar por',
    status: 'Status',
    cpfCnpj: 'CPF/CNPJ',
    accountNumber: 'Número de conta',
    remittance: 'Informação de envio',
    transactionIdentification: 'Identificação da transação',
    paymentDetails: 'Detalhes do pagamento',
    rejectionReason: 'Motivo da rejeição',
    cancellationReason: 'Motivo do Cancelamento',
    banks: 'bancos',
    bank: 'Banco',
    overview: 'Visão geral',
    payments: 'Pagamentos',
    debtorCpf: 'CPF do devedor',
    debtorCnpj: 'CNPJ do devedor',
    debtorCpfCnpj: 'Devedor do CPF/CNPJ',
    creditorCpfCnpj: 'Credor CPF/CNPJ',
    all: 'Todos',
    date: 'Data',
    busiestBank: 'Banco mais movimentado',
    noOfBookedPayments: 'No. de pagamentos agendados',
    total: 'Montante total',
    viewDetails: 'Ver mais',
    detail: 'Detalhe',
    noData: 'Nenhum dado disponível!',
    searchByDebtor: 'Pesquisar por CPF/CNPJ do devedor',
    submittedDate: 'Data de criação',
    debtorBank: 'Banco Devedor',
    debtorBanks: 'Bancos devedores',
    scheduledDate: 'Scheduled Date',
    auditHistory: 'Audit History',
    pisClient: 'PIS Client',
    debtorsReference: 'Debtor\'s Reference',
    appId: 'App Id',
    startDate: 'Data de início',
    endDate: 'Data final',
    scheduledType: 'Tipo de agendamento',
    filter: {
      status: {
        booked: 'Realizado',
        future: 'Agendado',
        pending: 'Pendente',
        rejected: 'Rejeitado',
        cancelled: 'Cancelado'
      },
      graph: {
        booked: 'Total Realizado',
        future: 'Total Agendado',
        pending: 'Total Pendente',
        rejected: 'Total Rejeitado',
        cancelled: 'Total Cancelado'
      }
    },
    consentStatus: {
      CONSUMED: 'Consumido',
      AUTHORISED: 'Autorizado',
      AWAITING_AUTHORISATION: 'Aguardando Autorização',
      REJECTED_ASPSP: 'Rejeitado pelo Banco',
      REJECTED_PSU: 'Rejeitado pelo Cliente',
      REVOKED: 'Revogado',
      FUTURE: 'Agendado',
      RECEIVED: 'Received',
      CANCELLED: 'Cancelado',
      REJECTED: 'Rejeitado',
      BOOKED: 'Realizado'
    },
    paymentStatus: {
      booked: 'Crédito feito na instituição de destino',
      pending1: 'O início do pagamento ou a transação de pagamento está pendente.',
      pending2: 'Verificações adicionais estão em andamento.',
      future: 'O processo de agendamento foi realizado para o futuro',
      rejected: 'Instrução de pagamento rejeitada',
      cancelled: 'Pagamento cancelado pelo pagador'
    }
  },
  fintechManagement: {
    title: 'Gestão de Fintechs',
    subtitle: 'Aqui você pode gerenciar todas as fintechs.',
    fintechSearchText: 'Pesquisar nome de fintech, ID ou e-mail de administrador...',
    organizationId: 'ID da Organização',
    adminEmail: 'Email do Administrador',
    addAdminUser: 'Adicionar Usuário Administrador',
    noFintechMessage: 'Você ainda não tem nenhuma fintech. Crie novas fintechs para visualizá-las na lista.',
    deleteConfirmationMessage: ' Tem certeza de que deseja excluir a seguinte fintech',
    createdAt: 'Criado em'
  },
  userManagement: {
    title: 'Gestão de Usuários',
    subtitle: 'Aqui você pode gerenciar seus usuários',
    addNewUser: 'Adicionar Novo Usuário',
    searchText: 'Pesquisar usuário, nome, e-mail...',
    userPermissions: 'Permissões do Usuário',
    noAppDataMessage: 'Nenhum usuário encontrado. Por favor, crie novos usuário para visualizá-los na lista.',
    followingUser: 'o seguinte usuário?',
    adminUser: 'Usuário Administrador',
    adminUserDescription: `Este usuário é o
                administrador do seu banco cliente, portanto, você não pode excluir ou alterar
                as permissões ou status do usuário`,
    audit: 'Auditoria',
    auditDescription: `Só pode ver as informações específicas da
                    Fintech`,
    developer: 'Desenvolvedor',
    developerDescription: `Desenvolvedores da API para a
                Fintech`,
    fintechAdmin: 'Administrador da Fintech',
    fintechAdminDescription: `Pode executar todas as ações (incluindo
                       gestão de usuários) para a Fintech específica`
  },
  apps: {
    test: 'Teste',
    fintechId: 'FintechId',
    clientId: 'Client ID',
    obafRedirectUri: 'OBAF URI de Redirecionamento',
    product: 'Produto',
    editApplicationClient: 'Editar Cliente de Aplicativo',
    createApplicationClient: 'Criar Cliente de Aplicativo',
    fintechRedirectUri: 'URI de Redirecionamento da Fintech',
    aspspClients: 'Clientes ASPSP',
    redirectUrl: 'URL de Redirecionamento',
    webhookConfiguration: 'Configuração do Webhook'
  },
  contact: {
    messageSentSuccessfully: 'Mensagem enviada com sucesso',
    topTitle: 'Vamos nos conectar',
    title: 'Fale com um Especialista em Tempo Real',
    description:
      'Se você precisar de algum suporte usando nosso serviço ou tiver algum comentário, informe-nos usando o formulário abaixo.',
    firstName: 'Nome',
    lastName: 'Sobrenome',
    email: 'E-mail',
    message: 'Mensagem',
    submitNow: 'Enviar Agora',
    officeAddress: 'Endereço Comercial',
    website: 'Site',
    phone: 'Telefone',
    companyNumber: 'Número da Empresa',
    navigation: 'Navegação',
    home: 'Início',
    developer: 'Desenvolvedores',
    contactUs: 'Contate-nos',
    legals: 'Jurídicos',
    privacyPolicy: 'Política de Privacidade',
    termsAndConditions: 'Termos e Condições',
    cookiesPolicy: 'Política de Cookies',
    getInTouch: 'Entrar em contato',
    allRightsReserved: 'Todos os direitos reservados',
    registrationNumber: 'Número de registro',
    registeredAddress: 'Endereço de escritório registrado',
    dashboard: 'Visão Geral',
    contact: 'Contato',
    alreadyHaveAccount: 'Já tem uma conta'
  },
  documentation: {
    userGuides: 'Guia do Usuário',
    faq: 'FAQ',
    accountsApi: 'API de Contas',
    paymentsApi: 'API de Pagamentos',
    sdkIntegration: 'SDK de Integração',
    title: 'Documentação',
    developerOnboarding: 'Onboarding do Desenvolvedor',
    gettingStarted: 'Começando',
    userManagement: 'Gerenciamento de usuários',
    aspspClientManagement: 'Gestão de Clientes ASPSP',
    dcr: 'Cadastro Dinâmico de Clientes',
    softwareStatement: 'Obter declaração de declaração de software',
    aispFlow: 'Fluxo do serviço de informações da conta',
    pispFlow: 'Fluxo do Serviço de Iniciação de Pagamento',
    testingGuides: 'Guias de teste'
  },
  lending: {
    title: 'Empréstimo',
    searchText: 'Pesquise CPF, nome/número da conta...',
    allBanks: 'Todos os Bancos',
    lendingNoDataMessage: 'Você ainda não tem nenhum empréstimo.',
    accountName: 'Nome da Conta',
    noOfTransaction: 'No. de Transferência',
    lastTransaction: 'Última Data de Transferência',
    debit: 'Débito',
    credit: 'Crédito',
    currentBalance: 'Saldo Atual',
    noLendingMessage: 'Você ainda não tem clientes.',
    lendingEvaluation: 'Avaliação de Empréstimos',
    sendDetailsTo: 'Envie os dados acima para'
  },
  productDetails: {
    title: 'Produtos',
    subtitle: ' Aqui você pode gerenciar seus aplicativos.',
    applications: 'Aplicações',
    forMoreIntegration: 'Para mais integrações, entre em contato',
    fintechAdmin: 'Administrador da Fintech',
    checkAllBanks: 'Verifique Todos os Bancos Listados',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    applicationRole: 'Aqui você pode gerenciar seus aplicativos.',
    noApplicationText:
      'Você ainda não tem nenhum aplicativo. Crie novos aplicativos para visualizá-los na lista.'
  },
  userAnalytics: {
    title: 'Gerenciar Aplicativo',
    subtitle: 'Mostrando análises dos últimos 7 dias',
    testingProduct: 'Produto de Teste',
    bankA: 'Banco A',
    bankB: 'Banco B',
    bankC: 'Banco C',
    upTime: 'Tempo de Atividade',
    statusCode: 'Códigos de Status',
    usage: 'Uso',
    oneDay: '1 Dia',
    week: '7 Dias',
    month: '1 Mês',
    showLast: 'Exibir Último',
    sortBy: 'Ordenar por',
    sortByApp: 'Ordenar por App',
    checking1: 'Checando 1'
  },
  helmetTitle: {
    authenticating: 'Autenticando',
    register: 'Registro',
    session: 'Sessão Expirada'
  },
  profile: {
    title: 'Perfil',
    subtitle: 'Aqui você pode gerenciar sua conta.',
    email: 'E-mail',
    fullName: 'Nome Completo',
    phone: 'Telefone',
    organizationName: 'Nome da Organização',
    organizationLogo: 'Logomarcar da Organização',
    organizationStatus: 'Status da Organização',
    contactPerson: 'Pessoa de Contato',
    contactPhone: 'Telefone de Contato',
    contactAddress: 'Endereço de Contato',
    country: 'País',
    dateOfBirth: 'Data de Nascimento',
    jobTitle: 'Cargo',
    accountSettings: 'Configurações de Conta',
    password: 'Senha',
    temporaryPassword: 'Senha Temporária',
    oldPassword: 'Senha Antiga',
    newPassword: 'Nova Senha',
    retypeNewPassword: 'Redigite a nova senha',
    atLeastCharacters: 'Pelo menos 8 caracteres',
    atLeastUpperCaseLetter: 'Pelo menos 1 letra maiúscula',
    atLeastNumber: 'Pelo menos 1 número',
    atLeastSpecialCharacters: 'Pelo menos 1 caracter especial ! @ # $ ~',
    registrationSuccess:
      'Sucesso na inscrição! A equipe do Portal OBAF entrará em contato em breve.',
    agreeTo: 'Eu concordo com o',
    termsAndConditions: 'Termos e condições',
    websiteAgree: 'Ao usar este site você concorda com nossos',
    privacy: 'Privacidade',
    logOut: 'sair'
  },
  navigationLabels: {
    products: 'Produtos',
    productDetail: 'Produto Dados',
    documentation: 'Documentação',
    apiAnalytics: 'Métricas de Finanças Abertas',
    apiKeys: 'Chaves da API',
    apiAnalyticsDetail: 'Detalhes de análise de API',
    userManagement: 'Gestão de Usuários',
    aspspClientManagement: 'Gestão de Clientes ASPSP',
    dcrManagement: 'Gerenciamento de DCR',
    customers: 'Clientes',
    lending: 'Empréstimo',
    paymentHistory: 'Histórico de Pagamento',
    fintechManagement: 'Gestão de Fintechs'
  }
}
