import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'
const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/users`;

const users = {
  list: (params, userId) =>
    request({
      uri: `${baseUrl}?${new URLSearchParams(params).toString()}` , method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }
    }),
  get: (id) => request({ uri: `${baseUrl}/${id}` }),
  create: (body) => request({ uri: baseUrl, method: 'POST', body }),
  signUp: (body) =>
    request({ uri: `${baseUrl}/sign-up`, method: 'POST', body }),
  update: (id, body) =>
    request({ uri: `${baseUrl}/${id}`, method: 'PUT', body }),
  delete: (id) => request({ uri: `${baseUrl}/${id}`, method: 'DELETE' })
}

export default users;