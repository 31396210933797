import config from 'components/config';
import { getSession } from 'components/lib/session';
import { getRequestEntity } from 'components/lib/entity';

import cache from './cache';
import refreshToken from './refresh';
import i18n from 'localization';
const t = i18n?.t || (x => x);

const { commonResponseError } = config;

const stringify = (body) =>
  typeof body === 'object'
    ? JSON.stringify(body)
    : null;

export const authorization = () => {
  const sessionData = getSession();

  if (sessionData && sessionData.accessToken) {
    return {
      authorization: `Bearer ${sessionData.accessToken}`
    };
  }

  return {};
}

export const entityHeader = () => {
  const entity = getRequestEntity();

  if (entity) {
    return { 'ent-id': entity };
  }

  return {};
}

export default ({
  uri,
  method = 'GET',
  headers,
  body
}) => {
  const requestCall = () =>
    fetch(cache(method, uri), {
      method,
      headers: {
        ...authorization(),
        ...headers,
        'accept': 'application/json',
        'content-type': 'application/json'
      },
      body: body && method !== 'GET'
        ? stringify(body)
        : undefined, // eslint-disable-line no-undefined
      credentials: 'include',
      mode: 'cors',
      cache: 'no-store'
    })
      .catch(() => {
        throw new Error(
          commonResponseError
        );
      })
      .then((res) => {
        if (res.ok) {
          return res.status === 204
            ? Promise.resolve({ data: null })
            : res.json();
        }

        switch (res.status) {
          case 404: throw new Error(t('common.errorMessage.notFound'))
          case 429: throw new Error(t('common.errorMessage.tooManyRequests'))
        }

        if (res.status === 401) {
          return refreshToken()
            .then(requestCall);
        }

        return res.json()
          .catch(() =>
            Promise.reject({
              status: res.status,
              message: commonResponseError
            })
          )
          .then((res) =>
            Promise.reject(res)
          )
      });

  return requestCall();

}
