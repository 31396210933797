export default {
  'tabTitle': 'Termos e Condições',
  'mainHeading': 'Termos e Condições de Uso',
  'subTitle': 'Ao usar o OBAF Portal, você (o “Usuário”) aprova os seguintes termos e condições (o “Contrato”):',
  'lastModified': 'Última modificação: 19 de junho de 2023',
  'section1': {
    'title': 'Introdução',
    'description1': 'Estes termos e condições se aplicam entre você, o usuário deste site (incluindo quaisquer subdomínios, a menos que expressamente excluídos por seus próprios termos e condições) e Banfico Ltd, o proprietário e operador deste site. Por favor, leia estes termos e condições com atenção, pois eles afetam seus direitos legais. Sua concordância em cumprir e estar vinculado a estes termos e condições é considerada como ocorrendo em seu primeiro uso do site. Se você não concorda em ficar vinculado a estes termos e condições, deve parar de usar o site imediatamente.',
    'description2': 'Nestes termos e condições, Utilizador ou Utilizadores significam qualquer terceiro que aceda ao Website e que não seja (i) empregado do Banfico Ltd e a atuar no exercício das suas funções ou (ii) contratado como consultor ou prestador de qualquer outra forma de serviços ao Banfico Ltd e acessando o site em conexão com a prestação de tais serviços.',
    'description3': 'Você deve ter pelo menos 18 anos de idade para usar este site. Ao usar o site e concordar com estes termos e condições, você declara e garante que tem pelo menos 18 anos de idade.'
  },
  'section2': {
    'title': 'Propriedade intelectual e uso aceitável',
    'orderedList1': 'Todo o conteúdo incluído no site, a menos que seja carregado pelos usuários, é propriedade do Banfico Ltd, de nossas afiliadas ou de terceiros relevantes. Nestes termos e condições, Conteúdo significa qualquer texto, gráficos, imagens, áudio, vídeo, software, compilações de dados, layout de página, código subjacente e software e qualquer outra forma de informação capaz de ser armazenada em um computador que apareça ou faça parte deste site, incluindo qualquer conteúdo carregado pelos usuários. Ao continuar a usar o site, você reconhece que tal conteúdo é protegido por direitos autorais, marcas registradas, direitos de banco de dados e outros direitos de propriedade intelectual. Nada neste site deve ser interpretado como concessão, por implicação, preclusão ou de outra forma, qualquer licença ou direito de usar qualquer marca registrada, logotipo ou marca de serviço exibida no site sem a permissão prévia por escrito do proprietário',
    'orderedList2': 'Você pode, apenas para seu uso pessoal e não comercial, fazer o seguinte:',
    'orderedList3': 'Você não deve reproduzir, modificar, copiar, distribuir ou usar para fins comerciais qualquer Conteúdo sem a permissão por escrito do Banfico Ltd.',
    'unorderedList1': 'recuperar, exibir e visualizar o Conteúdo em uma tela de computador',
    'unorderedList2': 'imprimir uma cópia do Conteúdo'
  },
  'section3': {
    'title': 'Uso proibido',
    'orderedList1': 'Você não pode usar o Site para nenhuma das seguintes finalidades:',
    'unorderedList1': 'de qualquer forma que cause ou possa causar danos ao Site ou interfira no uso ou aproveitamento do Site por qualquer outra pessoa;',
    'unorderedList2': 'de qualquer forma que seja prejudicial, ilegal, ilegal, abusiva, assediante, ameaçadora ou censurável ou que viole qualquer lei, regulamento ou ordem governamental aplicável;',
    'unorderedList3': 'fazer, transmitir ou armazenar cópias eletrônicas de Conteúdo protegido por direitos autorais sem a permissão do proprietário.'
  },
  'section4': {
    'title': 'Cadastro',
    'orderedList1': 'Você deve garantir que os detalhes fornecidos por você no registro ou a qualquer momento estejam corretos e completos.',
    'orderedList2': 'Você deve nos informar imediatamente sobre quaisquer alterações nas informações fornecidas ao se registrar, atualizando seus dados pessoais para garantir que possamos nos comunicar com você de maneira eficaz.',
    'orderedList3': 'Podemos suspender ou cancelar seu registro com efeito imediato para quaisquer fins razoáveis ​​ou se você violar estes termos e condições.',
    'orderedList4': 'Você pode cancelar seu registro a qualquer momento, informando-nos por escrito para o endereço no final destes termos e condições. Se você fizer isso, você deve parar imediatamente de usar o site. O cancelamento ou suspensão do seu registo não afeta quaisquer direitos estatutários.'
  },
  'section5': {
    'title': 'Detalhes Banfico Ltd',
    'orderedList1': 'Banfico Ltd é uma empresa constituída na Inglaterra e País de Gales com o número registrado 11036752 cujo endereço registrado é 1 Canada Square, Level39, Canary Wharf, Londres, E14 5AB e opera o site www.banfico.com. O número de IVA registado é 294974636.',
    'description': 'Você pode entrar em contato com o Banfico Ltd por e-mail em openbanking@banfico.com.'
  }
}
