export default {
  'tabTitle': 'Terms and Conditions',
  'mainHeading': 'Terms and conditions of use',
  'subTitle': 'By using OBAF Portal you (the “User”) approve of the following terms and conditions (the “Agreement”):',
  'lastModified': 'Last modified: June 19, 2023',
  'section1': {
    'title': 'Introduction',
    'description1': 'These terms and conditions apply between you, the User of this Website (including any sub-domains, unless expressly excluded by their own terms and conditions), and Banfico Ltd, the owner and operator of this Website. Please read these terms and conditions carefully, as they affect your legal rights. Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon your first use of the Website. If you do not agree to be bound by these terms and conditions, you should stop using the Website immediately.',
    'description2': 'In these terms and conditions, User or Users means any third party that accesses the Website and is not either (i) employed by Banfico Ltd and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Banfico Ltd and accessing the Website in connection with the provision of such services.',
    'description3': 'You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms and conditions, you represent and warrant that you are at least 18 years of age.'
  },
  'section2': {
    'title': 'Intellectual property and acceptable use',
    'orderedList1': 'All Content included on the Website, unless uploaded by Users, is the property of Banfico Ltd, our affiliates or other relevant third parties. In these terms and conditions, Content means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website, including any such content uploaded by Users. By continuing to use the Website you acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual property rights. Nothing on this site shall be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the site without the owner\'s prior written permission',
    'orderedList2': 'You may, for your own personal, non-commercial use only, do the following:',
    'orderedList3': 'You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any Content without the written permission of Banfico Ltd.',
    'unorderedList1': 'retrieve, display and view the Content on a computer screen',
    'unorderedList2': 'print one copy of the Content'
  },
  'section3': {
    'title': 'Prohibited use',
    'orderedList1': 'You may not use the Website for any of the following purposes:',
    'unorderedList1': 'in any way which causes, or may cause, damage to the Website or interferes with any other person\'s use or enjoyment of the Website;',
    'unorderedList2': 'in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise objectionable or in breach of any applicable law, regulation, governmental order;',
    'unorderedList3': 'making, transmitting or storing electronic copies of Content protected by copyright without the permission of the owner.'
  },
  'section4': {
    'title': 'Registration',
    'orderedList1': 'You must ensure that the details provided by you on registration or at any time are correct and complete.',
    'orderedList2': 'You must inform us immediately of any changes to the information that you provide when registering by updating your personal details to ensure we can communicate with you effectively.',
    'orderedList3': 'We may suspend or cancel your registration with immediate effect for any reasonable purposes or if you breach these terms and conditions.',
    'orderedList4': 'You may cancel your registration at any time by informing us in writing to the address at the end of these terms and conditions. If you do so, you must immediately stop using the Website. Cancellation or suspension of your registration does not affect any statutory rights.'
  },
  'section5': {
    'title': 'Banfico Ltd details',
    'orderedList1': 'Banfico Ltd is a company incorporated in England and Wales with registered number 11036752 whose registered address is 1 Canada Square, Level39, Canary Wharf, London, E14 5AB and it operates the Website www.banfico.com. The registered VAT number is 294974636.',
    'description': 'You can contact Banfico Ltd by email on openbanking@banfico.com.'
  }
}
