import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/multi`;

const multi = {
  bulk: (bulkName, body) => request({ uri: `${baseUrl}/${bulkName}`, method: 'POST', body })
}

export default multi;