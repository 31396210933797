/* eslint-disable no-process-env */
const {
	tenantName,
	psd2ApiSpec,

	primaryColor,
	secondaryColor,

	paymentsDownloadLimit,

	appHost,
	appApi,
	appLogo,

	apiAccessAuth,
	apiSwagger,

	authHost,
	authClientId,
	authResponseType,
	authScope,

	corebankWebsite,
	disableSwaggerPages,
	enableSwaggerPreview,

	mainpageHeader,
	referenceSignupHeader,
	referenceSignupContent,

	recaptchaEnabled,
	recaptchaSitekey,

	companyName,
	companyAddressLine1,
	companyAddressLine2,
	companyAddressCity,
	companyAddressPostcode,
	companyRegistrationNo,
	companyWebsite,
	companyEmail,
	companyPhone,
	companyStaticLinks,

	companyEntities,

	customCss,

	platform,

	tenant,
	logoUrl,
	privacyPolicy,
	termsConditions,
	tenantDataLoadDate,
	tenantProductType
} = typeof window !== 'undefined'
	? window._DEFAULTS || process.env
	: process.env;

const apiBase = `${appApi}/api`;

const config = {

	tenantName,
	psd2ApiSpec,

	appLogo,
	appHost,
	appApi,
	apiBase,

	apiAccessAuth,
	apiSwagger,

	primaryColor,
	secondaryColor,

	paymentsDownloadLimit,

	disableSwaggerPages,
	enableSwaggerPreview,

	mainpageHeader,
	referenceSignupHeader,
	referenceSignupContent,

	companyName,
	companyAddressLine1,
	companyAddressLine2,
	companyAddressCity,
	companyAddressPostcode,
	companyRegistrationNo,
	companyWebsite,
	companyEmail,
	companyPhone,
	companyStaticLinks,

	companyEntities,

	authHost,
	authClientId,
	authResponseType,
	authScope,
	authRedirectUri: `${appHost}/auth/callback`,

	corebankWebsite,

	apiAuth: {
		session: `${apiBase}/session`,
		register: `${apiBase}/register`,
		activate: `${apiBase}/activate`,
		exchange: `${apiBase}/exchange`,
		refresh: `${apiBase}/refresh`,
		password: `${apiBase}/password`,
		logout: `${apiBase}/logout`
	},

	oauth2RedirectUrl: `${appHost}/callback`,

	defaultScopes: [
		'openid',
		'profile',
		'email'
	],

	recaptcha: {
		enabled: recaptchaEnabled,
		sitekey: recaptchaSitekey
	},

	timeFormat: 'DD MMM YYYY HH:mm',

	paths: {
		login: '/',
		register: '/register',
		verification: '/verification',
		logged: '/user/dashboard'
	},

	commonResponseError: 'something went wrong',

	platformApiAispCallback: (platform || {}).callack,
	platformApiPispCallback: (platform || {}).pispCallback,

	tenant,
	logoUrl,
	privacyPolicy,
	termsConditions,
	tenantDataLoadDate,
	tenantProductType
};

if (typeof window !== 'undefined' && !window._DEFAULTS) {
	const style = document.createElement('style');

	style.innerHTML = `:root{--primary-color:${
		primaryColor
	};--secondary-color:${
		secondaryColor
	}}`;

	document.head.appendChild(style);
}

if (customCss) {
	const style = document.createElement('style');

	style.innerHTML = customCss;
	document.head.appendChild(style);
}

// if (typeof window !== 'undefined') {
// 	console.log('* config', config);
// }

export default config