/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable */
import React from 'react';
import { SessionContextProvider } from "./src/components/context/session-context"
import { FintechContextProvider } from "./src/components/context/fintech-context"
import { LanguageContextProvider } from './src/components/context/language-context';
require('whatwg-fetch');

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

(function (window) {
  if (typeof window.URLSearchParams === 'undefined') {
    window.URLSearchParams = function (searchString) {
      var self = this;

      self.searchString = searchString;
      self.get = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);

        if (results == null) return null;
        else return decodeURI(results[1]) || 0;
      };
    }
  }
})(window);



export const wrapRootElement = ({ element }) => (
  <LanguageContextProvider>
    <SessionContextProvider>
      <FintechContextProvider>
        {element}
      </FintechContextProvider>
    </SessionContextProvider>
  </LanguageContextProvider>
)
