export default {
  environment: {
    environment: 'Environment'
  },
  faq: {
    title: 'Frequently Asked Questions',
    questionsHere: 'Questions? Look here',
    data: {
      q1: 'What does the developer portal cover in terms of APIs?',
      a1: 'This developer portal is published as per PSD2 mandate & APIs are based on Open Banking UK specification. It covers AISP and PISP functionalities.',
      q2: 'Is there separate API for corporate & retail banking customers?',
      a2: 'No. Its the same APIs for both retail & corporate customers. They way customers login & authorise may be different but the APIs itself are based on Open Banking UK specification',
      q3: 'How is consent gathered for AISP flow?',
      a3: 'Our API specification only supports *Bank Offered Consent*, which means the AISP doesn\'t explicitly specify the list of customer accounts of customers during Consent Request. But during consent capture, bank will display list of all available bank accounts that customer can choose to share Account Information.',
      q4: 'Do Developer/TPP need to be authorised on NCA register?',
      a4: 'We do expect developers to be licensed. However this is flexible in test or sandbox systems. We use Open Banking Europe directory services to connect to 31 NCAs.',
      q5: 'Do I get test bank customer accounts to perform AISP/PISP flows?',
      a5: 'Yes. Actually you could however register as a bank customer on our reference bank application. These customers can create their own list of accounts, balances & transactions.',
      q6: 'How can consent be revoked?',
      a6: 'Bank customers can login into reference bank application & manage their consent. `Revoke` for relevant AISP grants',
      q7: 'Are there any other API specification supported?',
      a7: 'No. We currently only support Open Banking UK API specification.',
      q8: 'Can developers create more apps & subscribe to same AISP/PISP API product?',
      a8: 'Yes. In production mode, developers can only create APP that has NCA license associated with it. However in test-mode, which is currently deployed, developers can create any number of AISP or PISP apps.'
    }
  },
  sidebar: {
    connectedFintechs: 'Connected Fintechs'
  },
  htmlView: {
    onThisPage: 'On this page'
  },
  product: {
    product: 'Product'
  },
  role: {
    role: 'User Permissions'
  },
  swagger: {
    application: 'application',
    authorized: 'Authorized',
    authorizationUrl: 'Authorization URL',
    tokenUrl: 'Token URL',
    flow: 'Flow',
    username: 'Username',
    password: 'Password',
    type: 'Type',
    requestBody: 'Request body',
    basicAuth: 'Basic auth',
    queryParameters: 'Query parameters',
    clientId: 'Client Id',
    clientSecret: 'Client Secret',
    scopes: 'Scopes',
    authorize: 'Authorize'
  }
}