export default {
  add: 'Add New',
  application: 'Application',
  addedSuccessfully: 'Added successfully',
  back: 'Back',
  bank: 'Bank',
  cancel: 'Cancel',
  close: 'Close',
  confirm: 'Confirm',
  chooseBank: 'Choose Bank',
  chooseAccount: 'Choose Account',
  connectedBanks: 'Connected Banks',
  create: 'Create',
  created: 'Created',
  clear: 'Clear',
  clearAll: 'Clear All',
  search: 'Search',
  copyToClipboard: 'Copy to clipboard',
  date: 'Date',
  createdDate: 'Created Date',
  dateRange: 'Date Range',
  download: 'Download',
  delete: 'Delete',
  developer: 'Developer',
  edit: 'Edit',
  error: 'Error',
  email: 'Email',
  export: 'Export',
  fintech: 'Fintech',
  getStarted: 'Get started',
  home: 'Home',
  login: 'Login',
  loading: 'Loading',
  main: 'main',
  message: 'Message',
  noDataFound: 'No data found',
  name: 'Name',
  next: 'Next',
  pleaseConfirm: 'Please confirm',
  product: 'Product',
  save: 'Save',
  send: 'Send',
  subscribe: 'Subscribe',
  saveChanges: 'Save Changes',
  status: 'Status',
  settings: 'Settings',
  statement: 'Statements',
  register: 'Register',
  registration: 'Registration',
  resume: 'Resume',
  revoke: 'Revoke',
  signUp: 'Sign Up',
  signIn: 'Sign In',
  remove: 'Remove',
  test: 'Test',
  today: 'today',
  user: 'User',
  update: 'Update',
  updatedSuccessfully: 'Updated successfully',
  viewDetails: 'View',
  viewDocs: 'View Docs',
  website: 'Website',
  tableStatus: {
    completed: 'Completed',
    failure: 'Error',
    active: 'Active',
    inActive: 'Inactive'
  },
  errorMessage: {
    internalError: 'sorry but an internal error has occurred.',
    missingPassword: 'Missing password',
    missingOrganization: 'Missing Organization Name',
    solveCaptcha: 'Kindly solve the captcha',
    isRequired: 'is required',
    notFound: 'not found',
    tooManyRequests: 'too many requests',
    provideOldPassword: 'Please provide old password',
    passwordDontMatch: 'Password do not match',
    newPasswordSame: 'New password must be different from the previous password',
    noAccessCode: 'no access code provided',
    authenticationError: 'Please login again!'
  },
  confirmationMessage: {
    delete: 'Are you sure you want to delete'
  },
  testing: {
    mainTitle: 'OBAF',
    availableBrazil: 'Available in Brazil'
  },
  passwordRules: {
    lengthRule: 'Password must contain at least 8 characters',
    digitRule: 'Password must contain at least 1 digit',
    upperCaseRule: 'Password must contain at least 1 upper case character',
    numberRule: 'Password must contain at least 1 lower case character',
    charRule: 'Password must contain at least 1 special character (!@#$~)'
  }
}