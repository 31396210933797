import request from './';
import config from 'components/config';
import { getNewApiBaseUrl } from 'components/common/helpers'

import {
  setSession,
  getSession,
  removeSession
} from 'components/lib/session';

const { apiAuth } = config;

let REFRESH_LOCK = null;

const logout = () => {
  removeSession();
  location.href = '/expired';

  return Promise.reject(
    'authentication required'
  );
}

export default () => {
  const {
    refreshToken
  } = getSession() || {};

  if (!refreshToken) {
    return logout();
  }

  if (REFRESH_LOCK) {
    return REFRESH_LOCK;
  }

  const refreshCall = () => request({
    uri: getNewApiBaseUrl(apiAuth.refresh),
    method: 'POST',
    body: { refreshToken }
  })
    .then(({ data }) => {
      REFRESH_LOCK = null;

      const {
        idToken,
        accessToken,
        refreshToken
      } = data || {};

      if (accessToken) {
        setSession({
          idToken,
          accessToken,
          refreshToken,
          timestamp: new Date().getTime()
        });
      }
    });

  return REFRESH_LOCK = refreshCall()
    .catch(({ status }) => {
      if (status === 401) {
        return removeSession();
      }

      // giving second chance in 3 seconds
      return new Promise((resolve) =>
        setTimeout(() => {
          REFRESH_LOCK = refreshCall()
            .then(resolve)
            .catch(logout)
        }, 3000)
      )
    });
}