import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/contact`;

const apps = {
  message: (body) => request({ uri: `${baseUrl}`, method: 'POST', body })
}

export default apps;