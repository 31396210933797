
import {use} from 'i18next';
import isBrowser from 'components/lib/isbrowser';
import {initReactI18next} from 'react-i18next';
import en from './en';
import pt from './pt';

const LANG_KEY = 'lang';
const DEFAULT_LANG = 'en';

const defaultLanguage = isBrowser ? (localStorage.getItem(LANG_KEY) || DEFAULT_LANG) : DEFAULT_LANG

// const i18n = isBrowser ? use(initReactI18next) : null;
const i18n = use(initReactI18next);

export const init = () => i18n?.init({
  resources: {
    en: {
      translation: en
    },
    pt: {
      translation: pt
    }
  },
  lng: defaultLanguage,
  // fallbackLng: 'en',

  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  initImmediate: true
});

export const changeLanguage = (newLanguage) => {
  i18n?.changeLanguage(newLanguage);
  localStorage.setItem(LANG_KEY, newLanguage);
}

export default i18n;