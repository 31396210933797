import React, { useContext, useState, createContext, useMemo, useEffect, useCallback } from 'react';
import { loggedUser } from 'components/lib/auth';
import { getSession } from 'components/lib/session';

export const SessionContext = createContext({});

const session = getSession();

export const SessionContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(!!session);
  const [errorMessage, setErrorMessage] = useState('');

  const loadUser = useCallback(async () => {
    setUserLoading(true);

    try {
      const { data } = await loggedUser();
      if (!data.userType) {
        data.userType = 'fintech-admin';
      }
      setUser(data);
    } catch (err) {
      setUser(null)
      setErrorMessage(err?.message);
      console.log(err);
    }

    setUserLoading(false);
  }, []);

  const loadSession = useCallback(() => {
    const session = getSession();
    setLoggedIn(!!session);

    if (session) {
      loadUser();
    }
  }, [loadUser]);

  useEffect(() => {
    loadSession();
  }, [loadSession]);

  const value = useMemo(() => ({
    user,
    userLoading,
    loggedIn,
    errorMessage,
    loadSession
  }), [user, userLoading, loggedIn, loadSession]);

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => useContext(SessionContext);

