import apps from './apps';
import dcrClients from './dcrClients';
import clients from './clients';
import contact from './contact';
import metrics from './metrics';
import aspsps from './aspsps';
import users from './users';
import roles from './roles';
import tpps from './tpps';
import multi from './multi';
import providers from './providers';
import payments from './payments';

const Service = {
  apps,
  clients,
  contact,
  dcrClients,
  metrics,
  multi,
  aspsps,
  users,
  roles,
  tpps,
  providers,
  payments
}

export default Service;

