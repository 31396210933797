import config from 'components/config';

import isBrowser from 'components/lib/isbrowser';
import safeParse from 'components/lib/safe-parse';
import { getUserEntity } from 'components/lib/session';

export const entities =
  safeParse(config.companyEntities);

export const getDefault = () =>
  entities && entities[0] && entities[0].value;

export const getEntity = () =>
  isBrowser
    ? (localStorage.getItem('entity') || getDefault())
    : getDefault()

export const setEntity = (entity) =>
  localStorage.setItem('entity', entity);

export const getRequestEntity = () =>
  getUserEntity() || getEntity()
