import config from 'components/config'
import request from 'components/lib/request'
import {getNewApiBaseUrl} from 'components/common/helpers'

const { apiBase } = config
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/payments`

const payments = {
  paymentHistory: (fintechId,userId, filter, paymentDateOrder, start, size) => {
    const queryString = new URLSearchParams({
      ...filter,
      paymentDateOrder,
      start,
      size
    }).toString();
    const filterQueryString = new URLSearchParams({
      ...filter,
      start: 0,
      size: 10000
  
    })
    if (!start && !size) {
      return request({ uri: `${baseUrl}/domestic/${fintechId}?${filterQueryString}`, method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }  });
    } else {
      return request({ uri: `${baseUrl}/domestic/${fintechId}?${queryString}`, method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }  });

    }
    // return request({ uri: `${baseUrl}/domestic/${fintechId}?${queryString}` });
  },
  selectedPaymentDetails: (fintechId, paymentId, page, limit, userId) =>
    request({ uri: `${baseUrl}/consent/${fintechId}/${paymentId}?page=${page}&size=${limit}` , method: 'GET', headers: userId?.toString() !== 'undefined' && userId?.length > 0 && { fintechId: userId }  })
}

export default payments;
