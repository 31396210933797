import isBrowser from 'components/lib/isbrowser';

/* eslint-disable no-unused-vars, no-empty-function */
const safeStorage = {
  getItem(key) { },
  setItem(key, data) { },
  removeItem(key) { }
};
/* eslint-enable no-unused-vars, no-empty-function */

export default isBrowser ? sessionStorage : safeStorage;