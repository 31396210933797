export default {
  'tabTitle': 'Privacy Policy',
  'mainHeading': 'Privacy Policy',
  'subTitle': 'Your privacy is important to us. We process all information about you in a gentle and responsible manner.',
  'lastModified': 'Last modified: June 1, 2023',
  'description': 'This Privacy Policy (“Policy”) indicates how your personal data is treated due to the use of the Services related to the processing of payments related to purchases made by you on the websites of our customers. This document forms an integral part of the EBANX Terms and Conditions, which contain an overview of the Services. By using our Services, you agree to our Policy and authorize us to process your personal data in the way described here to provide our Services properly.',
  'sideHeading': 'We break down this Policy by answering the following questions:',
  'desc1': 'Who is EBANX?',
  'desc2': 'What types of personal data does EBANX collect?',
  'desc3': 'How does EBANX collect personal data?',
  'desc4': 'Why does EBANX collect personal data?',
  'desc5': 'With whom can EBANX share personal data?',
  'desc6': 'Can EBANX transfer personal data internationally?',
  'desc7': 'How long will personal data be stored?',
  'desc8': 'What are the personal data security standards adopted by EBANX?',
  'desc9': 'What are your rights as a data subject?',
  'desc10': 'And the cookies?',
  'desc11': 'How to talk to EBANX about privacy?',
  'desc12': 'What happens if this Policy is changed?',

  'section1': {
    'title': 'Who is EBANX?',
    'description1': 'EBANX is a payment processing company that provides local payment methods on international websites (“Merchants”).',
    'description2': 'In purchases made by you at Merchants that process payments with EBANX, we act as the operator of your personal data: we are responsible for processing the payment for purchases of services or products made by you on the websites that offer you our means of payment.',
    'description3': 'Furthermore, EBANX may act as controller of your personal data in certain situations for its own commercial purposes, such as when we send notifications about purchases made by you with EBANX payment methods or when we take preventive and reactive measures to identify, prevent and remedy fraud and security threats.',
    'description4': 'To provide our Services, we process your personal data in accordance with the legitimate instructions of the website where you purchased services or products using EBANX payment methods, in accordance with this Policy or in accordance with applicable data protection legislation. .',
    'description5': 'This Policy only refers to the Services provided by EBANX. The products and services you purchase from our customers are subject to their own terms of use and privacy policies, as determined by them. In addition, the payment methods you use may also be subject to terms of use and privacy policies set forth by financial institutions and card issuers.'
  },
  'section2': {
    'title': 'What kind of personal data does EBANX collect?',
    'description1': 'Some data that we use in order to be able to provide our Services to you may be considered personal data – that is, information relating to you that, individually or in the aggregate, can identify you. We may use the following data for you to enjoy our Services, as well as to improve them:',
    'orderedList1': 'Identity Data: includes information about your identity, such as full name, tax identification number, date of birth, address, email, mother\'s name and telephone number.',
    'orderedList2': 'Financial Data: includes information about payment details, banking information and information about the payment methods used by you to buy from e-commerces that are our customers.',
    'orderedList3': 'Technical Data: includes information about your IP, time and date of access, geolocation, data about your access device and cookies.',
    'orderedList4': 'Usage Data: Information about how you use our Service, such as purchasing profile and behavior and transaction volume.',
    'orderedList5': 'Biometric Data: includes information such as photos of your documents and personal photos.',
    'description2': 'We may also collect aggregated data, such as statistical or demographic data. They Aggregates may be derived from your personal data, but they are not considered personal data under the legislation, as they do not reveal your identity directly or indirectly.',
    'description3': 'Biometric Data is the only sensitive data that we may intend to process solely for the purpose of providing security for you and our Services. Don\'t worry, you will be notified when we need this type of data.'
  },
  'section3': {
    'title': 'How does EBANX collect personal data?',
    'description1': 'You may provide us with Identity Data and Financial Data when using our Payment Processing Services. We may also collect personal data about you when you contact us through our customer service and communication channels. We may request Biometric Data for the sole purpose of preventing fraud and security threats and to confirm your identity.',
    'description2': 'When interacting with our Services, we may automatically collect data, such as Technical Data and Usage Data, through cookies, registrations in services, applications, software and similar technologies.',
    'description3': 'We may also receive your personal data through the Merchants from whom you purchase products or services with payment processing by EBANX.',
    'description4': 'In addition, for your security and to improve our Services, we may receive personal data about you from third parties and public sources, such as companies that introduce us to you, government agencies and service providers, including fraud prevention agencies.'
  },
  'section4': {
    'title': 'Why does EBANX collect personal data?',
    'description1': 'Your personal data are used in accordance with the purposes presented below, with their respective legal bases, which authorize their treatment:',
    'tableData': {
      'section1': {
        'head': 'GOAL',
        'row1': 'To provide our Services, including processing payments, chargebacks and refunds, sending transaction notifications, verifying identity, identifying and preventing fraud and security threats, analyzing data, maintaining systems, hosting data, and fulfilling our obligations legal and regulatory',
        'row2': 'To analyze transactions and consumption profile, for behavioral analysis purposes and to prevent fraud and security threats, to track and improve our performance and improve our Services',
        'row3': 'To manage our relationship with you, including service via our communication and customer service channels, which may involve procedures to confirm your identity and prevent fraud',
        'row4': 'For sending direct marketing communications to you via email or text message or digital communication channels'
      },
      'section2': {
        'head': 'PERSONAL DATA',
        'row1': 'Identity Data, Financial Data, Technical Data and Usage Data',
        'row2': 'Identity Data, Financial Data, Technical Data and Usage Data',
        'row3': 'Identity Data, Financial Data, Technical Data and Usage Data and Biometric Data',
        'row4': 'Identity Data'
      },
      'section3': {
        'head': 'LEGAL BASE',
        'row1': 'Legal obligation, fulfillment of contract with you and legitimate interest (developing and improving our Services, prevention and security when processing payments)',
        'row2': 'Legitimate interest (developing and improving our Services)',
        'row3': 'Legal obligation, contract compliance and legitimate interest (developing and improving our services)',
        'row4': 'Consent and legitimate interest (developing and improving our services and offering you products and services that may interest you)'
      }
    },
    'description2': 'We generally do not rely on consent as a legal basis for processing your personal data, except for sending direct marketing communications to you via email, text message or other digital communication channels. We may also send you these direct marketing communications on a legitimate interest basis if we understand that you might be interested in the products and services that we may offer you. Should this occur, you have the right to withdraw your consent, or to object to EBANX\'s legitimate interest, in EBANX\'s own communications received by you by contacting us or via the EBANX communication preferences page.',
    'description3': 'In other cases, if you refuse to provide personal data that we need by law or regulation, or because of the terms of a contract we have with you, we may not be able to provide the Services properly.',
    'description4': 'We will only use your personal data for the purposes for which we collected it, unless we believe that we need to use it for another reason and that reason is compatible with the original purpose. Furthermore, if we need to process your personal data for a new purpose not originally related, we will notify you providing explanations on the matter.',
    'description5': 'We may process your personal data without human intervention to analyze transactions and your consumption profile, for behavioral analysis purposes and to prevent fraud and security threats. We may also do this to decide which marketing communications are right for you, analyze statistics and assess risk. All of this is done based on our legitimate interests: to protect our business and to develop and improve our Services.'
  },
  'section5': {
    'title': 'With whom can EBANX share personal data?',
    'description1': 'Because we offer a Service that involves payment operations and financial transactions, we can work together with other companies to provide them properly, which may involve sharing your personal data. They can be shared with companies such as: companies of the same economic group; payment processors; fraud prevention agents; tax identification number agencies; bulk email sender platform; providers of card payment arrangements. We only share your data with companies that guarantee compliance with market security standards.',
    'description2': 'Your personal data may be shared with state bodies and regulatory agencies to comply with legal and regulatory obligations to which we are subject in all territories where we provide our Services.',
    'description3': 'In addition, we may share your personal data to protect our rights, customers or third parties, to protect your legitimate interests or someone else\'s, to enforce our terms of use or other agreements and to bring or defend legal claims. .',
    'description4': 'We do not license, sell or transfer your personal data to anyone, under any circumstances, for profit purposes or contrary to this Policy.'
  },
  'section6': {
    'title': 'Can EBANX transfer personal data internationally?',
    'description1': 'EBANX is headquartered in Brazil and your personal data is collected in accordance with Brazilian law. However, we may transfer your personal data internationally to provide our Services. Your personal data may be transferred to the USA, in case the company responsible for hosting your information is located there.',
    'description2': 'Before transferring your personal data internationally, we guarantee that such transfer will take place in accordance with the degree of protection required in this Policy. To this end, your personal data will only be transferred to parties located in countries or international organizations that legally have an adequate degree of data protection or that comply with standard contractual clauses required by us.'
  },
  'section7': {
    'title': 'How long is personal data stored?',
    'description1': 'We keep your personal data only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of complying with any legal, contractual, accountability obligations or request from competent authorities, observing the minimum period of 10 (ten) years from the year following the end of our relationship with you, in compliance with applicable financial system regulations.',
    'description2': 'To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm arising from unauthorized use or disclosure of your personal data, the purpose of processing your data personal needs and whether we can achieve those purposes through other means. If certain data are no longer necessary to achieve a certain purpose, they will be deleted or subject to anonymization.',
    'description3': 'If you request the deletion of your personal data, we will delete them as soon as the legal maintenance period mentioned above has elapsed, unless their maintenance is determined based on legal obligations or by request of a competent authority.'
  },
  'section8': {
    'title': 'What are the personal data security standards adopted by EBANX?',
    'description1': 'We have put in place appropriate administrative and technical security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized manner, altered or disclosed. In addition, we authorize employees, agents, contractors and other third parties to access your personal data only to the extent necessary to carry out their activities. They will only process your personal data in accordance with our instructions and will be subject to a duty of confidentiality.',
    'description2': 'We have established procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of any breach if we are required to do so.',
    'description3': 'We are PCI-DSS certified and ISO/IEC 27001 certified, which means that our Services follow high standard security rules, using encryption, access control, advanced monitoring, security updates, breach tests and other procedures required as much by regulation and by our payment processing partners.',
    'description4': 'Although we adhere to strict security standards, the transmission of information over the internet is not completely secure, and although we do our best to protect your personal data, we cannot guarantee the security of your data transmitted online.'
  },
  'section9': {
    'title': 'What are your rights as a data subject?',
    'description1': 'We summarize below all your rights as a data subject that can be exercised by contacting us:',
    'orderedList1': 'Right of access. You can request and receive a copy of the personal data we hold about you. In addition, you can request clarification on how we collect your personal data, what criteria we use, the purpose of treatment and with whom we share your personal data.',
    'orderedList2': 'Right of rectification. You can request the rectification of personal data that are incomplete, inaccurate or out of date, by verifying the validity of the data you provide us.',
    'orderedList3': 'Right to anonymization, blocking or deletion. You can request the anonymization, blocking or deletion of personal data that you understand is being treated in a manner contrary to this Policy or in breach of applicable personal data protection legislation.',
    'orderedList4': 'Opposition right. You can object to the processing of your personal data that is not carried out on the basis of your consent, if you understand that such processing is violating your rights. In such cases, we can demonstrate that we have legitimate grounds to process your personal data in accordance with this Policy and to provide our Services appropriately.',
    'orderedList5': 'Right of deletion. You can request the deletion of your personal data stored by EBANX, treated with your consent, that are no longer necessary or relevant for the provision of the Services, provided that we have no other reason to keep them, such as to comply with a legal obligation. or regulatory data retention or to protect EBANX\'s rights.',
    'orderedList6': 'Right not to provide consent. You can refuse the processing of personal data carried out on the basis of your consent at any time. However, if you withdraw your consent, we may not be able to offer part of the Services properly – the consequences of which we will explain to you.',
    'orderedList7': 'Right of revision. You can request the review of decisions made solely on the basis of automated processing, if you understand that they are affecting your interests.',
    'orderedList8': 'Portability right. You have the right to request the portability of your personal data in a structured and interoperable format.',
    'description2': 'To submit your manifestations, you must submit an express request, on your behalf or through your legal representative, at the EBANX contact addresses described in the topic below. This application will cost you nothing. However, we may charge a fee if your request is clearly repetitive or excessive, or we may refuse to fulfill your request under these circumstances.',
    'description3': 'In order to exercise these rights, we may need to verify your identity and the validity of your personal data. This is a security measure to ensure that personal data is not disclosed to anyone who is not entitled to receive it. EBANX may also contact you for further information regarding your request.',
    'description4': 'Confirmations of the existence of data processing and access to your data will be provided immediately, in a simplified format, or, through a detailed statement, within a period of up to 15 days. For other responses to your requests, EBANX will try to respond within 30 days. Occasionally, we may take longer if your request is particularly complex or if you have made multiple requests. In this case, EBANX will notify you and keep you updated on the progress of your request.'
  },
  'section10': {
    'title': 'And the cookies?',
    'description1': 'Cookies are small files that can temporarily store information about your browsing. Cookies can be used for different purposes, such as storing information about your browsing preferences, collecting information to offer personalized content, or even to redirect the browser to another part of the website, when necessary.',
    'description2': 'Cookies set by us are called First-party cookies. But there are also third-party cookies, which are those that are set by other partners and allow third-party features or functionality to be provided or appear on our website (such as advertising, interactive content and analytics). Those who set these third-party cookies can recognize your computer both when you visit our website and when you visit their website.',
    'description3': 'We use different categories of cookies for specific purposes. Are they:',
    'definitionRow': {
      'title1': 'Essential Cookies:',
      'description1': 'These are essential and necessary cookies for EBANX to provide some of the services on our website to you, such as the registration page and mobile applications, and also for you to be able to use some features, such as accessing secure areas within the website.',
      'title2': 'Performance and Functionality Cookies:',
      'description2': 'Used to enhance and improve the functionality of our website, registration services and mobile applications. Without these cookies, some functionality may be impaired.',
      'title3': 'Advertising Cookies:',
      'description3': 'These cookies are used to make advertising messages more interesting for you. They prevent ads you\'ve already seen from appearing again, ensure ads are placed in places that don\'t get in the way of your reading, and in some cases, deliver ads based directly on your interests.',
      'title4': 'Social Media Cookies:',
      'description4': 'These cookies are used to allow you to share pages and content that you are interested in on our website on your social media. These cookies may also be used for advertising purposes.'
    },
    'description4': 'You have the right to accept or reject cookies. You can configure your browser to accept or refuse cookies. If you choose to decline them, you will still be able to access our website, but some functionality and areas of our website may be restricted or limited. As ways to restrict cookies may vary from browser to browser, you should visit your browser\'s "help" page for more information.',
    'description5': 'For more information on setting cookies: http://www.aboutcookies.org/',
    'description6': 'For " Google Analytics " cookies , you can download the module accessible from the following address to deactivate these cookies;',
    'for': 'for',
    'description7': 'We employ a technology called "clear gifs" (known as web beacons or web bugs), which helps us manage our site by identifying which content is most efficient. "Clear Gifs" are small graphics with a unique identifier, similar to those found in cookies, which are used to track users\' movements on the internet. Unlike cookies, which are stored on the hard drive of users\' computers, "clear gifs" are invisibly embedded in web pages or emails, and are as small as the period at the end of that sentence.',
    'description8': 'We use clear gifs or pixels to find out which users actually opened emails we sent in their inboxes. This helps us to be sure of the effectiveness of our communications and marketing actions. With this, we unite the information generated by clear gifs with the personal information of our customers.'
  },
  'section11': {
    'title': 'How to talk to EBANX about privacy?',
    'description1': 'If you want to exercise any of the rights provided for in this Policy or in the relevant legislation, or if you have questions, comments or suggestions regarding this Policy, you can contact us at the following contact addresses:',
    'description2': 'Email: chargededados@ebanx.com',
    'description3': 'Website: https://www.ebanx.com/br/ajuda'
  },
  'section12': {
    'title': 'What happens if this policy changes?',
    'description1': 'Any changes we make to this Policy will be posted on this page and, where appropriate, notified by email. Please check back to this page frequently for any updates or changes.'
  }
}
