/* eslint-disable no-mixed-operators */
import request from 'components/lib/request';
import { getNewApiBaseUrl, getNewAuthUrl } from 'components/common/helpers'
import config from 'components/config';
import pubsub from 'components/lib/pubsub';
import isBrowser from 'components/lib/isbrowser';
import Service from 'service';
import { KeycloakService } from '@banfico-core/keycloak-connector-react';

import {
  getUser,
  getSession,
  removeSession
} from 'components/lib/session';

const {
  apiAuth,
  authHost,
  authClientId,
  authResponseType,
  authScope,
  appHost
} = config;

export const authUrl = (domain) => KeycloakService.getAuthUri({
  clientId: authClientId,
  host: getNewAuthUrl(authHost),
  redirectUri: `${getNewApiBaseUrl(domain)}auth/callback`,
  responseType: authResponseType,
  scope: authScope,
  uiLocales: isBrowser ? (localStorage.getItem('lang')) : 'en'
})

export const register = (data) => Service.users.signUp(data);

export const updateUser = ({
  organization,
  contactPerson,
  contactNumber
}) =>
  request({
    uri: getNewApiBaseUrl(apiAuth.session),
    method: 'PUT',
    body: {
      organization,
      contactPerson,
      contactNumber
    }
  });

export const changePassword = ({ password, newPassword }) =>
  request({
    uri: getNewApiBaseUrl(apiAuth.password),
    method: 'PUT',
    body: { password, newPassword }
  })

export const activate = (code, entity) =>
  request({
    uri: getNewApiBaseUrl(apiAuth.activate),
    method: 'POST',
    headers: { 'ent-id': entity },
    body: { code }
  })

export const exchange = (code) =>
  request({
    uri: getNewApiBaseUrl(apiAuth.exchange),
    method: 'POST',
    body: { code }
  })


export const loggedUser = () => {
  if (!isBrowser) {
    return Promise.reject();
  }

  if (!getSession()) {
    return Promise.reject();
  }

  return request({
    uri: getNewApiBaseUrl(apiAuth.session)
  }).catch(({ status, message }) => {
    if (status === 401 && message.match(/wrong/)) {
      removeSession()
      pubsub.publish('user:unauthorised')
    }

    throw new Error(message)
  })
    .catch(({
      status,
      message
    }) => {
      if ((status === 401 && message.match(/wrong/) || (!status && message === 'something went wrong'))
      ) {
        removeSession();
        pubsub.publish('user:unauthorised');
      }

      throw new Error(message);
    });
};

export const getCurrentUser = () =>
  isBrowser && getUser();


export const logout = (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }

  const session = getSession();

  if (session) {
    const { idToken, refreshToken } = session;

    request({
      uri: getNewApiBaseUrl(apiAuth.logout),
      method: 'POST',
      body: { idToken, refreshToken }
    })
  }

  setTimeout(() => {
    removeSession();
    window.location.href = '/';
  }, 800);
}
