export default {
  'tabTitle': 'Terms and Conditions',
  'mainHeading': 'Terms and conditions of use',
  'subTitle': 'By using OBAF Portal you (the “User”) approve of the following terms and conditions (the “Agreement”):',
  'lastModified': 'Last modified: July 18, 2023',
  'section1': {
    'title': 'Introduction',
    'description1': 'This document defines the general conditions applicable to the services provided by EBANX as “eFX” – provider of international payment and transfer services (“EBANX Services”). Please read it carefully before finalizing your purchase.',
    'description2': 'The User declares that he had prior and timely access to this Term and that he agrees with all the rules established herein and applicable to the EBANX Services.',
    'description3': 'Whenever the terms “EBANX”, “we” or “our” are mentioned, we are referring to EBANX; likewise, whenever the terms “User”, “your”, “your” are mentioned, we are referring to the User of EBANX Services.',
    'description4': 'Prior to contracting the EBANX Services, the User confirms that he has had access to these General Terms and Conditions (“Term”), in advance and in a timely manner, taking note of the provisions contained herein and agreeing with its entire content.',
    'description5': 'For the purposes of interpretation, in this document “EBANX” is understood to be the provider of the international payment and transfer services mentioned herein, through the companies of the EBANX economic group that were duly constituted and authorized, when applicable, under the terms of current legislation applicable for the provision of services contracted by the User.',
    'description6': 'This Term regulates and applies to the services offered by EBANX, a company headquartered and governed by the laws of Singapore, the United Kingdom or another payment service provider duly authorized to provide EBANX Services in Brazil, subject to Brazilian legislation, to the User, of so that it is possible to contract the EBANX Services, described below.',
    'description7': 'By accepting this Term, the User is aware that EBANX is a company that provides international payment and transfer services (conceptually called "eFX" under the terms of the applicable legislation in force), through an exchange operation carried out by an institution authorized to act in the foreign exchange market, as provided for in BCB Circular 3691/2013 and amendments and Resolution No. 137/2021 of the Central Bank of Brazil, which provides digital payment solutions in reais.',
    'description8': 'For the collection, exchange operation and remittance abroad of the User\'s resources in the various payment methods accepted in Brazil, EBANX PTE LTD contracts EBANX Ltda., which is duly regulated by the Central Bank to provide exchange operation services and international transfer under the terms of the applicable legislation in force. EBANX conducts its operations only through financial intuitions, payment institutions and other institutions authorized by the Central Bank of Brazil for EBANX Services to be provided to the User.',
    'description9': 'In order for EBANX to be able to offer exchange services, the User hereby appoints EBANX as his attorney-in-fact with special powers to, in his name and on his behalf, carry out exchange operations for the remittance of funds, with EBANX being able to substitute in all or in part the powers granted here. By confirming the acceptance of these Terms, the User authorizes EBANX to act under the terms of this paragraph on his behalf to fulfill the purposes of the contracted services.',
    'description10': 'The User hereby authorizes EBANX to collect, process and share their registration, financial and personal data with companies of the same economic group, financial institutions, belonging to the EBANX group or not, to provide the services contracted herein.'
  },
  'section2': {
    'title': 'Service Subscription',
    'orderedList1': 'The service object of this agreement is available on the websites of partners who are actively contracting EBANX services , and who, therefore, expressly make the EBANX service available as a payment method.',
    'orderedList2': 'Adhesion to this User Term takes place automatically at the time of payment in reais through the payment methods made available by EBANX.',
    'orderedList3': 'Once registered on the website and duly registered and approved by EBANX, the User will be able to choose the local means of payment made available by EBANX.',
    'orderedList4': 'When making a purchase, the User holds full responsibility for the correct indication of the information and values ​​chosen that will be processed by the means of payment offered by EBANX, exempting EBANX from any responsibilities in case of data typing error and/or regret in choosing the chosen payment method.',
    'orderedList5': 'The User declares to be aware and agrees that he can only inform truthful data that are consistent with the reality of the facts, exempting EBANX fully and unlimitedly from any alleged false declaration, which is why EBANX cannot, under any circumstances, be questioned for the veracity of such information, with the User remaining responsible for any civil and criminal sanctions that may be inflicted.',
    'orderedList6': 'Prior to making the purchase, the User declares that he was aware of the product information, as well as the commercial conditions offered at the time of purchase, exempting EBANX from any responsibility in relation to the transaction carried out.',
    'orderedList7': 'Under the terms of the applicable legislation in force, a statement of the operation will be made available to the User, the content of which will detail the operation, identify its value, the date of the operation, the name of the parties involved, any fees charged due to the operation, in addition to of relative subtotals, if any.',
    'orderedList8': 'The User declares to be aware that the EBANX Services may include recurring payment services or subscription payments, in which the User will be charged continuously within the contracted period and available on the websites of partners that expressly make the EBANX service available as a modality of payment. If the User uses the services of recurring payments or subscription payments provided by EBANX, it will not be necessary for the User to accept these Terms for each of the charges, and the User\'s acceptance at the time of the first recurring charge or subscription payments Valid for the entire contracted period'
  },
  'section3': {
    'title': 'Exchange Operation and EBANX Service',
    'orderedList1': 'The User declares that he/she is aware that the total amount of the purchase will be denominated in reais, after its conversion from the United States dollar based on the conversion rate from dollars to reais for the date of the respective payment, being subject to the exchange rate variation of the conversion, up or down. The value of operations carried out on international shopping websites in a currency other than the US dollar will first be converted into US dollars, according to the systems and criteria used by EBANX and, subsequently, will be converted into reais at the conversion rate on the date of the authorization of the operation, and the execution of the exchange operation may take place at a later date.',
    'orderedList2': 'The User is fully aware that the conversion rates practiced in accordance with these Terms may change according to the Brazilian exchange market.',
    'orderedList3': 'EBANX will only send the funds to the international website after receiving your payment confirmation, which must contain the payment method chosen by the User and offered by EBANX upon financial feasibility of the exchange operation.',
    'orderedList4': 'EBANX informs that it has no relationship with the products and/or services offered on the websites that contract the EBANX platform to carry out exchange operations. The role of EBANX is solely to serve as a means of payment in order to guarantee that the funds collected in Reais are effectively delivered to the website of the User\'s choice.',
    'orderedList5': 'The exchange operation carried out by EBANX is subject to limits and conditions imposed by the regulations, rules and internal rules applicable to the operation.',
    'orderedList6': 'When paying in national currency for the exchange operation carried out by EBANX, through the means of payment accepted by EBANX, the User accepts this Term and automatically agrees with the exchange rates contracted by EBANX with the financial institution, payment institution and other authorized by BACEN.'
  },
  'section4': {
    'title': 'User Registration and Information on EBANX',
    'orderedList1': 'In order to use the EBANX service and products, the User\'s personal identification information will be required. Thus, when completing our registration, the User declares that the information that identifies him/her is true, accurate and complete. It also declares that the number of its Individual Taxpayer Registration (CPF) or Corporate Taxpayer Registration (CNPJ) before the Ministry of Finance is valid and able to carry out exchange operations regulated by the Central Bank of Brazil. Any falsehoods may subject you to criminal and civil liability, in accordance with Brazilian law.',
    'orderedList2': 'User information will be shared between EBANX and partner companies, to the extent necessary for them to perform the services for which they were contracted, observing the clauses referring to the secrecy of information. User information, as well as information relating to payments made, may be provided to the Central Bank of Brazil, in the manner and under the conditions established by the Regulator. ',
    'orderedList3': 'To ensure proper compliance with the law, EBANX will use the User\'s personal information in order to identify and prevent possible attempts at practices considered to be criminal, as well as to prevent any fraud that may be identified. Any other sharing of User data will only be done within the limits provided by law and other regulations applied to the service offered.',
    'orderedList4': 'Information Sharing:',
    'unorderedList1': 'The User authorizes EBANX to keep the information collected during the provision of the services for the purpose of adapting to the applicable legislation in force and improving the services.',
    'unorderedList2': 'EBANX will use User data to assess financial risks, verify the possibility of the crime of money laundering, prevent fraud and report consistency of information in accordance with the provisions of law, and may, if applicable, block your registration or even delete it, preventively or definitively. In these cases, we reserve the right to refuse any transfer of values ​​made by the User when we understand that such transfers are not in accordance with our operating standards and risk policy. Our analysis of registration data is individualized and can be done at any time.',
    'orderedList5': 'If the transactions informed hereby are carried out by a minor under 16 years of age, the minor informs that he/she has the express authorization of the legal guardian so that he/she can make purchases in his/her name and on his/her account in commercial establishments, provided that they are subject to applicable legal restrictions and age limits for purchasing certain products. The legal guardian assumes full responsibility for paying for the products and services purchased by the minor, as well as for any damages that may be caused to third parties as a result of making purchases.'
  },
  'section5': {
    'title': 'Taxes',
    'orderedList1': 'Considering that the service provided by the EBANX Financial Partner comprises an exchange operation, the IOF-Exchange (Tax on Exchange Financial Operations) will be levied when there is an exchange transaction, and must always be paid by the User. The IOF-Exchange will be added to the transaction value and transferred to the tax authorities by the Financial Partner EBANX. Any other taxes that may be levied on the operation, as well as the ancillary obligations and instrumental duties required by the bodies that are part of the direct and indirect Public Administration are the sole responsibility of the User, with the possibility of holding the Financial Partner EBANX liable for the taxes that the User , whether by ignorance or any other reason,'
  },
  'section6': {
    'title': 'Refund Payment',
    'orderedList1': 'In the case of requesting a refund to the User, through the website, the amounts referring to taxes on financial operations (IOF) will be withheld for each transaction, at the time of purchase, and therefore will not be returned to the User, pursuant to current legislation. applicable.',
    'orderedList2': 'The requested refund will be made available to EBANX in foreign currency for payment order in Brazil. Operationally, the conversion rate from foreign currency to reais will be the date of effecting the refund and the credit related to the refund will be made in an account previously informed by the User.',
    'orderedList3': 'The User must request a refund directly from the website where the purchase was made, within the due legal period in accordance with the applicable legislation in force. When legal requirements are met, EBANX will be responsible for executing the refund transaction. The following are hypotheses provided for by law that allow the possibility of reimbursement:',
    'unorderedList1': 'Within 7 (seven) days after the purchase or delivery of the product due to the exercise of the right of withdrawal;',
    'unorderedList2': 'Within 30 (thirty) days after receipt of the product or provision of the service due to any defect in the product or service.',
    'unorderedList3': 'Once the described deadlines have expired, any reimbursement payment made by EBANX will be carried out by a mere discretionary act. Failure to exercise within the aforementioned deadlines will imply that the User recognizes and accepts the accuracy of the purchase made.'
  },
  'section7': {
    'title': 'Third Party Rights',
    'orderedList1': 'This Term does not grant any third party benefits or rights to demand compliance with any items of this Term.'
  },
  'section8': {
    'title': 'Transfer of Rights',
    'orderedList1': 'EBANX and the partner companies contracted by it may assign any of its rights and obligations provided for in this Term to any person, individual or legal entity, regardless of notice or notification to the User, with the proviso that the assignee will continue to comply with all the obligations assumed by EBANX and its partners in this Term.'
  },
  'section9': {
    'title': 'User Service',
    'orderedList1': 'In the event that the User contests a transaction with a website where he has purchased a product and/or service, EBANX will not interfere in the dispute except to confirm that the payment was made in accordance with its instructions.',
    'orderedList2': 'General service to the User will be carried out through the channels available in our Help Center (https://ajuda.ebanx.com).',
    'orderedList3': 'Also, according to the applicable legislation, EBANX must, by 10:00 am Brasília time, make available in all its User service channels the conversion rate from the United States dollar to reais used on the previous day applied in the conversion of transaction values in foreign currency of its Users and publish, in the form and conditions established by the Central Bank of Brazil, including in open data format, information on the history of the conversion rates applied in the conversion of the values ​​of operations in foreign currency of its Users.',
    'orderedList4': 'EBANX may, at its sole discretion, send communications or notifications to the User via e-mail, telephone or mail, according to the data collected in the provision of the service. EBANX uses personal data to be able to provide a high quality service and offer the best services to the User.'
  },
  'section10': {
    'title': 'Duties and Obligations of the parties',
    'orderedList1': 'Websites that sell products and/or services provide products and services to different countries, have different commercial, tax and intellectual property regulations. It is the User\'s sole responsibility to analyze the legal and financial implications regarding the importation of goods and services available on international websites.',
    'orderedList2': 'The User must specifically pay attention and refrain from purchasing products and/or services that (i) violate the applicable law, statute, ordinance or regulation; (ii) are related to the sale of prohibited or controlled substances; (iii) items that promote intolerance of minorities of any nature; (iv) items that infringe or violate any copyright, trademark, right of publicity or privacy; ammunition, firearms or firearm parts; products with a high probability of being fraudulent (price incompatible with the market average or with an irregular specification), among other cases provided for by law that must be avoided.',
    'orderedList3': 'The User must only use the EBANX system for lawful purposes, being expressly forbidden to use it to receive or transmit material that is obscene, offensive, defamatory, in violation of secrecy or in violation of any intellectual property rights.',
    'orderedList4': 'The purchase of any of the items listed above is the sole responsibility of the User. Any retention of such products by regulatory agencies or other bodies that are part of the Direct, Indirect or Municipal Public Administration is not and cannot be considered EBANX\'s responsibility under any circumstances.',
    'orderedList5': 'EBANX will not be responsible, under any circumstances, for any losses, damages, defects and/or failures arising from the transaction between the User and the international website receiving the shipment, including, but not limited to, those caused by the following scenarios:',
    'unorderedList1': 'Payments made to unwanted destinations or payments in incorrect amounts due to the User entering incorrect information;',
    'unorderedList2': 'Errors or omissions in the content of the international website receiving the shipment;',
    'unorderedList3': 'Misuse of the content of the international website receiving the shipment;',
    'unorderedList4': 'Impossibility of any person to access the international website recipient of the shipment;',
    'unorderedList5': 'Delays, losses, errors or omissions resulting from the failure of telecommunication or any other data transmission system and the failure of the central computer system or any part thereof of the international website receiving the shipment;',
    'unorderedList6': 'Any results arising from acts of government or authority, or any acts of force majeure;',
    'unorderedList7': 'Addictions and failures of products and services;',
    'unorderedList8': 'Damage occurred during shipping.',
    'orderedList6': 'The User is responsible for informing the international website, recipient of the shipment, about the possibilities and ways of returning the goods or canceling the service in case of addiction or failure.'
  },
  'section11': {
    'title': 'General Terms',
    'orderedList1': 'EBANX reserves the right to suspend the system for repair, security, maintenance and/or improvements, being responsible for and guaranteeing all ongoing operations.',
    'orderedList2': 'Whenever necessary, EBANX may restrict the services and/or add additional security measures for subscribing to the services.',
    'orderedList3': 'The User may terminate this Term at any time through one of our communication channels.'
  },
  'section12': {
    'title': 'Privacy Policy',
    'orderedList1': 'EBANX processes your data in accordance with the EBANX Privacy Policy, which can be consulted at any time by the User at the electronic address: https://business.ebanx.com/pt-br/legal/consumidores/politica-of-privacy/'
  },
  'section13': {
    'title': 'Intellectual Property',
    'orderedList1': 'The User acknowledges that EBANX owns all intellectual property used to provide the services contracted herein, in any material created or made available by EBANX. Such intellectual property encompasses the following: (a) trademarks, corporate names, service names, slogans, trade dress, logos, internet domain name and other distinctive signs, as well as all related applications, registrations, extensions and renewals; (b) patents, patent applications and all related renewals, utility models, utility model applications, certificates of addition, applications for certificates of addition, extensions and related renewals and registrations of inventions; (c) industrial design registrations and applications for industrial design registrations, related extensions and renewals; (d) copyright, computer programs, layouts, presentation forms, color combinations, source codes and related registrations and applications; and (e) trade secrets and know-how.',
    'orderedList2': 'The User undertakes not to violate, reproduce, imitate, in whole or in part, any intellectual property of EBANX, as well as not to use any intellectual property of EBANX for any purposes other than those provided for in this Agreement and permitted by law.'
  },
  'section14': {
    'title': 'Act Of God and Force Majeure',
    'orderedList1': 'EBANX and/or partner companies will not be considered in arrears or in default of any of its obligations under this Term if the reason for non-compliance is due to acts of God or force majeure.'
  },
  'section15': {
    'title': 'Amendment of Agreement',
    'orderedList1': 'EBANX may change any conditions of this Term to adapt the Contract to relevant legislative or economic changes. If the User does not agree with the changes made by EBANX, he may immediately request the termination of this Term, being aware that the termination of the Term will result in the impossibility of making new purchases using the EBANX services.'
  },
  'section16': {
    'title': 'Jurisdiction Choice',
    'orderedList1': 'The terms and conditions of this Term will be interpreted in accordance with Brazilian legislation and will be subject to the exclusive jurisdiction of its courts, which is the elected and competent forum, however privileged another may be in relation to this contract.',
    'orderedList2': 'To resolve any conflicts related to this Agreement, for cancellation requests, complaints and suggestions, the User may contact the service channel available at https://ajuda.ebanx.com/hc/pt-br .',
    'description1': 'This Agreement is fully available for consultation on the EBANX website at the electronic address: https://www.ebanx.com/br/termos/ .',
    'description2': 'I declare that I have read, understood and agreed with the provisions set forth in this Agreement.'
  }
}
