export default {
  environment: {
    environment: 'Ambiente'
  }, 
  faq: {
    title: 'Perguntas Frequentes',
    questionsHere: 'Dúvidas? Confira aqui',
    data: {
      q1: 'O que o portal do desenvolvedor cobre em termos de APIs?',
      a1: 'Este portal do desenvolvedor é publicado de acordo com o mandato do PSD2 e as APIs são baseadas na especificação do Open Banking do Reino Unido. Abrange as funcionalidades AISP e PISP.',
      q2: 'Existe uma API separada para clientes bancários corporativos e de varejo?',
      a2: 'Não. São as mesmas APIs para clientes corporativos e de varejo. A maneira como os clientes fazem login e autorizam pode ser diferente, mas as próprias APIs são baseadas na especificação do Open Banking do Reino Unido.',
      q3: 'Como é obtido o consentimento para o fluxo AISP?',
      a3: 'Nossa especificação de API é compatível apenas com *Consentimento oferecido pelo banco*, o que significa que o AISP não especifica explicitamente a lista de contas de clientes durante a Solicitação de consentimento. Mas durante a captura de consentimento, o banco exibirá uma lista de todas as contas bancárias disponíveis que o cliente pode optar por compartilhar as informações da conta.',
      q4: 'O Desenvolvedor/TPP precisa ser autorizado no registro da NCA?',
      a4: 'Esperamos que os desenvolvedores sejam licenciados. No entanto, isso é flexível em sistemas de teste ou sandbox. Usamos os serviços de diretório do Open Banking Europeu para nos conectarmos a 31 NCAs.',
      q5: 'Recebo contas de clientes do banco de teste para realizar fluxos AISP/PISP?',
      a5: 'Sim. Na verdade, você pode, no entanto, registrar-se como cliente bancário em nosso aplicativo bancário de referência. Esses clientes podem criar sua própria lista de contas, saldos e transações.',
      q6: 'Como o consentimento pode ser revogado?',
      a6: 'Os clientes do banco podem fazer login no aplicativo do banco de referência e gerenciar seu consentimento. `Revogue` as concessões do AISP.',
      q7: 'Há alguma outra especificação de API suportada?',
      a7: 'Não. No momento, oferecemos suporte apenas à especificação da API do Open Banking do Reino Unido.',
      q8: 'Os desenvolvedores podem criar mais aplicativos e inscrever-se no mesma API de produtos AISP/PISP?',
      a8: 'Sim. Em modo de produção, os desenvolvedores só podem criar APP que tenha licença NCA associada a ele. No entanto, no modo de teste, que está implantado atualmente, os desenvolvedores podem criar qualquer número de aplicativos AISP ou PISP.'
    }
  },
  sidebar: {
    connectedFintechs: 'Fintechs Conectadas'
  },
  htmlView: {
    onThisPage: 'Nesta página'
  },
  product: {
    product: 'Produto'
  },
  role: {
    role: 'Permissões do Usuário'
  },
  swagger: {
    application: 'aplicação',
    authorized: 'Autorizado',
    authorizationUrl: 'URL de Autorização',
    tokenUrl: 'URL do Token',
    flow: 'Fluxo',
    username: 'Usuário',
    password: 'Senha',
    type: 'Tipo',
    requestBody: 'Corpo da Requisição',
    basicAuth: 'Autenticação Básica',
    queryParameters: 'Parâmetros de consulta',
    clientId: 'Client Id',
    clientSecret: 'Client Secret',
    scopes: 'Escopos',
    authorize: 'Autorizar'
  }
}