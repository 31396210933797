export default {
  'tabTitle': 'Termos e Condições',
  'mainHeading': 'Termos e Condições de Uso',
  'subTitle': 'Ao usar o OBAF Portal, você (o “Usuário”) aprova os seguintes termos e condições (o “Contrato”):',
  'lastModified': 'Última modificação: 18 de julho de 2023',
  'section1': {
    'title': 'Introdução',
    'description1': 'Este documento define as condições gerais aplicáveis aos serviços prestados pelo EBANX na qualidade de “eFX” – prestador de serviços de pagamento e transferência internacional (“Serviços EBANX”). Por favor, leia-o atentamente antes de finalizar a sua compra.',
    'description2': 'O Usuário declara que teve acesso prévio e tempestivo a este Termo e que concorda com todas as regras aqui estabelecidas e aplicáveis aos Serviços EBANX.',
    'description3': 'Toda vez que houver menção aos termos “EBANX”, “nós” ou “nossos”, estamos nos referindo ao EBANX; da mesma forma, toda vez que houver menção aos termos “Usuário”, “seu”, “sua”, estamos nos referindo ao Usuário dos Serviços EBANX.',
    'description4': 'Anteriormente à contratação dos Serviços EBANX, o Usuário confirma que teve acesso ao presente Termos e Condições Gerais (“Termo”), de maneira prévia e tempestiva, tomando ciência das disposições aqui contidas e está de acordo com seu inteiro teor.',
    'description5': 'Para fins de interpretação, neste documento entende-se “EBANX” como sendo o prestador dos serviços de pagamento e transferência internacional aqui mencionados, através das empresas do grupo econômico do EBANX que foram devidamente constituídas e autorizadas, quando cabível, nos termos da legislação vigente aplicável para a prestação dos serviços contratados pelo Usuário.',
    'description6': 'Este Termo regula e se aplica aos serviços oferecidos pelo EBANX, empresa sediada e regida pelas leis de Cingapura, do Reino Unido ou outro prestador de serviços de pagamentos devidamente autorizado a prestar os Serviços EBANX no Brasil, sujeitos à legislação brasileira, ao Usuário, de modo que seja possível a contratação dos Serviços EBANX, a seguir descritos.',
    'description7': 'Ao aceitar o presente Termo, o Usuário fica ciente que o EBANX é uma empresa que presta serviços de pagamento e transferência internacional (conceitualmente denominada “eFX” nos termos da legislação vigente aplicável), por meio de operação de câmbio realizada  por instituição autorizada para atuar no mercado de câmbio, na forma prevista segundo a Circular BCB 3691/2013 e alterações e a  Resolução nº 137/2021 do Banco Central do Brasil, e que provê soluções de pagamentos em reais, por meio digital.',
    'description8': 'Para a coleta, operação de câmbio e remessa ao exterior dos recursos do Usuário nas diversas modalidades de pagamento aceitas no Brasil, o EBANX PTE LTD contrata o EBANX Ltda., que é devidamente regulado pelo Banco Central para prestação dos serviços de operação de câmbio e transferência internacional nos termos da legislação vigente aplicável. O EBANX cursa as suas operações somente por meio de intuições financeiras, instituições de pagamento e demais instituições autorizadas pelo Banco Central do Brasil para que os Serviços EBANX sejam prestados ao Usuário.',
    'description9': 'Para que o EBANX possa ofertar os serviços de câmbio, o Usuário desde já nomeia o EBANX seu bastante procurador com poderes especiais para, em seu nome e por sua conta, realizar operação de câmbio para remessa de fundos, podendo o EBANX substabelecer em todo ou em parte os poderes aqui outorgados. Ao confirmar o aceite destes Termos, o Usuário autoriza o EBANX a agir nos termos deste parágrafo em seu nome para cumprimento dos fins dos serviços contratados.',
    'description10': 'O Usuário desde já autoriza o EBANX a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as empresas do mesmo grupo econômicos, instituições financeiras, pertencentes ou não ao grupo do EBANX, para prestação dos serviços ora contratados.'
  },
  'section2': {
    'title': 'ADESÃO AO SERVIÇO',
    'orderedList1': 'O serviço objeto do presente contrato está disponível nos websites de parceiros que mantêm contratação ativa dos serviços do EBANX, e que, portanto, expressamente disponibilizam o serviço do EBANX como modalidade de pagamento.',
    'orderedList2': 'A adesão ao presente Termo de Usuário se dá automaticamente no momento do pagamento em reais através dos meios de pagamento disponibilizados pelo EBANX.',
    'orderedList3': 'Uma vez registrado no website e devidamente cadastrado e aprovado pelo EBANX, o Usuário poderá escolher os meios de pagamentos locais disponibilizados pelo EBANX.',
    'orderedList4': 'Ao realizar uma compra, o Usuário detém inteira responsabilidade pela correta indicação das informações e valores escolhidos que serão processados pelos meios de pagamento oferecidos pelo EBANX, eximindo o EBANX de quaisquer responsabilidades em caso de erro de digitação de dados e/ou arrependimento na escolha do método de pagamento escolhido.',
    'orderedList5': 'O Usuário declara estar ciente e concorda que somente poderá informar dados verídicos e que condizem com a realidade dos fatos, isentando o EBANX integral e ilimitadamente de qualquer declaração de falsidade eventualmente alegada, motivo pelo qual o EBANX não poderá, em qualquer hipótese, ser interpelado pela veracidade de tais informações, permanecendo ao Usuário a responsabilidade por eventuais sanções cíveis e criminais porventura infligidas.',
    'orderedList6': 'Anteriormente a realização da compra, o Usuário declara que teve conhecimento das informações do produto, bem como das condições comerciais oferecidas no momento da compra, isentando o EBANX de qualquer responsabilidade com relação ao negócio efetuado.',
    'orderedList7': 'Nos termos da legislação vigente aplicável, será disponibilizado ao Usuário um demonstrativo da operação, cujo conteúdo trará a discriminação da operação, a identificação do seu valor, a data da operação, o nome das partes envolvidas, eventuais tarifas cobradas em razão da operação, além de subtotais relativos, quando houver.',
    'orderedList8': 'O Usuário declara estar ciente de que os Serviços EBANX poderão incluir os serviços de pagamentos recorrentes ou de pagamentos de assinaturas, no qual o Usuário será cobrado continuamente dentro da periodicidade contratada e disponível nos websites de parceiros que expressamente disponibilizam o serviço do EBANX como modalidade de pagamento. Caso o Usuário utilize os serviços de pagamentos recorrentes ou de pagamentos de assinaturas fornecidos pelo EBANX, não será necessário o aceite do Usuário nestes Termos para cada uma das cobranças, sendo que o aceite do Usuário no momento da primeira cobrança recorrente ou de pagamentos de assinaturas válida para todo o período contratado.'
  },
  'section3': {
    'title': 'OPERAÇÃO DE CÂMBIO E SERVIÇO DO EBANX',
    'orderedList1': 'O Usuário declara conhecimento de que o valor total da compra será denominado em reais, após a sua conversão do dólar dos Estados Unidos com base na taxa de conversão de dólares para reais para a data do respectivo pagamento, estando sujeito à variação cambial da taxa de conversão, para maior ou para menor. O valor das operações realizadas em websites de compras internacionais em moeda distinta do dólar dos Estados Unidos será primeiramente convertido para dólares dos Estados Unidos, conforme os sistemas e critérios utilizados pelo EBANX e, posteriormente, será convertido em reais com a taxa de conversão da data da autorização da operação, sendo que a efetivação da operação de câmbio poderá acontecer em data posterior.',
    'orderedList2': 'O Usuário tem plena ciência de que as taxas de conversão praticadas de acordo com estes Termos poderão sofrer variações de acordo com o mercado de câmbio brasileiro.',
    'orderedList3': 'O EBANX somente enviará os fundos ao website internacional após receber sua confirmação do pagamento que deverá conter o método de pagamento escolhido pelo Usuário e ofertado pelo EBANX mediante viabilização financeira da operação de câmbio.',
    'orderedList4': 'O EBANX informa que não detém qualquer relação com os produtos e /ou serviços ofertados nos websites que contratam a plataforma do EBANX para operacionalizar as operações de câmbio. O papel do EBANX é tão somente servir como um meio de pagamento de modo a garantir que os recursos em reais por ele coletados sejam efetivamente entregues ao website da escolha do Usuário.',
    'orderedList5': 'A operação de câmbio realizada pelo EBANX está sujeita a limites e condições impostas pela regulamentação, normas e regras internas aplicáveis à operação.',
    'orderedList6': 'Ao pagar em moeda nacional pela operação de câmbio realizada pelo EBANX, através dos meios de pagamento aceitos pelo EBANX, o Usuário aceita esse Termo e, automaticamente, concorda com as taxas de câmbio contratadas pelo EBANX junto à instituição financeira, instituição de pagamento e demais autorizadas pelo BACEN.'
  },
  'section4': {
    'title': 'CADASTRO E INFORMAÇÕES DO USUÁRIO NO EBANX',
    'orderedList1': 'Para utilizar o serviço e produtos do EBANX serão necessárias informações pessoais de identificação do Usuário. Assim, ao preencher nosso cadastro, o Usuário declara que as informações que o identificam são verdadeiras, precisas e completas. Declara também que o número do seu Cadastro de Pessoa Física (CPF) ou Cadastro Nacional de Pessoa Jurídica (CNPJ) perante o Ministério da Fazenda está válido e apto para realização de operação de câmbio regulada pelo Banco Central do Brasil. Eventuais falsidades poderão sujeitá-lo às responsabilidades criminais e cíveis, de acordo com a legislação brasileira.',
    'orderedList2': 'As informações do Usuário serão compartilhadas entre o EBANX e empresas parceiras, na medida em que forem necessárias para que estas executem os serviços para os quais foram contratadas, observadas as cláusulas referentes ao sigilo de informações. As informações do Usuário, bem como as relativas aos pagamentos realizados poderão ser prestadas ao Banco Central do Brasil, na forma e nas condições que vierem a ser estabelecidas pelo Regulador.',
    'orderedList3': 'Para certificarmo-nos do devido cumprimento da lei, o EBANX utilizará as informações pessoais do Usuário com o intuito de identificar e evitar possíveis tentativas de práticas consideradas como criminosas, assim como para prevenir qualquer fraude que venha a ser identificada. Qualquer outro compartilhamento de dados do Usuário só será feito dentro dos limites previstos em lei e demais regulações aplicadas ao serviço ofertado',
    'orderedList4': 'Compartilhamento das Informações:',
    'unorderedList1': 'O Usuário autoriza o EBANX a manter as informações coletadas durante a prestação dos serviços para fins de adequação às legislações vigentes aplicáveis e aprimoramento dos serviços.',
    'unorderedList2': 'O EBANX utilizará os dados do Usuário para avaliação dos riscos financeiros, verificação da possibilidade da ocorrência do crime de lavagem de dinheiro, prevenção de fraudes e relatórios de consistência das informações de acordo com o previsto em lei, podendo, se for o caso, bloquear seu cadastro ou até mesmo excluí-lo, preventiva ou definitivamente. Nesses casos, reservamo-nos o direito de recusar quaisquer transferências de valores realizadas pelo Usuário quando entendermos que tais transferências não estão de acordo com nossos padrões de operação e política de riscos. Nossa análise dos dados cadastrais é individualizada e poderá ser feita a qualquer momento.',
    'orderedList5': 'Caso as transações, por meio deste informadas, sejam realizadas por menor de 16 anos, o menor de idade informa que detém a autorização expressa do responsável legal para que possa realizar compras em seu nome e por sua conta em estabelecimentos comerciais, desde que sejam observadas as restrições legais aplicáveis e os limites de idade para aquisição de determinados produtos. O responsável legal assume a responsabilidade integral pelo pagamento dos produtos e serviços adquiridos pelo menor, bem como por quaisquer danos eventualmente causados a terceiros em decorrência da realização das compras.'
  },
  'section5': {
    'title': 'TRIBUTOS',
    'orderedList1': 'Considerando que o serviço prestado pelo Parceiro Financeiro EBANX compreende uma operação de câmbio, o IOF-Câmbio (Imposto sobre Operações Financeiras de Câmbio) incidirá quando houver alguma transação cambial, devendo sempre ser pago pelo Usuário. O IOF-Câmbio será adicionado ao valor da transação e repassado às autoridades fazendárias pelo Parceiro Financeiro EBANX. Os demais tributos eventualmente incidentes sobre a operação, bem como as obrigações acessórias e os deveres instrumentais exigidos pelos órgãos integrantes da Administração Pública direta e indireta são de exclusiva responsabilidade do Usuário, sendo vedada a possibilidade de responsabilização do Parceiro Financeiro EBANX pelos tributos que o Usuário, por desconhecimento ou qualquer outro motivo, seja obrigado a recolher a tais órgãos em decorrência da operação realizada com o website.'
  },
  'section6': {
    'title': 'PAGAMENTO DO REEMBOLSO',
    'orderedList1': 'No caso de solicitação de reembolso ao Usuário, por meio do website, os valores referentes aos impostos sobre operações financeiras (IOF) serão retidos a cada transação, no momento da compra, não sendo, portanto, devolvidos ao Usuário, por força da legislação vigente aplicável.',
    'orderedList2': 'O reembolso requisitado será disponibilizado ao EBANX em moeda estrangeira para ordem de pagamento no Brasil. Operacionalmente, a taxa de conversão da moeda estrangeira para reais será a data da efetivação do reembolso e o crédito referente ao reembolso será realizado em conta previamente informada pelo Usuário.',
    'orderedList3': 'O Usuário deverá fazer o pedido de reembolso diretamente ao website em que efetuou a compra, dentro do devido prazo legal nos termos da legislação vigente aplicável. Quando cumpridos os requisitos legais, o EBANX será responsável por executar a transação de reembolso. São hipóteses previstas em lei que permitem a possibilidade de reembolso:',
    'unorderedList1': 'Em até 7 (sete) dias após a compra ou entrega do produto em razão do exercício do direito de arrependimento;',
    'unorderedList2': 'Em até 30 (trinta) dias após o recebimento do produto ou da prestação do serviço em razão de eventual vício do produto ou serviço.',
    'unorderedList3': 'Expirado os prazos descritos, qualquer pagamento de reembolso realizado pelo EBANX será realizado por mero ato discricionário. O não exercício dentro dos prazos mencionados implicará que o Usuário reconhece e aceita a exatidão da compra realizada.'
  },
  'section7': {
    'title': 'DIREITOS DE TERCEIROS',
    'orderedList1': 'Este Termo não confere a quaisquer terceiros benefícios ou direitos de exigir o cumprimento de quaisquer itens deste Termo.'
  },
  'section8': {
    'title': 'TRANSFERÊNCIA DE DIREITOS',
    'orderedList1': 'O EBANX e as empresas parceiras por ele contratadas poderão ceder quaisquer de seus direitos e obrigações previstos neste Termo a qualquer pessoa, física ou jurídica, independentemente de aviso ou notificação ao Usuário, ficando desde já ressalvado que o cessionário continuará a cumprir com todas as obrigações assumidas pelo EBANX e seus parceiros neste Termo.'
  },
  'section9': {
    'title': 'ATENDIMENTO AO USUÁRIO',
    'orderedList1': 'Na hipótese de o Usuário contestar uma transação com algum website em que tenha adquirido produto e/ou serviço, o EBANX não interferirá na disputa a não ser para confirmar que o pagamento foi feito em conformidade com as suas instruções.',
    'orderedList2': 'O atendimento geral ao Usuário será realizado através dos canais disponíveis em nossa Central de Ajuda (https://ajuda.ebanx.com).',
    'orderedList3': 'Ainda, conforme a legislação aplicável o EBANX deverá até as 10h do horário de Brasília, tornar disponível em todos os seus canais de atendimento ao Usuário a taxa de conversão do dólar dos Estados Unidos para reais utilizada no dia anterior aplicada na conversão dos valores das operações em moeda estrangeira de seus Usuário e publicar, na forma e condições estabelecidas pelo Banco Central do Brasil, inclusive no formato de dados abertos, informações sobre o histórico das taxas de conversão aplicadas na conversão dos valores das operações em moeda estrangeira de seus Usuário.',
    'orderedList4': 'O EBANX poderá, a seu exclusivo critério, enviar comunicações ou notificações ao Usuário via e-mail, telefone ou correio, de acordo com os dados coletados na prestação de serviço.  O EBANX utiliza os dados pessoais para poder prestar um serviço de alta qualidade e oferecer os melhores serviços ao Usuário.'
  },
  'section10': {
    'title': 'DEVERES E OBRIGAÇÕES DAS PARTES',
    'orderedList1': 'Os websites que comercializam produtos e/ou serviços fornecem produtos e serviços para diversos países,  apresentam regulamentações comercial, tributária e de propriedade intelectual diferentes entre si. É de exclusiva responsabilidade do Usuário analisar as implicações legais e financeiras no que concerne a importação de bens e serviços dispostos nos websites internacionais.',
    'orderedList2': 'Deverá o Usuário atentar especificamente e abster-se de adquirir produtos e/ou serviços que (i) violem lei, estatuto, portaria ou regulamentação vigente aplicável; (ii) estejam relacionados à venda de substâncias proibidas ou controladas; (iii) itens que promovam intolerância a minorias de qualquer natureza; (iv) itens que infrinjam ou violem qualquer direito autoral, marca comercial, direito de publicidade ou privacidade; munição, armas de fogo ou peças de armas de fogo; produtos com grande probabilidade de serem fraudulentos (preço incompatível com média de mercado ou com especificação irregular), entre outros casos previstos em lei que devam ser evitados.',
    'orderedList3': 'O Usuário deverá apenas utilizar o sistema EBANX para fins lícitos, sendo expressamente proibido o uso para receber ou transmitir material que seja obsceno, ofensivo, difamatório, em violação de sigilo ou em violação de quaisquer direitos de propriedade intelectual.',
    'orderedList4': 'A compra de qualquer um dos itens listados acima é de responsabilidade integral do Usuário. A eventual retenção de tais produtos por agências reguladoras ou outros órgãos integrantes da Administração Pública Direta, Indireta ou Autárquica, não é e não poderá ser considerada em nenhuma hipótese de responsabilidade do EBANX.',
    'orderedList5': 'O EBANX não se responsabilizará, sob nenhuma circunstância, por quaisquer perdas, danos, vícios e/ou falhas, decorrentes da transação havida entre Usuário e o website internacional destinatário da remessa, incluindo, mas não se limitando, aos causados pelos seguintes cenários:',
    'unorderedList1': 'Pagamentos feitos a destinos indesejados ou pagamentos em valores incorretos devido à digitação de informações incorretas por parte do Usuário;',
    'unorderedList2': 'Erros ou omissões no conteúdo do website internacional destinatário da remessa;',
    'unorderedList3': 'Mau uso do conteúdo do website internacional destinatário da remessa;',
    'unorderedList4': 'Impossibilidade de qualquer pessoa a acessar o website internacional destinatário da remessa;',
    'unorderedList5': 'Atrasos, perdas, erros ou omissões resultantes da falha de telecomunicação ou de qualquer outro sistema de transmissão de dados e da falha da central do sistema do computador ou qualquer parte dele do website internacional destinatário da remessa;',
    'unorderedList6': 'Quaisquer resultados decorrentes de atos de governo ou autoridade, ou quaisquer atos de força maior;',
    'unorderedList7': 'Vícios e falhas dos produtos e serviços;',
    'unorderedList8': 'Avarias ocorridas durante o frete.',
    'orderedList6': 'O Usuário tem a responsabilidade de se informar com o website internacional, destinatário da remessa, acerca das possibilidades e formas de devolução da mercadoria ou cancelamento do serviço em caso de vício ou falha.'
  },
  'section11': {
    'title': 'TERMOS GERAIS',
    'orderedList1': 'O EBANX reserva-se o direito de suspender o sistema para reparo, segurança, manutenção e/ou melhoramentos, responsabilizando-se e garantindo todas as operações em andamento.',
    'orderedList2': 'Sempre que necessário, o EBANX poderá restringir os serviços e/ou acrescentar medidas de segurança adicionais para a adesão aos serviços.',
    'orderedList3': 'O Usuário poderá rescindir este Termo a qualquer momento por meio de um dos nossos canais de comunicação.'
  },
  'section12': {
    'title': 'POLÍTICA DE PRIVACIDADE',
    'orderedList1': 'O EBANX realiza o tratamento de seus dados de acordo com a Política de Privacidade EBANX, a qual pode ser consultada a qualquer momento pelo Usuário no endereço eletrônico: https://business.ebanx.com/pt-br/legal/consumidores/politica-de-privacidade/'
  },
  'section13': {
    'title': 'PROPRIEDADE INTELECTUAL',
    'orderedList1': 'O Usuário reconhece que é de titularidade do EBANX toda a propriedade intelectual empregada para prestação dos serviços ora contratados, em qualquer material criado ou disponibilizado pelo EBANX. Tal propriedade intelectual engloba o seguinte: (a) marcas, denominações sociais, nomes de serviços, slogans, trade dress, logotipos, nome de domínio da internet e outros sinais distintivos, assim como todos os pedidos, registros, extensões e renovações relacionadas; (b) patentes, pedidos de patente e todas as renovações relacionadas, modelos de utilidade, pedidos de modelos de utilidade, certificados de adição, pedidos de certificados de adição, extensões e renovações relacionadas e registros de invenções; (c) registros de desenhos industriais e pedidos de registros de desenho industrial, extensões e renovações relacionadas; (d) direitos autorais, programas de computador, layouts, formas de apresentação, combinações de cores, códigos fonte e registros e pedidos de registro relacionados; e (e) segredos industriais e know-how.',
    'orderedList2': 'O Usuário se compromete a não violar, reproduzir, imitar, total ou parcialmente, qualquer propriedade intelectual do EBANX, bem como a não utilizar qualquer propriedade intelectual do EBANX para quaisquer finalidades além das previstas neste Contrato e permitidas por lei.'
  },
  'section14': {
    'title': 'CASO FORTUITO E FORÇA MAIOR',
    'orderedList1': 'O EBANX e/ou as empresas parceiras não serão consideradas em mora ou inadimplente de quaisquer de suas obrigações previstas neste Termo se o motivo de seu descumprimento decorrer de caso fortuito ou força maior.'
  },
  'section15': {
    'title': 'ALTERAÇÃO DE CONTRATO',
    'orderedList1': 'O EBANX poderá alterar quaisquer condições deste Termo para adaptar o Contrato a alterações legislativas ou econômicas relevantes. Caso o Usuário não concorde com as alterações realizadas pelo EBANX, poderá imediatamente solicitar a rescisão deste Termo estando ciente de que a rescisão do Termo acarretará a impossibilidade de realizar novas compras utilizando os serviços do EBANX.'
  },
  'section16': {
    'title': 'ELEIÇÃO DE FORO',
    'orderedList1': 'Os termos e condições do presente Termo serão interpretados em conformidade com a legislação brasileira e estarão sujeitos à jurisdição exclusiva de seus tribunais, sendo este o foro eleito e competente, por mais privilegiado que outro possa ser em relação ao presente contrato.',
    'orderedList2': 'Para solução de eventuais conflitos relacionados a este Contrato, para pedidos de cancelamento, reclamações e sugestões, o Usuário poderá entrar em contato com o canal de atendimento disponível em https://ajuda.ebanx.com/hc/pt-br .',
    'description1': 'O presente Contrato está integralmente disponível para consulta no site do EBANX no endereço eletrônico: https://www.ebanx.com/br/termos/.',
    'description2': 'Declaro que li, entendi e concordei com as disposições previstas neste Contrato.'
  }
}
