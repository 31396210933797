import React, { useCallback, useEffect, createContext, useState, useContext, useMemo } from 'react';
import Service from 'service';
import { useSession } from './session-context';

export const FintechContext = createContext({});

export const FintechContextProvider = ({ children }) => {
  const [fintechListLoading, setFintechListLoading] = useState(true);
  const [fintechList, setFintechList] = useState([]);
  const { loggedIn, user } = useSession();
  const [selectedFintech, setSelectedFintech] = useState(null);

  const loadFintechList = useCallback(async () => {
    if (!loggedIn) {
      return;
    }

    setFintechListLoading(true);

    try {
      if (user?.userType === 'super-admin') {
        const { data } = await Service.tpps.list('');// empty quotes is need to handle the if condition in service api
        setFintechList(data.items);
        setSelectedFintech(data.items[0]);
      } else if(user?.fintechId){        
        setSelectedFintech({ id: user.fintechId });
      }
    } catch (err) {
      console.log(err);
    }

    setFintechListLoading(false);
  }, [loggedIn, user]);

  useEffect(() => {
    loadFintechList();
  }, [loadFintechList]);

  const value = useMemo(() => ({
    fintechListLoading,
    fintechList,
    selectedFintech,
    setSelectedFintech,
    reloadFintechList: loadFintechList
  }), [fintechListLoading, fintechList, selectedFintech, setSelectedFintech, loadFintechList]);

  return (
    <FintechContext.Provider value={value}>
      {children}
    </FintechContext.Provider>
  );
}

export const useFintech = () => useContext(FintechContext);
