export default {
  'tabTitle': 'política de Privacidade',
  'mainHeading': 'política de Privacidade',
  'subTitle': 'Sua privacidade é importante para nós. Processamos todas as informações sobre você de maneira gentil e responsável.',
  'lastModified': 'Última modificação: 19 de junho de 2023',
  'description': 'Esta política de privacidade se aplica entre você, o usuário deste site e Banfico Ltd, o proprietário e provedor deste site. Banfico Ltd leva a privacidade de suas informações muito a sério. Esta política de privacidade se aplica ao nosso uso de todos e quaisquer dados coletados por nós ou fornecidos por você em relação ao seu uso do site.',
  'warningMessage': 'Por favor, leia esta política de privacidade com cuidado.',
  'section1': {
    'title': 'Definições e interpretação',
    'orderedList1': 'Nesta política de privacidade, são utilizadas as seguintes definições:',
    'orderedList2': 'Nesta política de privacidade, são utilizadas as seguintes definições:',
    'unorderedList1': 'o singular inclui o plural e vice-versa;',
    'unorderedList2': 'referências a subcláusulas, cláusulas, apêndices ou apêndices são para subcláusulas, cláusulas, apêndices ou apêndices desta política de privacidade;',
    'unorderedList3': 'uma referência a uma pessoa inclui firmas, empresas, entidades governamentais, fundos fiduciários e parcerias;',
    'unorderedList4': '"incluindo" é entendido como "incluindo sem limitação";',
    'unorderedList5': 'a referência a qualquer disposição estatutária inclui qualquer modificação ou alteração da mesma;',
    'unorderedList6': 'os títulos e subtítulos não fazem parte desta política de privacidade.',
    'definitionRow': {
      'title1': 'Data',
      'description1': 'coletivamente todas as informações que você envia ao Banfico Ltd através do site. Esta definição incorpora, quando aplicável, as definições fornecidas nas Leis de Proteção de Dados;',
      'title2': 'Cookies',
      'description2': 'um pequeno arquivo de texto colocado em seu computador por este site quando você visita determinadas partes do site e/ou quando usa determinados recursos do site. Os detalhes dos cookies usados ​​por este site estão descritos na cláusula abaixo (Cookies);',
      'title3': 'Leis de proteção de dados',
      'description3': 'qualquer lei aplicável relacionada ao processamento de dados pessoais, incluindo, entre outros, a Diretiva 96/46/EC (Diretiva de Proteção de Dados) ou o GDPR, e quaisquer leis, regulamentos e legislação secundária de implementação nacional, desde que o GDPR seja eficaz no Reino Unido;',
      'title4': 'GDPR',
      'description4': 'o Regulamento Geral de Proteção de Dados (UE) 2016/679;',
      'title5': 'Lei de Cookies do Reino Unido e da UE',
      'description5': 'Banfico Ltd, uma empresa constituída na Inglaterra e no País de Gales com o número registrado 11036752, com sede em 1 Canada Square, Level39, Canary Wharf, Londres, E14 5AB;',
      'title6': 'Usuário ou você',
      'description6': 'qualquer terceiro que aceda ao Website e não seja (i) empregado da Banfico Ltd e a atuar no decurso da sua atividade ou (ii) contratado como consultor ou de outra forma a prestar serviços à Banfico Ltd e a aceder ao Website em ligação com o fornecimento de tais serviços; e',
      'title7': 'Website',
      'description7': 'o site que você está usando atualmente, www.banfico.com, e quaisquer subdomínios deste site, a menos que expressamente excluídos por seus próprios termos e condições.'
    }
  },
  'section2': {
    'title': 'Âmbito desta política de privacidade',
    'orderedList1': 'Esta política de privacidade aplica-se apenas às ações do Banfico Ltd e dos Usuários com relação a este Site. Não se estende a nenhum site que possa ser acessado a partir deste site, incluindo, entre outros, quaisquer links que possamos fornecer para sites de mídia social.',
    'orderedList2': 'Para efeitos das Leis de Protecção de Dados aplicáveis, o Banfico Lda é o "controlador de dados". Isto significa que o Banfico Ltd determina as finalidades e a forma como os seus Dados são processados.'
  },
  'section3': {
    'title': 'Dados coletados',
    'orderedList1': 'Podemos coletar os seguintes dados, que incluem dados pessoais, de você:',
    'unorderedList1': 'nome',
    'unorderedList2': 'Informações de contato, como endereços de e-mail e números de telefone;',
    'unorderedList3': 'informações demográficas, como código postal, preferências e interesses;',
    'unorderedList4': 'Endereço IP (coletado automaticamente);',
    'unorderedList5': 'tipo e versão do navegador da web (coletados automaticamente);',
    'unorderedList6': 'sistema operacional (coletado automaticamente);',
    'unorderedList7': 'uma lista de URLs começando com um site de referência, sua atividade neste site e o site para o qual você sai (coletado automaticamente);',
    'unorderedList8': 'em cada caso, de acordo com esta política de privacidade.'
  },
  'section4': {
    'title': 'Como coletamos dados',
    'orderedList1': 'Coletamos Dados das seguintes formas:',
    'unorderedList1': 'os dados são fornecidos a nós por você; e',
    'unorderedList2': 'os dados são coletados automaticamente.'
  },
  'section5': {
    'title': 'Dados que nos são fornecidos por você',
    'orderedList1': 'Banfico Lda irá recolher os seus Dados de várias formas, por exemplo:',
    'unorderedList1': 'quando você nos contata através do Site, por telefone, correio, e-mail ou por qualquer outro meio;',
    'unorderedList2': 'quando você se registra conosco e cria uma conta para receber nossos produtos/serviços;',
    'unorderedList3': 'quando você usa nossos serviços;',
    'description': 'em cada caso, de acordo com esta política de privacidade.'
  },
  'section6': {
    'title': 'Dados que são coletados automaticamente',
    'orderedList1': 'Na medida em que você acessar o Site, coletaremos seus Dados automaticamente, por exemplo:',
    'unorderedList1': 'coletamos automaticamente algumas informações sobre sua visita ao site. Essas informações nos ajudam a melhorar o conteúdo e a navegação do site e incluem seu endereço IP, data, horário e frequência com que você acessa o site e a maneira como você usa e interage com seu conteúdo.',
    'unorderedList2': 'coletaremos seus dados automaticamente por meio de cookies, de acordo com as configurações de cookies do seu navegador. Para obter mais informações sobre cookies e como os usamos no site, consulte a seção abaixo, intitulada "Cookies".'
  },
  'section7': {
    'title': 'Cookies',
    'description1': 'Abaixo está uma lista dos cookies que usamos. Tentamos garantir que isso esteja completo e atualizado, mas se você acha que perdemos um cookie ou há alguma discrepância, informe-nos.',
    'description2': 'Usamos os seguintes cookies estritamente necessários:',
    'definitionRow': {
      'title1': 'Descrição do Cookie',
      'description1': 'Propósito',
      'title2': 'Identidade e Sessão',
      'description2': 'Login seguro'
    }
  }
}
