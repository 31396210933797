/* eslint-disable no-useless-escape */
import isBrowser from 'components/lib/isbrowser'
import moment from 'moment';

export const calculatePercentage = (number1, number2) => {
  return Math.round((number1 / number2) * 100)
}

export const isValidWebUrl =(url) => {
  let regEx =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm
  return regEx.test(url)
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export const dateFormatter = (date) => {
  return (moment.utc(new Date(date)).format('YYYY-MM-DD h:mm:ss'))
}

export const getTheCurrentUrlBasedOnDomain = () => {
   if (isBrowser) {
    return window.location.origin
   }
}

export const getTheSubDomainFintech = () => {
  if (isBrowser) {
  const url = window.location.href?.trim?.()
  let matches = url?.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
  let domain = matches && matches?.[1]
  domain = domain?.split('.')
  if (domain?.length > 4) {
    return domain?.[1]
  } else {
    return null
  }
  }
}

/* Works for apiBaseUrl , auth related url's */
export const getNewApiBaseUrl = (currentUrl) => {
  let currentSubDomain = getTheSubDomainFintech();
  if (currentSubDomain) {
    if (currentUrl?.includes(currentSubDomain)) {
      return currentUrl
    } else {
      return currentUrl?.replace('obaf', `${currentSubDomain}.obaf`)
    }
  } else {
    return currentUrl
  }
}

export const getNewAuthUrl = (currentUrl) => {
  let currentSubDomain = getTheSubDomainFintech();
  if (currentSubDomain) {
   return currentUrl.replace('provider', `${currentSubDomain}`)
  } else {
    return currentUrl
  }
}