export default {
  'tabTitle': 'política de Privacidade',
  'mainHeading': 'política de Privacidade',
  'subTitle': 'Sua privacidade é importante para nós. Processamos todas as informações sobre você de maneira gentil e responsável.',
  'lastModified': 'Última modificação: 1 de junho de 2023',
  'description': 'Esta Política de Privacidade (“Política”) indica como os seus dados pessoais são tratados em razão do uso dos Serviços relacionados ao processamento de pagamentos referentes a compras realizadas por você nos sites de nossos clientes. Este documento é parte integrante dos Termos e Condições do EBANX, que contêm uma visão geral dos Serviços. Ao utilizar nossos Serviços, você concorda com nossa Política e nos autoriza a tratar os seus dados pessoais da forma aqui descrita para fornecer nossos Serviços adequadamente.',
  'sideHeading': 'Dividimos esta Política respondendo às seguintes perguntas:',
  'desc1': 'Quem é o EBANX?',
  'desc2': 'Que tipos de dados pessoais o EBANX coleta?',
  'desc3': 'Como o EBANX coleta dados pessoais?',
  'desc4': 'Para que o EBANX coleta dados pessoais?',
  'desc5': 'Com quem o EBANX pode compartilhar dados pessoais?',
  'desc6': 'O EBANX pode transferir dados pessoais internacionalmente?',
  'desc7': 'Por quanto tempo os dados pessoais serão armazenados?',
  'desc8': 'Quais os padrões de segurança de dados pessoais adotados pelo EBANX?',
  'desc9': 'Quais são os seus direitos como titular de dados?',
  'desc10': 'E os cookies?',
  'desc11': 'Como falar com o EBANX sobre privacidade?',
  'desc12': 'O que acontece se esta Política for alterada?',

  'section1': {
    'title': 'Quem é o EBANX?',
    'description1': 'O EBANX é uma empresa de processamento de pagamentos que fornece meios de pagamentos locais em sites internacionais (“Merchants”).',
    'description2': 'Nas compras realizadas por você nos Merchants que processam pagamentos com o EBANX, nós atuamos como operador de seus dados pessoais: somos responsáveis por processar o pagamento de compras de serviços ou produtos realizados por você nos sites que lhe oferecem nossos meios de pagamento.',
    'description3': 'Ainda, o EBANX pode agir como controlador dos seus dados pessoais em determinadas situações para fins comerciais próprios, como quando enviamos notificações sobre as compras realizadas por você com os meios de pagamento do EBANX ou quando tomamos medidas preventivas e reativas para identificar, prevenir e remediar fraudes e ameaças de segurança.',
    'description4': 'Para prestar nossos Serviços, tratamos seus dados pessoais de acordo com as instruções legítimas do site em que você realizou a compra de serviços ou produtos utilizando meios de pagamento do EBANX, de acordo com esta Política ou de acordo com a legislação de proteção de dados aplicável.',
    'description5': 'Esta Política se refere unicamente aos Serviços prestados pelo EBANX. Os produtos e serviços que você adquirir de nossos clientes estão sujeitos aos seus próprios termos de uso e políticas de privacidade, determinados por eles. Além disso, os métodos de pagamento que você usar também podem estar sujeitos aos termos de uso e políticas de privacidade determinados pelas instituições financeiras e emissores de cartões.'
  },
  'section2': {
    'title': 'Que tipo de dados pessoais o EBANX coleta?',
    'description1': 'Alguns dados que utilizamos para poder prestar nossos Serviços a você podem ser considerados dados pessoais – isso é, informações relacionadas a você que, individualmente ou em conjunto, podem identificá-lo. Podemos utilizar os seguintes dados para você usufruir de nossos Serviços, bem como para aprimorá-los:',
    'orderedList1': 'Dados de Identidade: incluem informações sobre sua identidade, como nome completo, número de identificação fiscal, data de nascimento, endereço, e-mail, nome da mãe e número de telefone.',
    'orderedList2': 'Dados Financeiros: incluem informações sobre detalhes do pagamento, informações bancárias e informações dos métodos de pagamento utilizados por você para comprar nos e-commerces que são nossos clientes.',
    'orderedList3': 'Dados Técnicos: incluem informações sobre seu IP, hora e data de acesso, geolocalização, dados sobre o seu dispositivo de acesso e cookies.',
    'orderedList4': 'Dados de Uso: Informações sobre como você usa nosso Serviço, como perfil e comportamento de compra e volume de transações.',
    'orderedList5': 'Dados Biométricos: incluem informações como fotos de seus documentos e fotos pessoais.',
    'description2': 'Também podemos coletar dados agregados, como dados estatísticos ou demográficos. Eles Agregados podem ser derivados de seus dados pessoais, mas não são considerados dados pessoais segundo a legislação, pois não revelam sua identidade direta ou indiretamente.',
    'description3': 'Dados Biométricos são os únicos dados sensíveis que podemos ter a intenção de tratar, exclusivamente com o intuito de prover segurança a você e aos nossos Serviços.  Não se preocupe, você será notificado quando precisarmos desse tipo de dados.'
  },
  'section3': {
    'title': 'Como o EBANX coleta dados pessoais?',
    'description1': 'Você pode nos fornecer Dados de Identidade e Dados Financeiros ao utilizar nossos Serviços de processamento de pagamentos. Também podemos coletar dados pessoais sobre você ao entrar em contato conosco por meio dos nossos canais de comunicação e de atendimento ao cliente. Podemos solicitar Dados Biométricos para fins exclusivos de prevenção à fraude e ameaças de segurança e para confirmar a sua identidade.',
    'description2': 'Ao interagir com nossos Serviços, podemos coletar dados automaticamente, como Dados Técnicos e Dados de Uso, por meio de cookies, registros em serviços, aplicações, softwares e tecnologias semelhantes.',
    'description3': 'Também podemos receber seus dados pessoais por intermédio dos Merchants dos quais você realiza a compra de produtos ou serviços com o processamento do pagamento pelo EBANX.',
    'description4': 'Além disso, para a sua segurança e para melhorar nossos Serviços, podemos receber dados pessoais sobre você de terceiros e fontes públicas, como empresas que nos apresentam a você, órgãos estatais e prestadores de serviço, incluindo agências de prevenção a fraudes.'
  },
  'section4': {
    'title': 'Para que o EBANX coleta dados pessoais?',
    'description1': 'Os seus dados pessoais são utilizados de acordo com as finalidades apresentadas abaixo, com suas respectivas bases legais, que autorizam o seu tratamento:',
    'tableData': {
      'section1': {
        'head': 'FINALIDADE	',
        'row1': 'Para prestar nossos Serviços,incluindo processamento de pagamentos, estornos e reembolsos, envio de notificações sobre transações, verificação de identidade, identificaçãoe prevenção de fraudes e ameaças desegurança, análise de dados, manutençãode sistemas, hospedagem de dados ecumprimento de nossas obrigações legaise regulatórias',
        'row2': 'Para analisar as transações e operfil de consumo, para fins deanálise comportamental e deprevenção de fraudes e ameaçasde segurança, para acompanhar emelhorar nosso desempenho eaprimorar nossos Serviços',
        'row3': 'Para gerenciar nosso relacionamentocom você, incluindo atendimentovia nossos canais de comunicação ede atendimento ao cliente, o que podeenvolver procedimentos para confirmarsua identidade e prevenir fraudes',
        'row4': 'Para o envio de comunicações demarketing direto para você via e-mail ou mensagem de texto ou canais decomunicação digitais'
      },
      'section2': {
        'head': 'DADOS PESSOAIS',
        'row1': 'Dados de Identidade, Dados Financeiros, Dados Técnicos e Dados de Uso',
        'row2': 'Dados de Identidade, Dados Financeiros, Dados Técnicos e Dados de Uso',
        'row3': 'Dados de Identidade, Dados Financeiros,Dados Técnicos e Dados de Uso e Dados Biométricos',
        'row4': 'Dados de Identidade'
      },
      'section3': {
        'head': 'BASE LEGAL',
        'row1': 'Obrigação legal, cumprimento decontrato com você e legítimo interesse (desenvolver e aprimorarnossos Serviços, prevenção e segurançaao processar os pagamentos)',
        'row2': 'Legítimo interesse (desenvolver eaprimorar nossos Serviços)',
        'row3': 'Obrigação legal, cumprimento decontrato e legítimo interesse (desenvolver e aprimorar nossosserviços)',
        'row4': 'Consentimento e legítimo interesse (desenvolver e aprimorar nossos serviços e oferecer a você produtose serviços que possam lhe interessar)'
      }
    },
    'description2': 'Geralmente não nos baseamos no consentimento como base legal para o tratamento de seus dados pessoais, exceto para o envio de comunicações de marketing direto para você via e-mail, mensagem de texto ou outros canais de comunicação digitais. Também poderemos enviar estas comunicações de marketing direto com base no legítimo interesse se entendermos que você possa se interessar por produtos e serviços que podemos lhe oferecer. Caso isso ocorra, você tem o direito de revogar o seu consentimento, ou de se opor ao legítimo interesse do EBANX, nas próprias comunicações do EBANX recebidas por você, entrando em contato conosco ou por meio da página de preferências de comunicação do EBANX.',
    'description3': 'Nos demais casos, se você se recusar a fornecer dados pessoais que precisamos em razão de lei ou regulação, ou em razão de termos de um contrato que temos com você, é possível que não possamos prestar os Serviços adequadamente.',
    'description4': 'Só usaremos seus dados pessoais para as finalidades segundo as quais os coletamos, a menos que consideremos que precisamos usá-los por outro motivo e, que esse motivo seja compatível com a finalidade original. Além disso, se precisarmos tratar seus dados pessoais com uma nova finalidade não relacionada originalmente, nós notificaremos você fornecendo explicações sobre o assunto.',
    'description5': 'Podemos tratar seus dados pessoais sem intervenção humana para analisar as transações e seu perfil de consumo, para fins de análise comportamental e para prevenção de fraudes e ameaças de segurança. Também podemos fazer isso para decidir quais comunicações de marketing são adequadas para você, analisar estatísticas e avaliar riscos. Tudo isso é feito com base em nossos interesses legítimos: para proteger nosso negócio e para desenvolver e melhorar nossos Serviços.'
  },
  'section5': {
    'title': 'Com quem o EBANX pode compartilhar dados pessoais?',
    'description1': 'Por oferecermos um Serviço que envolve operações de pagamento e transações financeiras, podemos atuar em conjunto com outras empresas para prestá-los adequadamente, o que pode envolver o compartilhamento de seus dados pessoais. Eles podem ser compartilhados com empresas como: empresas do mesmo grupo econômico; processadores de pagamento; agentes de prevenção a fraudes; agências de número de identificação fiscal; plataforma de remetentes de e-mails em massa; fornecedores de arranjos de pagamento com cartões. Somente compartilhamos seus dados com empresas que nos garantem o cumprimento de padrões de segurança do mercado.',
    'description2': 'Seus dados pessoais podem ser compartilhados com órgãos estatais e agências reguladoras para o cumprimento de obrigações legais e regulatórias às quais nos submetemos em todos os territórios onde prestamos nossos Serviços.',
    'description3': 'Ademais, podemos compartilhar seus dados pessoais para proteger os nossos direitos, de clientes ou de terceiros, para proteger seus interesses legítimos ou de outra pessoa, para aplicar nossos termos de uso ou outros contratos e para a propositura ou o exercício de defesa em demandas judiciais.',
    'description4': 'Nós não licenciamos, vendemos ou transferimos seus dados pessoais a ninguém, em nenhuma hipótese, com fins de obter lucro ou de forma contrária ao contido nesta Política.'
  },
  'section6': {
    'title': 'O EBANX pode transferir dados pessoais internacionalmente?',
    'description1': 'O EBANX possui sede no Brasil e seus dados pessoais são coletados de acordo com a lei brasileira. Contudo, nós podemos transferir seus dados pessoais internacionalmente para prestar nossos Serviços. Seus dados pessoais podem ser transferidos para os EUA, no caso de a empresa responsável pela hospedagem de suas informações estiver localizada lá.',
    'description2': 'Antes de transferir seus dados pessoais internacionalmente, garantimos que essa transferência ocorrerá de acordo com o grau de proteção exigido nesta Política. Para isso, seus dados pessoais serão transferidos somente para partes localizadas em países ou organizações internacionais que legalmente possuam um grau adequado de proteção de dados ou que estejam de acordo com cláusulas padrão contratuais exigidas por nós.'
  },
  'section7': {
    'title': 'Por quanto tempo os dados pessoais são armazenados?',
    'description1': 'Mantemos os seus dados pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, de prestação de contas ou requisição de autoridades competentes, observado o prazo mínimo de 10 (dez) anos contado do do ano seguinte ao término do nosso relacionamento com você, em atendimento às normativas do sistema financeiro aplicáveis.',
    'description2': 'Para determinar o período de retenção adequado para os dados pessoais, consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios. Caso determinados dados deixem de ser necessários para o alcance de determinada finalidade, serão excluídos ou sujeitos à anonimização.',
    'description3': 'Se você solicitar a exclusão de seus dados pessoais, os excluiremos assim que decorrer o prazo legal de manutenção mencionado acima, a menos que a sua manutenção seja determinada com base em obrigações legais ou por requisição de uma autoridade competente.'
  },
  'section8': {
    'title': 'Quais os padrões de segurança de dados pessoais adotados pelo EBANX?',
    'description1': 'Estabelecemos medidas de segurança administrativas e técnicas apropriadas para impedir que seus dados pessoais sejam acidentalmente perdidos, usados ​​ou acessados ​​de maneira não autorizada, alterados ou divulgados. Além disso, autorizamos o acesso aos seus dados pessoais aos funcionários, agentes, contratados e outros terceiros somente no limite do necessário para executarem suas atividades. Eles só tratarão seus dados pessoais de acordo com nossas instruções e estarão sujeitos a um dever de confidencialidade.',
    'description2': 'Estabelecemos procedimentos para lidar com qualquer suspeita de violação de dados pessoais e notificaremos você e qualquer regulador aplicável a respeito de alguma violação, caso assim sejamos obrigados a fazê-lo.',
    'description3': 'Somos certificados pelo PCI-DSS e temos certificação ISO/IEC 27001, o que significa que nossos Serviços seguem regras de segurança de alto padrão, utilizando criptografia, controle de acesso, monitoramento avançado, atualizações de segurança, testes de violação e demais procedimentos exigidos tanta pela regulação quanto pelos nossos parceiros de processamento de pagamentos.',
    'description4': 'Embora adotemos rigorosos padrões de segurança, a transmissão de informações na internet não é completamente segura e, embora façamos o nosso melhor para proteger os seus dados pessoais, não podemos garantir a segurança dos seus dados transmitidos online.'
  },
  'section9': {
    'title': 'Quais são os seus direitos como titular de dados?',
    'description1': 'Resumimos abaixo todos seus direitos como titular de dados que podem ser exercidos entrando em contato conosco:',
    'orderedList1': 'Direito de acesso. Você pode requisitar e receber uma cópia dos seus dados pessoais que possuímos. Além disso, você pode solicitar esclarecimentos sobre como coletamos os seus dados pessoais, quais critérios utilizamos, qual a finalidade de tratamento e com quem compartilhamos os seus dados pessoais.',
    'orderedList2': 'Direito de retificação. Você pode solicitar a retificação de dados pessoais que estejam incompletos, inexatos ou desatualizados, mediante a verificação de validade dos dados que você nos fornecer.',
    'orderedList3': 'Direito de anonimização, bloqueio ou eliminação. Você pode solicitar a anonimização, bloqueio ou eliminação de dados pessoais que você entenda que estão sendo tratados de maneira contrária a esta Política ou em desconformidade com a legislação de proteção de dados pessoais aplicável.',
    'orderedList4': 'Direito de oposição. Você pode se opor ao tratamento de seus dados pessoais que não sejam realizados com base no seu consentimento, caso você entenda que tal tratamento está violando seus direitos. Nesses casos, podemos demonstrar que temos motivos legítimos para tratar os seus dados pessoais conforme esta Política e para prestarmos nossos Serviços adequadamente.',
    'orderedList5': 'Direito de exclusão. Você pode solicitar a exclusão dos seus dados pessoais armazenados pelo EBANX, tratados com o seu consentimento, que não sejam mais necessários ou relevantes para a prestação dos Serviços, desde que não tenhamos outra razão para mantê-los, como para o cumprimento de obrigação legal ou regulatória de retenção dos dados ou para resguardar os direitos do EBANX.',
    'orderedList6': 'Direito de não fornecimento do consentimento. Você pode se recusar ao tratamento de dados pessoais realizado com base no seu consentimento a qualquer momento. Porém, se você retirar o seu consentimento, é possível que não possamos oferecer parte dos Serviços adequadamente – cujas consequências explicaremos a você.',
    'orderedList7': 'Direito de revisão. Você pode solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado, caso entenda que elas estejam afetando seus interesses.',
    'orderedList8': 'Direito de portabilidade. Você tem direito a solicitar a portabilidade de seus dados pessoais em formato estruturado e interoperável.',
    'description2': 'Para apresentar suas manifestações, você deverá apresentar requerimento expresso, em seu nome ou por meio de seu representante legal, nos endereços de contato do EBANX descritos no tópico abaixo. Esse requerimento não terá custo algum a você. Porém, podemos cobrar uma taxa se o seu pedido for claramente repetitivo ou excessivo, ou podemos nos recusar a atender sua solicitação nessas circunstâncias.',
    'description3': 'Para o exercício desses direitos, é possível que tenhamos que checar sua identidade e a validade dos seus dados pessoais. Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. O EBANX pode também contatá-lo para obter mais informações em relação à sua solicitação.',
    'description4': 'Serão fornecidas confirmações da existência de tratamento de dados e acesso a seus dados imediatamente, em formato simplificado, ou, por meio de declaração detalhada, no prazo de até 15 dias. Para demais respostas às suas solicitações, o EBANX tentará respondê-lo no prazo de 30 dias. Ocasionalmente, podemos levar mais tempo se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, o EBANX irá notificá-lo e mantê-lo atualizado sobre o andamento da sua solicitação.'
  },
  'section10': {
    'title': 'E os cookies?',
    'description1': 'Cookies são pequenos arquivos que podem armazenar, temporariamente, informações sobre sua navegação. Os cookies podem ser utilizados para diversos propósitos como armazenar informações suas preferências de navegação, coletar informações para oferecer conteúdos personalizados, ou até mesmo para redirecionar o navegador a outra parte do site, quando necessário.',
    'description2': 'Os cookies configurados por nós, são denominados cookies Primários. Mas, existem também, os cookies de terceiros, que são aqueles que são configurados por outros parceiros e permitem que recursos ou funcionalidades terceiras sejam fornecidos ou apareçam em nosso site (como anúncios de publicidade, conteúdo interativos e análises). Quem configura esses cookies terceiros pode reconhecer seu computador tanto quando você visita o nosso site, quanto quando você visita o site deles.',
    'description3': 'Nós utilizamos diversas categorias de cookies, com finalidades específicas. São elas:',
    'definitionRow': {
      'title1': 'Cookies Essenciais:',
      'description1': 'Esses são cookies essenciais e necessários para que o EBANX forneça alguns dos serviços do nosso site para você, como página de cadastro e aplicativos móveis, e também para que você consiga utilizar alguns recursos, como acessar áreas de segurança dentro do site.',
      'title2': 'Cookies de Performance e Funcionalidade:',
      'description2': 'Usados para aumentar e melhorar a funcionalidade do nosso site, serviços de cadastro e aplicativos móveis. Sem estes cookies algumas funcionalidades podem ser prejudicadas.',
      'title3': 'Cookies de Publicidade:',
      'description3': 'Estes cookies são utilizados para transformar mensagens publicitárias mais interessantes para você. Eles previnem que os anúncios já vistos apareçam novamente, garantem que os anúncios sejam posicionados em locais que não atrapalhem sua leitura e, em alguns casos, apresentam anúncios baseados diretamente em seus interesses.',
      'title4': 'Cookies de Mídias Sociais:',
      'description4': 'Esses cookies são usados para permitir que você compartilhe páginas e conteúdos que você tem interesse em nosso site em suas mídias sociais. Esses cookies também podem ser utilizados com intuito de publicidade.'
    },
    'description4': 'Você tem o direito de aceitar ou não os cookies. Você pode configurar seu navegador para aceitar ou recusar cookies. Se você optar por recusá-los, você ainda poderá acessar nosso site, mas algumas funcionalidades e áreas dele podem ficar restritas ou limitadas. Como as formas de restringir cookies podem variar de navegador para navegador, você deverá visitar a página de "ajuda" do seu navegador para obter mais informações.',
    'description5': 'Para mais informações sobre a configuração de cookies: http://www.aboutcookies.org/',
    'description6': 'Para os cookies do "Google Analytics", você pode fazer o download do módulo acessível a partir do endereço a seguir para desativar esses cookies;',
    'for': 'Para o',
    'description7': 'Nós contratamos uma tecnologia chamada "clear gifs" (conhecida como web beacons ou web bugs), que nos ajuda a gerenciar nosso site, identificando quais conteúdos são mais eficientes. "Clear Gifs" são pequenos gráficos com um identificador único, similar aos encontrados nos cookies, que são usados para rastrear os movimentos dos usuários na internet. Ao contrário dos cookies, que são armazenados no disco rígido dos computadores dos usuários, os "clear gifs" são incorporados de forma invisível nas páginas da Web ou em e-mails, e são tão pequenos quanto o ponto final dessa frase.',
    'description8': 'Nós usamos clear gifs ou pixels para sabermos quais usuários efetivamente abriram emails que nós enviamos em suas caixas de entrada. Isso nos ajuda a ter certeza da efetividade de nossas comunicações e ações de marketing. Com isso, unimos as informações geradas pelos clear gifs com as informações pessoais de nossos clientes.'
  },
  'section11': {
    'title': 'Como falar com o EBANX sobre privacidade?',
    'description1': 'Se você quiser exercer algum dos direitos previstos nesta Política ou na legislação pertinente, ou se você tiver dúvidas, comentários ou sugestões referentes a esta Política, você pode entrar em contato conosco nos seguintes endereços de contato:',
    'description2': 'Email: encarregadodedados@ebanx.com',
    'description3': 'Site: https://www.ebanx.com/br/ajuda'
  },
  'section12': {
    'title': 'O que acontece se esta política for alterada?',
    'description1': 'Quaisquer alterações que fizermos nesta Política serão publicadas nesta página e, quando apropriado, notificadas por e-mail. Por favor, volte com frequência a esta página para ver quaisquer atualizações ou alterações.'
  }
}
