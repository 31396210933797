/* eslint-disable max-lines */
export default {
  adminAnalytics: {
    title: 'API Analytics',
    subtitle: 'Showing analytics for last 7 Days',
    bestUptime: 'Best Uptime',
    lastDowntime: 'Last Downtime',
    fastestAvgResponseTime: 'Fastest Avg. Response Time',
    banks: 'Banks',
    avgUptime: 'Avg. Uptime',
    lastFailure: 'Last Failure',
    noDowntime: 'No downtime',
    avgResponseTime: 'Avg. Response Time'
  },
  adminAnalyticsDetail: {
    nav: {
      responseTime: 'Response Time',
      uptime: 'Uptime',
      apiRequests: 'API Requests',
      uniqueApiUsers: 'Unique API Users',
      regulatoryReports: 'Regulatory Reports'
    },
    format: 'Format',
    type: 'Type',
    downloadReports: 'Download Reports',
    pleaseSelectReportTypeAndDateRange:
      'Please select report type and date range.',
    reportFormat: 'Report Format',
    reportType: 'Report Type',
    dateRange: 'Date Range',
    requests: 'Requests',
    aispNoOfRequests: 'AISP - No. of Requests',
    noAispDataFound: 'No AISP Data Found!',
    pispNoOfRequests: 'PISP - No. of Requests',
    noDataFound: 'Sorry! No data available in the selected date period.',
    noRejectedPaymentsFound: 'No Rejected/Cancelled payments found!',
    average: 'Average',
    minimum: 'Minimum',
    maximum: 'Maximum',
    apiRequests: 'API Requests',
    aispAvgResponseTime: 'AISP - Avg. Response Time',
    pispAvgResponseTime: 'PISP - Avg. Response Time',
    apiResponseTime: 'API Response Time',
    today: 'Today',
    daysAgo: 'days ago',
    uptime: 'Uptime',
    users: 'Users',
    aispUniqueApiUsers: 'AISP - Unique API Users',
    pispUniqueApiUsers: 'PISP - Unique API Users',
    uniqueApiUsers: 'Unique API Users',
    days: 'days'
  },
  adminClientManagement: {
    title: 'ASPSP Clients',
    subtitle: 'Here you can manage your ASPSP client.',
    dcrManagement: 'DCR Management',
    aspspClientManagement: 'ASPSP Client Management',
    dcrRegistrationError: 'Some of dcr client registrations are failed!',
    aspsp: 'ASPSP',
    noAspspSelected: 'No ASPSP selected...',
    selectedAspsp: 'Selected ASPSPS(s)',
    dcrJobs: 'DCR Jobs',
    dcrInfo: 'DCR Info',
    webhookInfo: 'Webhook Info',
    runDcr: 'Run DCR',
    registrationStatus: 'Registration Status',
    clientDetails: 'Client Details',
    noDataMessageClient: 'You don’t have any client yet.',
    jobId: 'Job ID',
    manualFintechNoData: 'For manual fintech clients, no data is available.',
    aspspId: 'ASPSP ID',
    registrationType: 'Registration Type',
    mtlsPrivateKey: 'MTLS Private Key',
    mtlsPublicCertificate: 'MTLS Public Certificate',
    signingKeyId: 'Signing Key ID',
    signingPrivateKey: 'Signing Private Key',
    softwareAssertion: 'Directory Software Statement Assertion',
    listOfAspsp: 'List of ASPSP(s)',
    goToDcr: 'Go to DCR Management',
    noAspspAdded: 'You don’t have any ASPSP client yet. Please add a ASPSP to view them in the list.',
    clientOverview: 'Client Overview',
    dcrDetails: 'DCR Details',
    error: 'Error',
    activate: 'Activate',
    deactivate: 'Deactivate',
    product: 'Product'
  },
  apiKeys: {
    title: 'API Keys',
    createApiKey: 'Create API Key',
    keyTitle: 'Title',
    environment: 'Environment',
    noApiKeyFound: 'No API Key found.',
    revokeConfirmationMessage: 'Are you sure to revoke the key?',
    keyCopiedToClipboard: 'Key copied to clipboard'
  },
  customerDetails: {
    accountTemp: 'account temp',
    customers: 'Customers',
    transactions: 'Transactions',
    balances: 'Balances',
    consents: 'Consents',
    profile: 'Profile',
    noAccountSelected: 'Please select bank and account to see the details.',
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    searchText: 'Search by account name/number, references...',
    customerSearchText: 'Search CPF, account name/number ...',
    noTransactionText: 'You don’t have any transactions yet.',
    noConsentsText: 'You don’t have any consents yet.',
    noBalanceText: 'You don’t have any balances yet.',
    noCustomerText: 'You don’t have any customers yet.',
    allBanks: 'All Banks'
  },
  paymentHistory: {
    tabTitle: 'Payments',
    title: 'Overview',
    paymentId: 'Payment Id',
    reference: 'Reference',
    amount: 'Amount',
    paymentNoDataMessage: 'Sorry! No payments were done in the selected date period.',
    createdAt: 'Created At',
    updatedOn: 'Updated On',
    consentId: 'Consent Id',
    paymentDashboard: 'Payment Dashboard',
    details: 'Details',
    consentTitle: 'Consent Details',
    consentsNoDataMessage: 'No Consent details found for the payment!',
    clientId: 'Client ID',
    userId: 'User Id',
    searchBy: 'Search by account number, remittance information, transaction identification or endToEndId',
    searchByPlaceholder: 'Search by',
    status: 'Payment Status',
    cpfCnpj: 'CPF/CNPJ',
    accountNumber: 'Account Number',
    remittance: 'Remittance Information',
    transactionIdentification: 'Transaction Identification',
    paymentDetails: 'Payment Details',
    rejectionReason: 'Rejection Reason',
    cancellationReason: 'Cancellation Reason',
    banks: 'Banks',
    bank: 'Bank',
    overview: 'Overview',
    payments: 'Payments',
    debtorCpf: 'Debtor CPF',
    debtorCnpj: 'Debtor CNPJ',
    date: 'Date',
    rejectedPayments: 'Rejected/Cancelled Payments',
    noOfBookedPayments: 'No. of Booked Payments',
    total: 'Total Amount',
    viewDetails: 'View more',
    detail: 'Detail',
    noData: 'No data available!',
    searchByDebtor: 'Search by Debtor CPF/CNPJ',
    debtorCpfCnpj: 'Debtor CPF/CNPJ',
    creditorCpfCnpj: 'Creditor CPF/CNPJ',
    all: 'All',
    submittedDate: 'Submitted Date',
    scheduledDate: 'Scheduled Date',
    auditHistory: 'Audit History',
    paymentExecutedDate: 'Payment Executed Date',
    debtorBank: 'Debtor Bank',
    debtorBanks: 'Debtor Banks',
    pisClient: 'PIS Client',
    debtorsReference: 'Debtor\'s Reference',
    appId: 'App Id',
    startDate: 'Start Date',
    endDate: 'End Date',
    scheduledType: 'Schedule Type',
    filter: {
      status: {
        booked: 'Booked',
        future: 'Future',
        pending: 'Pending',
        rejected: 'Rejected',
        cancelled: 'Cancelled'
      },
      graph: {
        booked: 'Total Booked',
        future: 'Total Future',
        pending: 'Total Pending',
        rejected: 'Total Rejected',
        cancelled: 'Total Cancelled'
      }
    },
    consentStatus: {
      CONSUMED: 'Consumed',
      AUTHORISED: 'Authorised',
      AWAITING_AUTHORISATION: 'Awaiting Authorisation',
      REJECTED_ASPSP: 'Rejected by Bank',
      REJECTED_PSU: 'Rejected by Customer',
      REVOKED: 'Revoked',
      FUTURE: 'Future',
      RECEIVED: 'Received',
      CANCELLED: 'Cancelled',
      REJECTED: 'Rejected',
      BOOKED: 'Booked'
    },
    paymentStatus: {
      booked: 'Credit made at the target institution',
      pending1: 'Payment initiation or payment transaction is pending.',
      pending2: 'Additional checks are in progress.',
      future: 'The scheduling process has been carried out',
      rejected: 'Rejected payment instruction',
      cancelled: 'Payment cancelled by the payer'
    }
  },
  fintechManagement: {
    title: 'Fintech Management',
    subtitle: 'Here you can manage all the fintechs.',
    fintechSearchText: 'Search fintech name, ID or admin email...',
    organizationId: 'Organization ID',
    adminEmail: 'Admin Email',
    enableAccountsDataStorage: 'Enable Accounts Data Storage',
    addAdminUser: 'Add Admin User',
    noFintechMessage: 'You don’t have any fintech yet. Please create new fintech to view them in the list.',
    deleteConfirmationMessage: 'Are you sure you want to delete the following fintech',
    createdAt: 'Created At'
  },
  userManagement: {
    title: 'Manage Users',
    subtitle: 'Here you can manage your users',
    addNewUser: 'Add New User',
    searchText: 'Search user, name, email...',
    userPermissions: 'User Permissions',
    noAppDataMessage:
      'No users found. Please create new users to view them in the list.',
    followingUser: 'the following user?',
    adminUser: 'Admin User',
    adminUserDescription: `This user is the
                Admin of your client-bank, therefore you cannot delete or change
                the User Details, Permissions or Status of this user`,
    audit: 'Audit',
    auditDescription: `Can only see the information for the specific
                  Fintech`,
    developer: 'Developer',
    developerDescription: `Normal API developers for the specific
                  Fintech`,
    fintechAdmin: 'Fintech Admin',
    fintechAdminDescription: `Can perform all actions (including
                  managing users) for the specific Fintech`
  },
  apps: {
    test: 'Test',
    fintechId: 'FintechId',
    clientId: 'Client ID',
    obafRedirectUri: 'OBAF Redirect URI',
    product: 'Product',
    editApplicationClient: 'Edit Application Client',
    createApplicationClient: 'Create Application Client',
    fintechRedirectUri: 'Fintech Redirect URI',
    aspspClients: 'ASPSP Clients',
    redirectUrl: 'Redirect Url',
    webhookConfiguration: 'Webhook Configuration'
  },
  contact: {
    messageSentSuccessfully: 'Message sent successfully',
    topTitle: 'Let\'s connect',
    title: 'Talk to a Realtime Expert',
    description:
      'If you need any support using our service or have any feedback please let us know using the form below.',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    message: 'Message',
    submitNow: 'Submit Now',
    officeAddress: 'Office Address',
    website: 'Website',
    phone: 'Phone',
    companyNumber: 'Company Number',
    navigation: 'Navigation',
    home: 'Home',
    developer: 'Developers',
    contactUs: 'Contact Us',
    legals: 'Legals',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
    cookiesPolicy: 'Cookies Policy',
    getInTouch: 'Get in touch',
    allRightsReserved: 'All rights reserved',
    registrationNumber: 'Registration Number',
    registeredAddress: 'Registered office address',
    dashboard: 'Dashboard',
    contact: 'Contact',
    alreadyHaveAccount: 'Already have an account'
  },
  documentation: {
    userGuides: 'User Guides',
    faq: 'FAQ',
    products: 'Products',
    accountsApi: 'Accounts API',
    paymentsApi: 'Payments API',
    sdkIntegration: 'SDK Integration',
    title: 'Documentation',
    developerOnboarding: 'Developer Onboarding',
    gettingStarted: 'Getting Started',
    userManagement: 'User Management',
    aspspClientManagement: 'ASPSP Client Management',
    dcr: 'Dynamic Client Registration',
    softwareStatement: 'Get Software Statement Assertion',
    aispFlow: 'Account Information Service Flow',
    pispFlow: 'Payment Initiation Service Flow',
    testingGuides: 'Testing Guides'
  },
  lending: {
    title: 'Lending',
    searchText: 'Search CPF, account name/number ..',
    allBanks: 'All Banks',
    lendingNoDataMessage: 'You don’t have any lending yet.',
    accountName: 'Account Name',
    noOfTransaction: 'No.of Trans.',
    lastTransaction: 'Last Trans. Date',
    debit: 'Debit',
    credit: 'Credit',
    currentBalance: 'Current Balance',
    noLendingMessage: 'You don’t have any customers yet.',
    lendingEvaluation: 'Lending Evaluation',
    sendDetailsTo: 'Send the above details to'
  },
  productDetails: {
    title: 'Products',
    subtitle: ' Here you can manage your apps.',
    applications: 'Applications',
    forMoreIntegration: 'For more integrations contact',
    fintechAdmin: 'Fintech Admin',
    checkAllBanks: 'Check All Listed Banks',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    applicationRole: 'Here you can manage your apps.',
    noApplicationText:
      'You don’t have any apps yet. Please create new apps to view them in the list.'
  },
  userAnalytics: {
    title: 'Manage Application',
    subtitle: 'Showing analytics for last 7 Days',
    testingProduct: 'Testing Product',
    bankA: 'Bank A',
    bankB: 'Bank B',
    bankC: 'Bank C',
    upTime: 'Uptime',
    statusCode: 'Status Codes',
    usage: 'Usage',
    oneDay: '1 Day',
    week: '7 Days',
    month: '1 Month',
    showLast: 'Show Last',
    sortBy: 'Sort by',
    sortByApp: 'Sort by App',
    checking1: 'Checking 1'
  },
  helmetTitle: {
    authenticating: 'Authenticating',
    register: 'Register',
    session: 'Session Expired'
  },
  profile: {
    title: 'Profile',
    subtitle: 'Here you can manage your account.',
    email: 'Email',
    fullName: 'Full Name',
    phone: 'Phone',
    organizationName: 'Organization Name',
    organizationLogo: 'Organization Logo',
    organizationStatus: 'Organization Status',
    contactPerson: 'Contact Person',
    contactPhone: 'Contact Phone',
    contactAddress: 'Contact Address',
    country: 'Country',
    dateOfBirth: 'Date of Birth',
    jobTitle: 'Job Title',
    accountSettings: 'Account Settings',
    password: 'Password',
    temporaryPassword: 'Temporary Password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    retypeNewPassword: 'Retype new password',
    atLeastCharacters: 'At least 8 characters',
    atLeastUpperCaseLetter: 'At least 1 upper case letter',
    atLeastNumber: 'At least 1 number',
    atLeastSpecialCharacters: 'At least 1 special characters ! @ # $ ~',
    registrationSuccess:
      'Registration success ! OBAF team will contact you soon.',
    agreeTo: 'I agree to the',
    termsAndConditions: 'Terms and conditions',
    websiteAgree: 'By using this website you agree to our',
    privacy: 'Privacy',
    logOut: 'log out'
  },
  navigationLabels: {
    products: 'Products',
    productDetail: 'Product Detail',
    documentation: 'Documentation',
    apiAnalytics: 'API Analytics',
    apiKeys: 'API Keys',
    apiAnalyticsDetail: 'API Analytics Detail',
    userManagement: 'User Management',
    aspspClientManagement: 'ASPSP Client Management',
    dcrManagement: 'DCR Management',
    customers: 'Customers',
    lending: 'lending',
    paymentHistory: 'Payments',
    fintechManagement: 'Fintech Management'
  }
}