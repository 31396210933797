import config from 'components/config'
import request from 'components/lib/request'
import { getNewApiBaseUrl } from 'components/common/helpers'
const { apiBase } = config
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/roles`

const roles = {
  list: () => request({ uri: `${baseUrl}` })
}

export default roles
