/* eslint-disable max-lines */
export default {
  home: {
    availableInBrazil: 'Available in Brazil',
    title: 'Open Banking Aggregation Framework',
    pageDescription: `Open Banking Aggregation Framework (OBAF) aggregates OB APIs
                 from multiple banks into a marketplace to meet your Open
                 Banking Competitive Strategy. It allows you to immediately take
                 advantage of OB APIs in your market.`,
    getStarted: 'Get Started',
    viewDocs: 'View Docs',
    weOffer: 'What we offer',
    obafIncludes: 'Our OBAF Includes',
    fintechPortal: 'Fintech Portal',
    accountInformation: 'Account information',
    accountDescription: `For customer representatives and relationship managers. It
                     includes customer data, accounts, payments, transactions,
                     balances and many more.`,
    monitoring: 'Monitoring and administrating',
    operationStaff: 'For operations staff.',
    marketPlace: 'Marketplace',
    marketPlaceDescription: `For third party applications such as FX, personal financial
                     management, lend evaluation & credit scoring applications.`,
    customerPortal: 'Customer Portal',
    customerPortalDescription: `For authorizing and aggregating account information and
                   payments - reference client implementation.`,
    coreFeatures: 'Our core features ',
    whyBanfico: 'Why use Banfico OBAF?',
    point1: 'Single API to connect to all Bank APIs',
    point2: 'Multi tenant – provision multiple propositions',
    point3: 'API Gateway – protect and provision access',
    point4: 'Fintech Portal – leverage Open Banking immediately​',
    point5: 'Developer Portal – Self serve onboarding and discovery​​',
    point6: 'Customer Portal – reference client implementation',
    point7: 'SaaS or On Premises',
    point8: 'Marketplace integrations – leverage fintech apps',
    startForFree: 'Start For Free',
    description: 'description',
    keywords: 'keywords'
  },
  userGuide: {
    welcomeToDocs: 'Welcome to the Docs',
    welcomeDesc: 'Here you\'ll find user guides, standards, resources, and references to build with Banfico OBAF.',
    title: 'User Guides',
    developerOnboard: 'Developer Onboarding',
    gettingStarted: 'Getting Started',
    description: 'On-boarding of a developer would require registration.The identity of the developer is an user email.',
    fintechManagement: 'Fintech Management',
    fintechDescription: 'On-boarding of developer would require a registration.The identity of the developer is an user email.',
    userManagement: 'User Management',
    userDescription: 'To manage the users in the OBAF Portal.',
    aspspClientManagement: 'ASPSP Client Management',
    clientDescription: 'To manage the ASPSP Clients for a particular Fintech.',
    readMore: 'Read More'
  },
  developerOnboard: {
    description: 'Developers are welcome to use our bank APIs based on OBBR specification. Check with our team on possibilities of collaboration.',
    registrationDescription: 'On-boarding of Developer would require setup of an account using user email. There will be an email verification link sent during the first login attempt.',
    loginDescription: 'The login will require mobile phone for second factor authentication via Google authenticator app.'
  },
  userManagement: {
    description: 'This User Manual details the “User Management” feature of the OBAF application.',
    roles: 'User Roles',
    roleDescription: 'There are two user roles that can be assigned to a user',
    devRole: 'Developer',
    devRoleDesc: 'Users can manage applications and test the OBAF api\'s',
    adminRole: 'Fintech Admin',
    adminRoleDesc: 'Users can manage ASPSP clients, manage users for a particular Fintech.  Users who are assigned with this role are considered as “Admin” users',
    addUser: {
      title: 'Add New User',
      login: 'Login to OBAF portal.',
      goTo: 'Go to',
      tab: 'tab in the top of the screen.',
      add: 'Admin users can add a new user by clicking',
      rightButton: 'button on the top right corner.',
      addUserScreen: 'After clicking the button, the “Add New User” screen is displayed and the following details are entered in this screen:',
      email: 'Email (will be used as the Login ID)',
      userPermissions: 'User Permissions',
      password: 'Temporary Password',
      status: 'Status',
      note: 'Only Active users will be able to login to the portal.',
      onclick: 'On click of',
      userCreated: 'button, the user details are saved and a new user is created.',
      googleAuth: 'The new user can now login to the portal.The login will require google authenticator mobile app for second factor authentication.',
      resetPassword: 'After completing it, the user will be required to reset the password.',
      verifyEmail: 'There will be an email verification link sent during the first login attempt, User can verify the email by clicking the link.'
    }
  },
  clientManagement: {
    description: 'This User Manual details the "Client Management” feature of the OBAF application.',
    users: 'Users with',
    admin: 'Admin',
    permission: 'access permission will be able to create and manage ASPSP Clients for a particular Fintech.',
    addClient: 'Add New Client',
    add: 'Users can add a new client by clicking',
    dcr: 'DCR Management',
    products: 'According to Open Banking requirements, bank provides two API products. Select the product that you want to use',
    aisp: 'AISP',
    pisp: 'PISP',
    selectAspsp: 'Select one or more ASPSP (Open Banking participant) to create client registration.',
    payment: 'Below is an example to show how to make payment (PISP) in the Juno (bank).',
    createDcr: 'Create DCR Registration',
    popup: 'After selecting the ASPSP the popup will expand for registering the TPP client. Details like',
    privateKey: 'private key',
    cert: 'certificate',
    needtoFill: 'needs to be filled.',
    pastePrivatekey: 'Provide the',
    mtlsPrivateKey: 'MTLS Private Key',
    field: 'values',
    mtlsPublicKey: 'MTLS Public Key',
    copy: 'As an example, copy this value',
    paste: 'and paste it in',
    kid: 'Signature Private Key ID',
    below: 'field and below private key can be used in the',
    signaturePrivateKey: 'Signature Private Key',
    followSteps: 'To get the Software Statement Assertion (SSA) you can follow the steps given',
    here: 'here',
    jwt: 'Paste the generated JWT in',
    ssa: 'Software Statement Assertion',
    clickDcr: 'field and click',
    runDcr: 'Run DCR',
    success: 'On success, new Client will be created.'
  },
  softwareStatement: {
    title: 'Get Software Statement Assertion using Postman',
    description: 'This User Manual details the steps to configure and get the Software Statement Assertion using Postman.',
    addCert: 'Add mTLS certificates',
    onClick: 'Click on the',
    setting: 'settings icon',
    select: 'and select',
    selectCert: 'Select',
    click: 'tab and click on',
    host: 'Use the below host and provide Bank specific',
    button: 'button',
    success: 'On success, the added certificate will be displayed in the',
    postman: 'Postman Setup',
    importCollection: 'Download and Import the below postman collection file into Postman.',
    openCollection: 'Open the Collection',
    selectEnv: 'and select the environment',
    provide: 'Provide the',
    provideSpecific: 'Provide the specific',
    execute: 'and execute the',
    dcrFolder: 'endpoint present within the DCR folder.',
    sslCert: 'SSL Certificate Validation should be disabled as shown below.',
    jwt: 'Here the Software Statement Assertion will be generated as a JWT.'
  },
  aisp: {
    description: 'Testing AISP flow using Swagger',
    product: 'When Developer wants to test the AIS APIs, they would need to create an app in their Dashboard of API product type',
    followSteps: 'Follow the below steps to complete the AISP flow',
    selectApp: 'Select App',
    onClick: 'On clicking the',
    test: 'button against the App, the Developer is redirected to the Swagger UI.',
    devAuth: 'Developer Authentication',
    authorize: 'The first step is for the AISP\'s App-ID to get authenticated. Click the Authorise button to get an access token specific to the Developer.',
    totalApi: 'The OBAF AISP product has a total of 10 API groups and 47 endpoints. These endpoints are used to share different account data.',
    getAspsp: 'Get List of Available ASPSPs',
    authHeader: 'The authorisation header is auto-populated.',
    tryItout: 'Try it out',
    execute: 'Then, click on Execute button.',
    available: 'The response will have the list of available',
    bank: 'ASPSP/bank',
    fintech: 'that the Fintech has created through Client registration',
    id: 'Note that each ASPSP/bank has an id field which is considered as',
    aspspId: 'aspspId',
    getIntAspsp: 'Get List of Integrated ASPSPs',
    tryOut: 'Try out',
    listAspsp: 'It returns the list of ASPSP/bank for a particular end-user',
    success: 'On successfull request, the response should be the list of',
    endUser: 'for the requested end-user.',
    connectAspsp: 'Connect to the ASPSP',
    integrateAspsp: 'It integrates with specific ASPSP/bank',
    payload: 'Use the below payload to send the request',
    modalDesc: 'You can check the Model description to find the explanation for each field present in the request body.',
    note: 'Please note that you need to provide all the permissions related to the specific account data that you need to fetch.',
    successReq: 'On successfull request, it should return an',
    field: 'field that contains the',
    authPage: 'Copy this URI and paste it in the browser to access the ASPSP/bank authentication page.',
    bankLoginPage: 'On hitting the URI in browser, you will see the ASPSP/bank authentication page as below. Enter the end-user credentials to log in.',
    review: 'Review and share the account consent and confirm.',
    redirect: 'Then, ASPSP/bank redirects the user back to Fintech portal.',
    getIntAspspDetail: 'Get Integrated ASPSP Details',
    aspspDetail: 'It provides information about a particular ASPSP/bank',
    intEndUser: 'that was integrated to a particular end-user',
    successInt: 'On successfull request, the response should be the detail of the requested ASPSP/bank integrated with the requested end-user.',
    revoke: 'Revoke Integration with ASPSP',
    revokeAccess: 'to revoke access to an Integrated ASPSP/bank',
    particularEndUser: 'for a particular end-user',
    successRes: 'On successfull request, we get a response with status code 200.',
    listAspspConsents: 'List Consents from ASPSP',
    listConsents: 'It lists all the consents provided by a particular end-user',
    successConsent: 'On successfull request, the response should be a list of consents that the end-user has provided.',
    listAllAccs: 'List All Accounts',
    listIntAccs: ' It lists all the accounts integrated with a particular end-user',
    whenExec: 'When executing',
    required: 'request for connecting to a bank, it is required to have',
    accData: 'permission in the request body to get the list of accounts data.',
    successAcc: 'On successfull request, the response should be a list of accounts that the end-user has integrated.',
    listAllTrans: 'List All Transactions',
    listTrans: 'It lists all the transactions integrated with a particular end-user',
    transData: 'permission in the request body to get the list of transactions data.',
    successTrans: 'On successfull request, the response should be a list of transactions grouped by status that the end-user has integrated.',
    listAspspAccs: 'List Accounts from ASPSP',
    listIntAspspAccs: 'to get the list of accounts integrated with a specific ASPSP/bank',
    byEndUser: 'by a particular end-user',
    accsData: 'permission in the request body to get the list of accounts data.',
    successAccs: 'On successfull request, the response should be a list of accounts that the end-user has integrated.',
    accDetail: 'Get Account Detail for a specific account',
    listAccDetail: 'to get the detail of a specific account integrated with a specific ASPSP/bank',
    listAllAccDetail: 'permission in the request body to get of accounts data.',
    successAccDetail: 'On successfull request, the response should be the detail of the requested account.',
    balanceDetail: 'Get Balance Detail for a specific account',
    listBalDetail: 'to read the balance for a specific account integrated with a specific ASPSP/bank',
    listAllBalDetail: 'permission in the request body to get the balance data.',
    successBalDetail: 'On successfull request, the response should be the balance detail for the requested account.',
    transDetail: 'Get Transaction Detail for a specific account',
    listTransDetail: 'to read the transactions for a specific account integrated with a specific ASPSP/bank',
    listAllTransDetail: 'permission in the request body to get the transaction data.',
    successTransDetail: 'On successfull request, the response should be the transaction detail for the requested account.',
    personalIdenData: 'Get Personal Identification Data',
    listpersonalIdenDetail: 'to get the list of personal identification data integrated with a specific ASPSP/bank',
    listAllpersonalIdenDetail: 'permission in the request body to get the personal identification data.',
    successpersonalIdenDetail: 'On successfull request, the response should be a list of personal identification data integrated with a specific ASPSP/bank.',
    personalQualificationData: 'Get Personal Qualification Data',
    listpersonalQualificationDetail: 'to get the list of personal qualification data integrated with a specific ASPSP/bank',
    listAllpersonalQualificationDetail: 'permission in the request body to get the personal qualification data.',
    successpersonalQualificationDetail: 'On successfull request, the response should be a list of personal qualification data integrated with a specific ASPSP/bank.',
    personalFinancialData: 'Get Personal Financial Relations Details',
    listpersonalFinancialDetail: 'to get the list of personal financial relations data integrated with a specific ASPSP/bank',
    listAllpersonalFinancialDetail: 'permission in the request body to get the personal financial relations data.',
    successpersonalFinancialDetail: 'On successfull request, the response should be a list of personal financial relations data integrated with a specific ASPSP/bank.',
    businessIdenData: 'Get List of Business Identification Data',
    listbusinessIdenDetail: 'to get the list of business identification data integrated with a specific ASPSP/bank ',
    listAllbusinessIdenDetail: 'permission in the request body to get list of business identification data.',
    successbusinessIdenDetail: 'On successfull request, the response should be a list of business identification data integrated with a specific ASPSP/bank.',
    businessQualificationData: 'Get Business Qualification Data',
    listbusinessQualificationDetail: 'to get the list of business qualification data integrated with a specific ASPSP/bank',
    listAllbusinessQualificationDetail: 'permission in the request body to get the business qualification data.',
    successbusinessQualificationDetail: 'On successfull request, the response should be a list of business qualification data integrated with a specific ASPSP/bank.',
    businessFinancialData: 'Get Business Financial Relations Details',
    listbusinessFinancialDetail: 'to get the list of business financial relations data integrated with a specific ASPSP/bank ',
    listAllbusinessFinancialDetail: 'permission in the request body to get the business financial relations data.',
    successbusinessFinancialDetail: 'On successfull request, the response should be a list of business financial relations data integrated with a specific ASPSP/bank.',
    resourcesDetail: 'Get List of Resources',
    listresourceDetail: 'to get the list of features consented to by the client integrated with a specific ASPSP/bank',
    listAllresourceDetail: 'permission in the request body to get the list of resources.',
    successresourceDetail: 'On successfull request, the response should be a list of resources data integrated with a specific ASPSP/bank.',
    creditAccDetail: 'Get List of Credit Card Accounts',
    listcreditAccDetail: 'to get the list of postpaid payment accounts integrated with a specific ASPSP/bank',
    listAllcreditAccDetail: 'permission in the request body to get the list of credit card accounts.',
    successcreditAccDetail: 'On successfull request, the response should be the list of credit card accounts integrated with a specific ASPSP/bank.',
    creditAccDetailData: 'Get Credit Card Account Details',
    listcreditAccDetailData: 'to get the postpaid payment account details of a specific account integrated with a specific ASPSP/bank',
    listAllcreditAccDetailData: 'permission in the request body to get the list of credit card account data.',
    successcreditAccDetailData: 'On successfull request, the response should be the credit card account details held by the end-user.',
    creditAccLimitData: 'Get Credit Card Account Limits',
    listcreditAccLimitData: 'to get the postpaid payment account limits of a specific ASPSP/bank',
    listAllcreditAccLimitData: 'permission in the request body to get the credit card account limits data.',
    successcreditAccLimitData: 'On successfull request, the response should be the list of credit card account limits held by the end-user.',
    creditAccTransData: 'Get List of Credit Card Account Transactions',
    listcreditAccTransData: 'to get the postpaid payment account transactions of a specific ASPSP/bank',
    listAllcreditAccTransData: 'permission in the request body to get the postpaid payment account transactions data.',
    successcreditAccTransData: 'On successfull request, the response should be the list of credit card account transactions held by the end-user.',
    creditRecentAccTransData: 'Get List of Recent Credit Card Account Transactions',
    listRecentAccTransData: 'to get the postpaid payment account transactions for the last 7 days of a specific ASPSP/bank',
    listAllRecentAccTransData: 'permission in the request body to get the postpaid payment account transactions data.',
    successRecentAccTransData: 'On successfull request, the response should be the list of recent credit card account transactions held by the end-user.',
    creditAccBillsData: 'Get List of Credit Card Account Bills',
    listAccBillsData: 'to get the postpaid payment account bills of a specific ASPSP/bank',
    listAllAccBillsData: 'permission in the request body to get the postpaid payment account bills data.',
    successAccBillsData: 'On successfull request, the response should be the list of credit card account bills held by the end-user.',
    creditAccBillsTransData: 'Get List of Credit Card Account Bill Transactions',
    listAccBillsTransData: 'to get the postpaid payment account bill transactions of a specific ASPSP/bank ',
    listAllAccBillsTransData: 'permission in the request body to get the postpaid payment account bill transactions data.',
    successAccBillsTransData: 'On successfull request, the response should be the list of credit card account bill transactions held by the end-user.',
    procedure: 'To execute the APIs for Credit Operations - Loans, Credit Operations - Financings, Credit Operations - Unarranged Accounts Overdraft and Credit Operations - Invoice Financings, please follow the same procedure as explained above'
  },
  pisp: {
    description: 'Testing PISP flow using Swagger',
    product: 'When Developer wants to test the PIS APIs, they would need to create an app in their Dashboard of API product type',
    followSteps: 'Follow the below steps to complete the PISP flow',
    selectApp: ' Select App',
    onClick: 'On clicking the',
    test: 'button against the App, the Developer is redirected to the Swagger UI.',
    devAuth: 'Developer Authentication',
    authorize: 'The first step is for the PISP\'s App-ID to get authenticated. Click the Authorise button to get an access token specific to the Developer.',
    availableAspsp: 'Get List of Available ASPSPs',
    authHeader: 'The authorisation header is auto-populated.',
    tryItout: 'Try out',
    execute: 'Then, click on Execute button.',
    available: 'The response will have the list of available',
    bank: 'ASPSP/bank',
    fintech: 'that the Fintech has created through Client registration',
    id: 'Note that each ASPSP/bank has an id field which is considered as',
    aspspId: 'aspspId',
    domesticPaymentCall: 'Domestic Payment request call',
    paymentRequest: 'Payment request can be created for a particular end-user',
    specificBank: 'in a specific ASPSP/bank',
    response: 'You can get the aspspId for banks from the above response',
    onSuccess: 'On successfull request, it should return an',
    field: 'field that contains the',
    authPage: 'Copy this URI and paste it in the browser to access the ASPSP/bank authentication page.',
    enterCred: 'Enter the end-user credentials to log in',
    redirect: 'Once logged in, the user will be redirected to Payment confirmation screen to review the payment data and confirm. On Successfull request, the user will be redirected back to this window.',
    listDomesticPayments: 'Get List of Domestic Payments',
    nowTryOut: 'Now try out this endpoint',
    listPayments: 'It returns the list of payments for a particular end-user',
    successReq: 'On successfull request, the response should be the list of payments for the requested end-user.'
  },
  postmanFlow: {
    title: 'Testing the APIs using Postman',
    description: 'This User Manual details the steps to configure and test the APIs using Postman.',
    supportedFlows: 'Currently supported type of flows',
    pispFlow: 'Payment Initiation Flow',
    import: 'Download and Import the below Collection and Environment files into Postman.',
    openEnv: 'Open the Environment OBAF-PISP-SBX and update the',
    save: 'values for PISP, as per the user that needs to be validated and save the change.',
    openCollection: 'Open the Collection',
    selectEnv: 'and select the environment',
    executeToken: 'Execute the Token request endpoint present within the BRAZIL PISP folder.',
    execute: 'Execute the',
    listAvailableAspsp: 'List of Available ASPSPs',
    response: 'endpoint and the response will have the list of available ASPSP/bank that the Fintech has created through Client registration.',
    particularEndUser: 'endpoint for a particular end-user (x-user-id) in a specific ASPSP/bank (aspspId)',
    onSuccess: 'On successfull request, an',
    bankAuthUri: 'will be generated which is the ASPSP/bank Authentication URI',
    bankAuthPage: 'Copy this URI and paste it in the browser to access the ASPSP/bank authentication page.',
    creds: 'Enter the end-user credentials to log in.',
    paymentConfirm: 'Once logged in, the user will be redirected to Payment confirmation screen to review the payment data and confirm. On Successfull request, the user will be redirected back to Fintech and the payment will be processed.',
    listPayments: 'endpoint, which returns the list of payments for a particular end-user (x-user-id).',
    specificPayment: 'endpoint, to get the details for a specific payment.'
  }
}