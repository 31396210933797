class PubSub {

  _callbacks;

  constructor() {
    this._callbacks = {};
  }

  subscribe(name, cb) {
    if (!this._callbacks[name]) {
      this._callbacks[name] = [cb];
    } else {
      this._callbacks[name].push(cb);
    }
  }

  unsubscribe(name, cb) {
    const callbacks = this._callbacks[name];

    if (callbacks && callbacks.length) {
      const index = this._callbacks[name].indexOf(cb);

      if (index !== -1) {
        this._callbacks[name].splice(index, 1);
      }
    }
  }

  publish(name, ...data) {
    const callbacks = this._callbacks[name];

    if (callbacks && callbacks.length) {
      callbacks.forEach(
        (cb) => typeof cb === 'function' && cb(...data)
      );
    }
  }

}

export default new PubSub();