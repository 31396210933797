import config from 'components/config';
import request from 'components/lib/request';
import {getNewApiBaseUrl} from 'components/common/helpers'


const { apiBase } = config;
const baseUrl = `${getNewApiBaseUrl(apiBase)}/resources/clients`;

const clients = {
  list: (fintechId) => request({ uri: `${baseUrl}?fintechId=${fintechId}` }),
  get: (id) => request({ uri: `${baseUrl}/${id}` }),
  create: (body) => request({ uri: `${baseUrl}`, method: 'POST', body }),
  update: (body) => request({ uri: `${baseUrl}`, method: 'PUT', body }),
  delete: (id) => request({ uri: `${baseUrl}/${id}`, method: 'DELETE' })
}

export default clients;