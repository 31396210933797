// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-callback-js": () => import("./../../../src/pages/auth/callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-docs-faq-js": () => import("./../../../src/pages/docs/faq.js" /* webpackChunkName: "component---src-pages-docs-faq-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-docs-standards-js": () => import("./../../../src/pages/docs/standards.js" /* webpackChunkName: "component---src-pages-docs-standards-js" */),
  "component---src-pages-expired-js": () => import("./../../../src/pages/expired.js" /* webpackChunkName: "component---src-pages-expired-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-aisp-js": () => import("./../../../src/pages/products/aisp.js" /* webpackChunkName: "component---src-pages-products-aisp-js" */),
  "component---src-pages-products-cbpii-js": () => import("./../../../src/pages/products/cbpii.js" /* webpackChunkName: "component---src-pages-products-cbpii-js" */),
  "component---src-pages-products-pisp-js": () => import("./../../../src/pages/products/pisp.js" /* webpackChunkName: "component---src-pages-products-pisp-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-user-documentation-accounts-api-js": () => import("./../../../src/pages/user/documentation/accounts-api.js" /* webpackChunkName: "component---src-pages-user-documentation-accounts-api-js" */),
  "component---src-pages-user-documentation-payments-api-js": () => import("./../../../src/pages/user/documentation/payments-api.js" /* webpackChunkName: "component---src-pages-user-documentation-payments-api-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-private-js": () => import("./../../../src/templates/private.js" /* webpackChunkName: "component---src-templates-private-js" */),
  "component---src-templates-public-js": () => import("./../../../src/templates/public.js" /* webpackChunkName: "component---src-templates-public-js" */)
}

