/* eslint-disable max-lines */
export default {
  home: {
    availableInBrazil: 'Disponível no Brasil',
    title: 'Open Banking Aggregation Framework',
    pageDescription: `Open Banking Aggregation Framework (OBAF) agrega APIs OB
                      de vários bancos em um mercado para atender à sua
                      estratégia competitiva de Open Banking. Ele permite que você aproveite imediatamente
                      as APIs do OB em seu mercado.`,
    getStarted: 'Iniciar',
    viewDocs: 'Exibir Documentos',
    weOffer: 'O que oferecemos',
    obafIncludes: 'Nosso OBAF inclui',
    fintechPortal: 'Portal Fintech',
    accountInformation: 'Informação de Conta',
    accountDescription: `Para representantes de clientes e gerentes de relacionamento.
                          Inclui dados de clientes, contas, pagamentos, transações,
                          saldos e muito mais.`,
    monitoring: 'Monitoramento e administração',
    operationStaff: 'Para a equipe de operações.',
    marketPlace: 'Marketplace',
    marketPlaceDescription: `Para aplicativos de terceiros, como FX, gerenciamento financeiro
                          pessoal, avaliação de empréstimos e aplicativos de pontuação de crédito.`,
    customerPortal: 'Portal do Cliente',
    customerPortalDescription: `Para autorizar e agregar informações de contas
                            e pagamentos - implementação do cliente de referência.`,
    coreFeatures: 'Nossos recursos principais ',
    whyBanfico: 'Porquê utilizar o Banfico OBAF?',
    point1: 'API única para se conectar a todas as APIs do banco',
    point2: 'Vários tenants - provisione várias proposições',
    point3: 'API Gateway – proteja e provisione o acesso',
    point4: 'Portal Fintech – aproveite o Open Finance imediatamente',
    point5: 'Portal do Desenvolvedor – Faça você mesmo o onboarding e descoberta',
    point6: 'Portal do Cliente – implementação do cliente de referência',
    point7: 'SaaS ou On Premises',
    point8: 'Integrações no Marketplace – aproveitar aplicativos fintech',
    startForFree: 'Comece de Graça',
    description: 'descrição',
    keywords: 'palavras-chave'
  },
  userGuide: {
    welcomeToDocs: 'Bem-vindo ao Documentos',
    welcomeDesc: 'Aqui você encontrará guias de usuário, padrões, recursos e referências para construir com o Banfico OBAF.',
    title: 'Guias do Usuário',
    developerOnboard: 'Onboarding do Desenvolvedor',
    gettingStarted: 'Começando',
    description: 'A integração de um desenvolvedor exigiria registro. A identidade do desenvolvedor é um e-mail de usuário.',
    fintechManagement: 'Gestão Fintech',
    fintechDescription: 'A integração do desenvolvedor exigiria um registro. A identidade do desenvolvedor é um e-mail do usuário.',
    userManagement: 'Gerenciamento de usuários',
    userDescription: 'Gerenciar os usuários no Portal OBAF.',
    aspspClientManagement: 'Gestão de Clientes ASPSP',
    clientDescription: 'Para gerenciar os clientes ASPSP para uma determinada Fintech.',
    readMore: 'Leia Mais'
  },
  developerOnboard: {
    description: 'Os desenvolvedores podem usar nossas APIs de banco com base na especificação OBBR. Consulte nossa equipe sobre possibilidades de colaboração.',
    registrationDescription: 'A integração do desenvolvedor exigiria a configuração de uma conta usando o e-mail do usuário. Haverá um link de verificação por e-mail enviado durante a primeira tentativa de login.',
    loginDescription: 'O login exigirá o celular para autenticação de segundo fator via aplicativo autenticador Google.'
  },
  userManagement: {
    description: 'Este Manual do Usuário detalha o recurso “Gerenciamento de Usuários” do aplicativo OBAF.',
    roles: 'Funções do usuário',
    roleDescription: 'Existem duas funções de usuário que podem ser atribuídas a um usuário',
    devRole: 'Desenvolvedor',
    devRoleDesc: 'Os usuários podem gerenciar aplicativos e testar as APIs do OBAF',
    adminRole: 'Fintech Administrador',
    adminRoleDesc: 'Os usuários podem gerenciar clientes ASPSP, gerenciar usuários para uma determinada Fintech. Os usuários atribuídos com esta função são considerados usuários "Admin"',
    addUser: {
      title: 'Adicionar novo usuário',
      login: 'Acesse o portal da OBAF.',
      goTo: 'Abra a aba',
      tab: 'na parte superior da tela.',
      add: 'Os usuários administradores podem adicionar um novo usuário clicando em',
      rightButton: 'botão no canto superior direito.',
      addUserScreen: 'Após clicar no botão, a tela “Adicionar novo usuário” é exibida e os seguintes detalhes são inseridos nesta tela:',
      email: 'E-mail (será usado como ID de login)',
      userPermissions: 'Permissões de usuário',
      password: 'Senha Temporária',
      status: 'Status',
      note: 'Somente usuários ativos poderão fazer login no portal.',
      onclick: 'Ao clicar no',
      userCreated: 'botão, os detalhes do usuário são salvos e um novo usuário é criado.',
      googleAuth: 'O novo usuário agora pode fazer login no portal. O login exigirá o aplicativo autenticador (como o FreeOTP ou Google Authenticator) para realizar a autenticação com segundo fator.',
      resetPassword: 'Após concluí-lo, o usuário será solicitado a redefinir a senha.',
      verifyEmail: 'Haverá um link de verificação de e-mail enviado durante a primeira tentativa de login. O usuário pode verificar o e-mail clicando no link.'
    }
  },
  clientManagement: {
    description: 'Este Manual do Usuário detalha o recurso "Gerenciamento de Clientes" do aplicativo OBAF.',
    users: 'Usuários com permissão de acesso ',
    admin: 'Admin',
    permission: ' poderão criar e gerenciar Clientes ASPSP para uma determinada Fintech.',
    addClient: 'Adicionar novo cliente',
    add: 'Os usuários podem adicionar um novo cliente clicando em',
    dcr: 'Gestão DCR',
    products: 'De acordo com os requisitos do Open Finance, o banco fornece dois produtos de API. Selecione o produto que deseja usar',
    aisp: 'AISP',
    pisp: 'PISP',
    selectAspsp: 'Selecione um ou mais ASPSP (participante do Open Finance) para criar o cadastro do cliente.',
    payment: 'Abaixo está um exemplo para mostrar como fazer o pagamento (PISP) no Juno (banco).',
    createDcr: 'Criar Registro DCR',
    popup: 'Depois de selecionar o ASPSP, serão exibidos os demais campos para registrar o cliente. Detalhes como',
    privateKey: 'private key',
    cert: 'certificate',
    needtoFill: 'precisam ser preenchidos.',
    pastePrivatekey: 'Por exemplo, copie a chave privada abaixo e cole-a no',
    mtlsPrivateKey: 'MTLS Private Key',
    field: 'valores',
    mtlsPublicKey: 'MTLS Public Key',
    copy: 'Como exemplo, copie este valor',
    paste: 'e cole-o em',
    kid: 'Signature Private Key ID',
    below: 'campo e abaixo da chave privada podem ser usados no',
    signaturePrivateKey: 'Signature Private Key',
    followSteps: 'Para obter a Software Statement Assertion (SSA), você pode seguir as etapas fornecidas',
    here: 'aqui',
    jwt: 'Cole o JWT gerado no campo',
    ssa: 'Software Statement Assertion',
    clickDcr: 'e clique em',
    runDcr: 'Executar DCR',
    success: 'Em caso de sucesso, um novo cliente será criado.',
    accData: 'permission in the request body to get the list of accounts data.'
  },
  softwareStatement: {
    title: 'Obter declaração de declaração de software usando Postman',
    description: 'Este manual do usuário detalha as etapas para configurar e obter a asserção de declaração de software usando o Postman.',
    addCert: 'Adicionar certificados mTLS',
    onClick: 'Clique no',
    setting: 'ícone de configurações',
    select: 'e selecione a opção',
    selectCert: 'Selecione a aba',
    click: 'e clique em',
    host: 'Use o host abaixo e forneça dados específicos do Banco',
    button: 'botão',
    success: 'Em caso de sucesso, o certificado adicionado será exibido na aba',
    postman: 'Configuração do carteiro',
    importCollection: 'Baixe e importe o arquivo de coleção do carteiro abaixo para o Postman.',
    openCollection: 'Abra a coleção',
    selectEnv: 'e selecione o ambiente',
    provide: 'Proporciona a',
    execute: 'Execute o',
    dcrFolder: 'endpoint presente na pasta DCR.',
    sslCert: 'A validação do certificado SSL deve ser desativada conforme mostrado abaixo.',
    jwt: 'Aqui, a asserção de declaração de software será gerada como um JWT.'
  },
  aisp: {
    description: 'Testando fluxo AISP usando Swagger',
    product: 'Quando o desenvolvedor deseja testar as APIs AIS, ele precisa criar um aplicativo em seu painel do tipo de produto API',
    followSteps: 'Siga as etapas abaixo para concluir o fluxo AISP',
    selectApp: 'Selecione o aplicativo',
    onClick: 'Ao clicar no botão',
    test: ', o desenvolvedor é redirecionado para a interface do usuário do Swagger.',
    devAuth: 'Autenticação do desenvolvedor',
    authorize: 'A primeira etapa é autenticar o client do AISP. Clique no botão Autorizar para obter um token de acesso específico para o Desenvolvedor.',
    totalApi: 'O produto OBAF AISP tem um total de 10 grupos de API e 47 endpoints. Esses endpoints são usados para compartilhar diferentes dados de conta.',
    getAspsp: 'Obter lista de ASPSPs disponíveis',
    authHeader: 'O cabeçalho de autorização é preenchido automaticamente.',
    tryItout: 'Try it out',
    execute: 'Em seguida, clique no botão Execute.',
    available: 'A resposta terá a lista de disponíveis',
    bank: 'ASPSP/banco',
    fintech: 'que a Fintech criou por meio do cadastro do Cliente',
    id: 'Observe que cada ASPSP/banco possui um campo id que é considerado como',
    aspspId: 'aspspId',
    getIntAspsp: 'Obter lista de ASPSPs integrados',
    tryOut: 'Tente',
    listAspsp: 'Retorna a lista de ASPSP/banco para um determinado usuário final',
    success: 'Na solicitação bem-sucedida, a resposta deve ser a lista de',
    endUser: 'para o usuário final solicitado.',
    connectAspsp: 'Conecte-se ao ASPSP',
    integrateAspsp: 'Integra-se com ASPSP/banco específico',
    payload: 'Use o payload abaixo para enviar a solicitação',
    modalDesc: 'Você pode verificar a descrição do Modelo para encontrar a explicação de cada campo presente no corpo da solicitação.',
    note: 'Observe que você precisa fornecer todas as permissões relacionadas aos dados específicos da conta que precisa buscar.',
    successReq: 'No pedido bem-sucedido, ele deve retornar um',
    field: 'campo que contém o',
    authPage: 'Copie este URI e cole-o no navegador para acessar a página de autenticação ASPSP/banco.',
    bankLoginPage: 'Ao acessar o URI no navegador, você verá a página de autenticação ASPSP/banco conforme abaixo. Insira as credenciais do usuário final para efetuar login.',
    review: 'Revise e compartilhe o consentimento da conta e confirme.',
    redirect: 'Em seguida, o ASPSP/banco redireciona o usuário de volta ao portal Fintech.',
    getIntAspspDetail: 'Obter detalhes ASPSP integrados',
    aspspDetail: 'Ele fornece informações sobre um determinado ASPSP/banco',
    intEndUser: 'que foi integrado a um determinado usuário final',
    successInt: 'Em caso de solicitação bem-sucedida, a resposta deverá ser o detalhamento do ASPSP/banco solicitado integrado ao usuário final solicitado.',
    revoke: 'Revogar integração com ASPSP',
    revokeAccess: 'revogar o acesso a um ASPSP/banco Integrado',
    particularEndUser: 'para um determinado usuário final',
    successRes: 'Se a solicitação for bem-sucedida, obtemos uma resposta com o código de status 200.',
    listAspspConsents: 'Listar consentimentos do ASPSP',
    listConsents: 'Ele lista todos os consentimentos fornecidos por um determinado usuário final',
    successConsent: 'Em caso de solicitação bem-sucedida, a resposta deve ser uma lista de consentimentos fornecidos pelo usuário final.',
    listAllAccs: 'Listar todas as contas',
    listIntAccs: 'Ele lista todas as contas integradas com um determinado usuário final',
    whenExec: 'Ao executar',
    required: 'pedido de ligação a um banco, é necessário ter a permissão',
    accData: 'no corpo da solicitação para obter a lista de dados das contas.',
    successAcc: 'Se a solicitação for bem-sucedida, a resposta deve ser uma lista de contas que o usuário final integrou.',
    listAllTrans: 'Listar todas as transações',
    listTrans: 'Ele lista todas as transações integradas com um determinado usuário final',
    transData: 'no corpo da solicitação para obter a lista de dados de transações.',
    successTrans: 'Em caso de solicitação bem-sucedida, a resposta deve ser uma lista de transações agrupadas por status que o usuário final integrou.',
    listAspspAccs: 'Listar contas do ASPSP',
    listIntAspspAccs: 'para obter a lista de contas integrada com um ASPSP/banco específico',
    byEndUser: 'por um determinado usuário final',
    accsData: 'no corpo da solicitação para obter a lista de dados das contas.',
    successAccs: 'Se a solicitação for bem-sucedida, a resposta deve ser uma lista de contas que o usuário final integrou.',
    accDetail: 'Obter detalhes da conta para uma conta específica',
    listAccDetail: 'para obter os detalhes de uma conta específica integrada a um ASPSP/banco específico',
    listAllAccDetail: 'no corpo da solicitação para obter dados de contas.',
    successAccDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser o detalhe da conta solicitada.',
    balanceDetail: 'Obter detalhes do saldo de uma conta específica',
    listBalDetail: 'ler o saldo de uma conta específica integrada a um ASPSP/banco específico',
    listAllBalDetail: 'no corpo da solicitação para obter os dados do saldo.',
    successBalDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser o detalhe do saldo da conta solicitada.',
    transDetail: 'Obter detalhes da transação para uma conta específica',
    listTransDetail: 'para ler as transações de uma conta específica integrada a um ASPSP/banco específico',
    listAllTransDetail: 'no corpo da solicitação para obter os dados da transação.',
    successTransDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser o detalhe da transação da conta solicitada.',
    personalIdenData: 'Obter dados de identificação pessoal',
    listpersonalIdenDetail: 'para obter a lista de dados de identificação pessoal integrada com um determinado ASPSP/banco',
    listAllpersonalIdenDetail: 'permissão no corpo do pedido para obter os dados de identificação pessoal.',
    successpersonalIdenDetail: 'Mediante solicitação bem-sucedida, a resposta deverá ser uma lista de dados de identificação pessoal integrada a um ASPSP/banco específico.',
    personalQualificationData: 'Obter dados pessoais de qualificação',
    listpersonalQualificationDetail: 'para obter a lista de dados de qualificação pessoal integrada com um ASPSP/banco específico',
    listAllpersonalQualificationDetail: 'no corpo da solicitação para obter os dados pessoais de qualificação.',
    successpersonalQualificationDetail: 'Mediante solicitação bem-sucedida, a resposta deverá ser uma lista de dados de qualificação pessoal integrada a um ASPSP/banco específico.',
    personalFinancialData: 'Obter detalhes de relações financeiras pessoais',
    listpersonalFinancialDetail: 'para obter a lista de dados de relações financeiras pessoais integrada com um ASPSP/banco específico',
    listAllpersonalFinancialDetail: 'permissão no corpo do pedido para obter os dados de relações financeiras pessoais.',
    successpersonalFinancialDetail: 'Mediante solicitação bem-sucedida, a resposta deve ser uma lista de dados de relações financeiras pessoais integrada a um ASPSP/banco específico.',
    businessIdenData: 'Obter lista de dados de identificação comercial',
    listbusinessIdenDetail: 'para obter a lista de dados de identificação comercial integrada com um ASPSP/banco específico',
    listAllbusinessIdenDetail: 'no corpo da solicitação para obter a lista de dados de identificação comercial.',
    successbusinessIdenDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser uma lista de dados de identificação comercial integrada a um ASPSP/banco específico.',
    businessQualificationData: 'Obter dados de qualificação comercial',
    listbusinessQualificationDetail: 'para obter a lista de dados de qualificação empresarial integrada com um ASPSP/banco específico',
    listAllbusinessQualificationDetail: 'no corpo da solicitação para obter os dados de qualificação do negócio.',
    successbusinessQualificationDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser uma lista de dados de qualificação empresarial integrada a um ASPSP/banco específico.',
    businessFinancialData: 'Obter detalhes de relações financeiras comerciais',
    listbusinessFinancialDetail: 'para obter a lista de dados de relações financeiras empresariais integrada com um ASPSP/banco específico',
    listAllbusinessFinancialDetail: 'no corpo da solicitação para obter os dados de relações financeiras comerciais.',
    successbusinessFinancialDetail: 'Mediante solicitação bem-sucedida, a resposta deve ser uma lista de dados de relações financeiras empresariais integrada a um ASPSP/banco específico.',
    resourcesDetail: 'Obter lista de recursos',
    listresourceDetail: 'obter a lista de funcionalidades consentidas pelo cliente integradas com um determinado ASPSP/banco',
    listAllresourceDetail: 'no corpo da solicitação para obter a lista de recursos.',
    successresourceDetail: 'Se a solicitação for bem-sucedida, a resposta deve ser uma lista de dados de recursos integrados a um ASPSP/banco específico.',
    creditAccDetail: 'Obter lista de contas de cartão de crédito',
    listcreditAccDetail: 'para obter a lista de contas de pagamento pós-pagas integradas a um ASPSP/banco específico',
    listAllcreditAccDetail: 'no corpo da solicitação para obter a lista de contas de cartão de crédito.',
    successcreditAccDetail: 'Em caso de solicitação bem-sucedida, a resposta deverá ser a lista de contas de cartão de crédito integradas a um ASPSP/banco específico.',
    creditAccDetailData: 'Obter detalhes da conta do cartão de crédito',
    listcreditAccDetailData: 'para obter os detalhes da conta de pagamento pós-pago de uma conta específica integrada a um ASPSP/banco específico',
    listAllcreditAccDetailData: 'no corpo da solicitação para obter a lista de dados da conta do cartão de crédito.',
    successcreditAccDetailData: 'Se a solicitação for bem-sucedida, a resposta deve ser os detalhes da conta do cartão de crédito do usuário final.',
    creditAccLimitData: 'Obter limites de conta de cartão de crédito',
    listcreditAccLimitData: 'para obter os limites da conta de pagamento pós-pago de um ASPSP/banco específico',
    listAllcreditAccLimitData: 'no corpo da solicitação para obter os dados dos limites da conta do cartão de crédito.',
    successcreditAccLimitData: 'Se a solicitação for bem-sucedida, a resposta deve ser a lista de limites da conta do cartão de crédito do usuário final.',
    creditAccTransData: 'Obter lista de transações de contas de cartão de crédito',
    listcreditAccTransData: 'para obter as transações da conta de pagamento pós-pago de um ASPSP/banco específico',
    listAllcreditAccTransData: 'no corpo da solicitação para obter os dados de transações da conta de pagamento pós-pago.',
    successcreditAccTransData: 'Se a solicitação for bem-sucedida, a resposta deve ser a lista de transações da conta de cartão de crédito mantida pelo usuário final.',
    creditRecentAccTransData: 'Obter lista de transações recentes de contas de cartão de crédito',
    listRecentAccTransData: 'para obter as transações da conta de pagamento pós-pago dos últimos 7 dias de um ASPSP/banco específico',
    listAllRecentAccTransData: 'no corpo da solicitação para obter os dados de transações da conta de pagamento pós-pago.',
    successRecentAccTransData: 'Se a solicitação for bem-sucedida, a resposta deve ser a lista de transações recentes da conta de cartão de crédito mantidas pelo usuário final.',
    creditAccBillsData: 'Obter lista de contas de cartão de crédito',
    listAccBillsData: 'para obter as contas de pagamento pós-pago de um ASPSP/banco específico',
    listAllAccBillsData: 'no corpo da solicitação para obter os dados das contas de pagamento pós-pago.',
    successAccBillsData: 'Em caso de solicitação bem-sucedida, a resposta deve ser a lista de contas de cartão de crédito detidas pelo usuário final.',
    creditAccBillsTransData: 'Obter lista de transações de contas de cartão de crédito',
    listAccBillsTransData: 'para obter as transações de contas de pagamento pós-pagas de um ASPSP/banco específico',
    listAllAccBillsTransData: 'no corpo da solicitação para obter os dados de transações da conta de pagamento pós-pago.',
    successAccBillsTransData: 'Em caso de solicitação bem-sucedida, a resposta deve ser a lista de transações de faturas de contas de cartão de crédito mantidas pelo usuário final.',
    procedure: 'Para executar as APIs de Operações de Crédito - Empréstimos, Operações de Crédito - Financiamentos, Operações de Crédito - Contas à Vista Cheque Especial e Operações de Crédito - Nota Fiscal Financiamentos, siga o mesmo procedimento explicado acima'
  },
  pisp: {
    description: 'Testando o fluxo do PISP usando o Swagger',
    product: 'Quando o desenvolvedor deseja testar as APIs do PIS, ele precisa criar um aplicativo em seu painel do tipo de produto da API',
    followSteps: 'Siga as etapas abaixo para concluir o fluxo do PISP',
    selectApp: 'Selecione o aplicativo',
    onClick: 'Ao clicar no botão',
    test: ', o desenvolvedor é redirecionado para a interface do usuário do Swagger.',
    devAuth: 'Autenticação do desenvolvedor',
    authorize: 'A primeira etapa é autenticar o client do PISP. Clique no botão Autorizar para obter um token de acesso específico para o Desenvolvedor.',
    availableAspsp: 'Obter lista de ASPSPs disponíveis',
    authHeader: 'O cabeçalho de autorização é preenchido automaticamente.',
    tryItout: 'Teste',
    execute: 'Em seguida, clique no botão Execute.',
    available: 'A resposta terá a lista de disponíveis',
    bank: 'ASPSP/banco',
    fintech: 'que a Fintech criou por meio do DCR',
    id: 'Observe que cada ASPSP/banco possui um campo id que é considerado como',
    aspspId: 'aspspId',
    domesticPaymentCall: 'Chamada de solicitação de pagamento doméstico',
    paymentRequest: 'A solicitação de pagamento pode ser criada para um usuário final específico',
    specificBank: 'em um ASPSP/banco específico',
    response: 'Você pode obter o aspspId para bancos na resposta acima',
    onSuccess: 'No pedido bem-sucedido, ele deve retornar um',
    field: 'campo que contém o',
    authPage: 'Copie este URI e cole-o no navegador para acessar a página de autenticação ASPSP/banco.',
    enterCred: 'Digite as credenciais do usuário final para fazer login',
    redirect: 'Uma vez logado, o usuário será redirecionado para a tela de confirmação de pagamento para revisar os dados de pagamento e confirmar. Caso a solicitação seja bem-sucedida, o usuário será redirecionado de volta para esta janela.',
    listDomesticPayments: 'Obter Lista de Pagamentos Domésticos',
    nowTryOut: 'Agora teste este endpoint',
    listPayments: 'Ele retorna a lista de pagamentos para um determinado usuário final',
    successReq: 'Se a solicitação for bem-sucedida, a resposta deve ser a lista de pagamentos para o usuário final solicitado.'
  },
  postmanFlow: {
    title: 'Testando as APIs usando o Postman',
    description: 'Este manual do usuário detalha as etapas para configurar e testar as APIs usando o Postman.',
    supportedFlows: 'Tipo de fluxo atualmente suportado',
    pispFlow: 'Fluxo de Iniciação de Pagamento',
    import: 'Baixe e importe os arquivos de coleção e ambiente abaixo para o Postman.',
    openEnv: 'Abra o Ambiente OBAF-PISP-SBX e atualize o',
    save: 'valores para PISP, conforme o usuário que precisa ser validado e salve a alteração.',
    openCollection: 'Abra a coleção',
    selectEnv: 'e selecione o ambiente',
    executeToken: 'Execute o endpoint de solicitação de token presente na pasta BRAZIL PISP.',
    execute: 'Execute o',
    listAvailableAspsp: 'Lista de ASPSPs Disponíveis',
    response: 'endpoint e a resposta terá a lista de ASPSP/banco disponíveis que a Fintech criou através do cadastro do Cliente.',
    particularEndUser: 'endpoint para um determinado usuário final (x-user-id) em um ASPSP/banco específico (aspspId)',
    onSuccess: 'Mediante solicitação bem-sucedida, um',
    bankAuthUri: 'será gerado, que é o URI de autenticação ASPSP/banco',
    bankAuthPage: 'Copie este URI e cole-o no navegador para acessar a página de autenticação ASPSP/banco.',
    creds: 'Insira as credenciais do usuário final para efetuar login.',
    paymentConfirm: 'Uma vez logado, o usuário será redirecionado para a tela de confirmação de pagamento para revisar os dados de pagamento e confirmar. Mediante solicitação bem-sucedida, o usuário será redirecionado de volta para a Fintech e o pagamento será processado.',
    listPayments: 'endpoint, que retorna a lista de pagamentos para um determinado usuário final (x-user-id).',
    specificPayment: 'endpoint, para obter os detalhes de um pagamento específico.'
  }
}