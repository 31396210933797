import React, { createContext } from 'react';
import { init } from 'localization';

export const LanguageContext = createContext({});


export const LanguageContextProvider = ({ children }) => {
  const [localizationLoaded, setLocalizationLoaded] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => 
    init()
    .then(() => setLocalizationLoaded(true))
    .catch(() => setLocalizationLoaded(true)), 1000);
  }, []);

  return (
    <LanguageContext.Provider value={null}>
      {localizationLoaded ? children : null}
    </LanguageContext.Provider>
  )
}